import React, { useEffect } from 'react'

const Message = ({
    imageUrl,
    body,
    sent,
    showDay,
    day,
    showImage,
    smallScreen,
    timestamp,
    typing = null,
    scrollDown = null,
}) => {
    useEffect(() => {
        if (typing) scrollDown()
    }, [typing])

    return !showDay ? (
        <div
            className={
                'flex w-full dark:text-white' +
                (sent ? ' flex-row-reverse justify-start self-end' : '') +
                (showImage ? ' mt-6' : ' mt-px') +
                (smallScreen ? ' px-1' : ' px-4')
            }
        >
            <div className={'w-14 min-w-14' + (sent ? ' mr-2' : ' ml-2')}>
                {showImage && (
                    <img
                        src={imageUrl}
                        className='w-11 h-11 rounded-full ml-2 md:mr-2 object-cover'
                    />
                )}
            </div>

            <div
                className={
                    'p-1 flex flex-col items-center justify-center overflow-hidden' +
                    (sent ? '' : '')
                }
            >
                <div
                    className={
                        'break-words px-5 py-2 overflow-hidden antialiased max-w-full' +
                        (sent
                            ? ` bg-blue-400 dark:bg-blue-700 ${
                                  showImage
                                      ? 'rounded-l-3xl rounded-br-3xl'
                                      : 'rounded-3xl'
                              }`
                            : ` bg-gray-300 dark:bg-gray-800 ${
                                  showImage
                                      ? 'rounded-r-3xl rounded-bl-3xl'
                                      : 'rounded-3xl'
                              }`)
                    }
                >
                    {body}
                </div>
                <div
                    className={
                        'text-xxs font-semibold text-gray-800 dark:text-white w-full mt-2 flex' +
                        (sent ? ' justify-end mr-1' : ' ml-1')
                    }
                >
                    {timestamp}
                </div>
            </div>
        </div>
    ) : (
        <div
            className={
                'flex flex-col items-center w-full' +
                (showImage ? ' mt-6' : ' mt-px')
            }
        >
            <div className='flex-items text-white py-2'>
                <div className='w-full text-sm bg-gray-800 dark:bg-gray-500 rounded-3xl py-2 px-5 overflow-hidden antialiased'>
                    {day}
                </div>
            </div>
            <div
                className={
                    'flex w-full dark:text-white' +
                    (sent ? ' flex-row-reverse justify-start self-end' : '') +
                    (smallScreen ? ' px-1' : ' px-4')
                }
            >
                <div className={'w-14' + (sent ? ' mr-2' : ' ml-2')}>
                    <img
                        src={imageUrl}
                        className='w-11 h-11 rounded-full ml-2 mr-2 object-cover'
                    />
                </div>

                <div
                    className={
                        `p-1 flex flex-col items-center justify-center overflow-hidden` +
                        (sent ? ' ml-16' : '')
                    }
                >
                    <div
                        className={
                            'break-words px-5 py-2 overflow-hidden antialiased flex flex-col' +
                            (sent
                                ? ' bg-blue-400 dark:bg-blue-700 rounded-l-3xl rounded-br-3xl'
                                : ' bg-gray-300 dark:bg-gray-800 rounded-r-3xl rounded-bl-3xl')
                        }
                    >
                        {body}
                    </div>
                    <div
                        className={
                            'text-xxs font-semibold text-gray-800 dark:text-white w-full mt-2 flex' +
                            (sent ? ' justify-end mr-1' : ' ml-1')
                        }
                    >
                        {timestamp}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Message
