import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Logo from './Logo'
import { logout } from '../actions/userActions'
import { motion, AnimatePresence } from 'framer-motion'
import UserSearch from './UserSearch'

const Nav = () => {
    const [dark, setDark] = useState(false)
    const [profileDrop, setProfileDrop] = useState(false)
    const [navDrop, setNavDrop] = useState(false)
    const [avatarBounce, setAvatarBounce] = useState(true)
    const [scrollNav, setScrollNav] = useState(false)
    const [profilePic, setProfilePic] = useState('')
    const [textColor, setTextColor] = useState('')
    const dispatch = useDispatch()
    const location = useLocation()

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, userInfo, error } = userLogin

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const {
        loading: loadingUpdate,
        success,
        userInfo: updatedUserInfo,
    } = userUpdateProfile

    const clientInfo = useSelector((state) => state.chatClient)
    const { chatClient: conversationsClient } = clientInfo

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                setDark(true)
                localStorage.setItem('theme', 'dark')
            } else {
                setDark(false)
                localStorage.setItem('theme', 'light')
            }
        }
    }
    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delay: 0.3,
                when: 'beforeChildren',
                delayChildren: 0.2,
                staggerChildren: 0.1,
            },
        },
    }

    const item = {
        hidden: { x: -20, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
        },
    }
    window.onscroll = function (e) {
        // var scrollY = window.pageYOffset || document.documentElement.scrollTop
        // var navbar = document.querySelector('nav')
        // scrollY <= this.lastScroll ? setScrollNav(true) : setScrollNav(false)
        // this.lastScroll = scrollY
        if (location.pathname === '/') {
            if (window.scrollY === 0) {
                setTextColor('text-white')
            } else {
                setTextColor('')
            }
        }
    }
    useEffect(() => {
        if (userInfo) {
            setProfilePic(userInfo.imageUrl)
        }
    }, [userInfo])

    useEffect(() => {
        if (localStorage.getItem('theme')) {
            const theme = localStorage.getItem('theme')

            if (theme === 'dark') {
                setDark(true)
                document.querySelector('html').classList.add(theme)
            } else {
                document.querySelector('html').classList.remove('dark')
            }
        } else {
            localStorage.setItem('theme', 'light')
        }
        if (userInfo) {
            setTimeout(() => {
                setAvatarBounce(false)
            }, 3000)
        }
    }, [dark])

    useEffect(() => {
        if (location.pathname === '/') {
            setTextColor('text-white')
        } else {
            setTextColor('')
        }
        // let footer = document.getElementById('my-footer')

        // if (footer.classList.contains('hidden')) {
        //     footer.classList.remove('hidden')
        // }
    }, [location])

    useEffect(() => {
        if (updatedUserInfo) {
            setProfilePic(updatedUserInfo.imageUrl)
        }
    }, [success])

    const logoutHandler = () => {
        setProfileDrop(false)
        dispatch(logout(conversationsClient))
    }

    const globalClick = (e) => {
        if (!e.target.classList.contains('profile-header-button')) {
            setProfileDrop(false)
            document.removeEventListener('click', globalClick)
        }
    }
    const otherGlobalClick = (e) => {
        if (!e.target.classList.contains('profile-nav-button')) {
            setNavDrop(false)
            document.removeEventListener('click', otherGlobalClick)
        }
    }
    const toggleNav = () => {
        setProfileDrop((prevState) => !prevState)
    }
    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         if (window.scrollY === 0) {
    //         }
    //     })
    // }, [])
    useEffect(() => {
        if (profileDrop) {
            document.addEventListener('click', globalClick)
        }
    }, [profileDrop])
    useEffect(() => {
        if (navDrop) {
            document.addEventListener('click', otherGlobalClick)
        }
    }, [navDrop])

    return (
        <nav
            id='navbar'
            className={
                'flex-none py-1 w-full z-50 navbar fixed z-50 top-0 transition ' +
                (textColor ? ' bg-transparent' : ' bg-white dark:bg-gray-800 ')
            }
        >
            <div className='max-w-full mx-auto px-4 sm:px-6 lg:px-8 h-full'>
                <div className='flex items-center justify-between h-full'>
                    {!userInfo && (
                        <div class='inset-y-0 left-0 flex items-center sm:block md:hidden'>
                            {/* <!-- Mobile menu button--> */}
                            <button
                                type='button'
                                class='inline-flex items-center profile-nav-button justify-center w-10 p-2 z-50 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                aria-controls='mobile-menu'
                                aria-expanded='false'
                                onClick={() => setNavDrop(!navDrop)}
                            >
                                <span class='sr-only'>Open main menu</span>
                                {navDrop ? (
                                    <i className='fas fa-times text-2xl'></i>
                                ) : (
                                    <i className='fas fa-bars text-2xl'></i>
                                )}
                            </button>
                        </div>
                    )}
                    <Logo />
                    <div className='flex items-center'>
                        <motion.div
                            initial={{ opacity: 0, y: -50, scale: 1 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            transition={{
                                type: 'tween',
                                ease: 'anticipate',
                                duration: 0.5,
                            }}
                            className='hidden md:block z-30 flex justify-between justify-self-center md:mr-4 md:ml-10 md:pr-4 md:space-x-8'
                        >
                            {userInfo ? (
                                <>
                                    <Link
                                        to='/bucketlists'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        My Bucketlists
                                    </Link>
                                    <Link
                                        to='/feed'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        Feed
                                    </Link>
                                    {/* <Link
                                        to='/explore'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        Explore
                                    </Link> */}
                                    <Link
                                        to='/chats'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        Chats
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link
                                        to='/'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        About
                                    </Link>
                                    <Link
                                        to='/faq'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        FAQ
                                    </Link>
                                    <Link
                                        to='/contact'
                                        className={
                                            'font-bold dark:text-white dark:hover:text-blue-600 ' +
                                            (textColor
                                                ? ` ${textColor}  hover:text-blue-600`
                                                : ' text-gray-700 hover:text-gray-900')
                                        }
                                    >
                                        Contact Us
                                    </Link>
                                    <Link
                                        to='/login'
                                        className={
                                            'font-bold dark:text-blue-400 dark:hover:text-blue-600' +
                                            (textColor
                                                ? ' text-blue-400 hover:text-blue-600'
                                                : ' text-indigo-600 hover:text-indigo-500')
                                        }
                                    >
                                        Login
                                    </Link>
                                    <Link
                                        to='/signup'
                                        className={
                                            'font-bold dark:text-blue-400 dark:hover:text-blue-600' +
                                            (textColor
                                                ? ' text-blue-400 hover:text-blue-600'
                                                : ' text-indigo-600 hover:text-indigo-500')
                                        }
                                    >
                                        Sign Up
                                    </Link>
                                </>
                            )}
                        </motion.div>
                        {userInfo && <UserSearch userInfo={userInfo} />}
                    </div>

                    <label
                        htmlFor='toogleA'
                        className={
                            'flex justify-even items-center cursor-pointer z-30 dark:white md:block' +
                            (!userInfo ? '' : ' hidden md:block')
                        }
                    >
                        <div className='relative '>
                            <input
                                id='toogleA'
                                type='checkbox'
                                className='hidden'
                                checked={dark}
                                onChange={handleChange}
                            />
                            <div className='w-10 h-4 bg-gray-400 rounded-full shadow-inner'></div>
                            <div className='toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0'></div>
                        </div>
                        {/* <span className={'text dark:text-white ml-4 ' + (textColor ? textColor: ' text-gray-800')}>
							{dark ? 'Light Mode' : 'Dark Mode'}
						</span> */}
                    </label>
                    {userInfo && (
                        <div className='z-40 profile-header-button '>
                            <div>
                                <button
                                    type='button'
                                    className='h-11 md:h-14 w-11 md:w-14 animate-bounce overflow-hidden flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-500 focus:ring-white z-40'
                                    id='user-menu-button'
                                    aria-expanded='false'
                                    aria-haspopup='true'
                                    onClick={toggleNav}
                                >
                                    <span className='sr-only'>
                                        Open user menu
                                    </span>
                                    <img
                                        className='h-full w-full rounded-full object-cover'
                                        src={profilePic}
                                        alt=''
                                    />
                                </button>
                            </div>
                            <AnimatePresence>
                                {profileDrop && (
                                    <motion.div
                                        className='origin-top-right profile-header-button absolute right-0 mt-2 mr-4 w-48 z-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                                        initial={{
                                            opacity: 0,
                                            y: 40,
                                            scale: 0.8,
                                        }}
                                        animate={{ opacity: 1, y: 0, scale: 1 }}
                                        exit={{ opacity: 0, y: 40, scale: 0.8 }}
                                        transition={{
                                            type: 'tween',
                                            ease: 'anticipate',
                                            duration: 0.3,
                                        }}
                                        role='menu'
                                        aria-orientation='vertical'
                                        aria-labelledby='user-menu-button'
                                        tabIndex='-1'
                                    >
                                        <Link
                                            to='/profile'
                                            className='block px-4 py-2 text-sm text-gray-700'
                                            role='menuitem'
                                            tabIndex='-1'
                                            id='user-menu-item-0'
                                        >
                                            Your Profile
                                        </Link>
                                        <Link
                                            to='/settings'
                                            className='block px-4 py-2 text-sm text-gray-700'
                                            role='menuitem'
                                            tabIndex='-1'
                                            id='user-menu-item-0'
                                        >
                                            Settings
                                        </Link>

                                        {/* <Link
										to='/settings'
										className='block px-4 py-2 text-sm text-gray-700'
										role='menuitem'
										tabIndex='-1'
										id='user-menu-item-1'
									>
										Settings
									</Link> */}
                                        <button
                                            className='block px-4 py-2 text-sm text-indigo-600 hover:text-indigo-500 dark:text-blue-400 dark:hover:text-blue-600'
                                            role='menuitem'
                                            tabIndex='-1'
                                            id='user-menu-item-2'
                                            onClick={logoutHandler}
                                        >
                                            logout
                                        </button>
                                        <label
                                            htmlFor='toogleA'
                                            className='flex justify-even items-center cursor-pointer z-30 dark:white block md:hidden profile-header-button px-4 py-2'
                                        >
                                            <div className='relative'>
                                                <input
                                                    id='toogleA'
                                                    type='checkbox'
                                                    className='hidden'
                                                    checked={dark}
                                                    onChange={handleChange}
                                                />
                                                <div className='w-10 h-4 bg-gray-400 rounded-full shadow-inner'></div>
                                                <div className='toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0'></div>
                                            </div>
                                            {/* <span className={'text dark:text-white ml-4 ' + (textColor ? textColor: ' text-gray-800')}>
							{dark ? 'Light Mode' : 'Dark Mode'}
						</span> */}
                                        </label>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    )}
                </div>
                {navDrop && !userInfo && (
                    <div className='absolute profile-nav-button rounded-lg py-2 bg-white dark:bg-gray-50 block md:hidden w-3/4 mr-10 z-50 md:pr-4 md:space-x-8'>
                        {
                            <>
                                <Link
                                    to='/'
                                    className='text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium dark:text-gray-600 dark:hover:text-white'
                                >
                                    About
                                </Link>
                                <Link
                                    to='/faq'
                                    className='text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium dark:text-gray-600 dark:hover:text-white'
                                >
                                    FAQ
                                </Link>
                                <Link
                                    to='/contact'
                                    className='text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium dark:text-gray-600 dark:hover:text-white'
                                >
                                    Contact Us
                                </Link>
                                <Link
                                    to='/login'
                                    className='font-medium text-indigo-600 hover:text-indigo-500 dark:text-blue-400 dark:hover:text-blue-600 text-base block px-3 py-2 rounded-md'
                                >
                                    Login
                                </Link>
                                <Link
                                    to='/signup'
                                    className='font-medium text-indigo-600 hover:text-indigo-500 dark:text-blue-400 dark:hover:text-blue-600 text-base block px-3 py-2 rounded-md'
                                >
                                    Sign Up
                                </Link>
                            </>
                        }
                    </div>
                )}
            </div>
            {userInfo && (
                <div
                    id='small-footer'
                    className='block md:hidden fixed bottom-0 bg-gray-200 dark:bg-gray-800 py-1 px-2 flex justify-between w-full rounded-t-lg'
                >
                    <Link
                        to='/bucketlists'
                        className={
                            'hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium dark:hover:text-blue-500' +
                            (location.pathname === '/bucketlists'
                                ? ' text-blue-500 font-semibold'
                                : ' text-gray-700 dark:text-white')
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-7 w-7'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'
                            />
                        </svg>
                    </Link>
                    <Link
                        to='/feed'
                        className={
                            'hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium dark:hover:text-blue-500' +
                            (location.pathname === '/feed'
                                ? ' text-blue-500 font-semibold'
                                : ' text-gray-700 dark:text-white')
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-7 w-7'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z'
                            />
                        </svg>
                    </Link>
                    {/* <Link
                        to='/explore'
                        className={
                            'hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium dark:hover:text-blue-500' +
                            (location.pathname === '/explore'
                                ? ' text-blue-500 font-semibold'
                                : ' text-gray-700 dark:text-white')
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-7 w-7'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                            />
                        </svg>
                    </Link> */}
                    <Link
                        to='/chats'
                        className={
                            'hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium dark:hover:text-blue-500' +
                            (location.pathname === '/chats'
                                ? ' text-blue-500 font-semibold'
                                : ' text-gray-700 dark:text-white')
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-7 w-7'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z'
                            />
                        </svg>
                    </Link>
                </div>
            )}
        </nav>
    )
}

export default Nav
