import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import Typing from '../../assets/images/typing.svg'
import { Link } from 'react-router-dom'
import { CURRENT_CHAT_RESET } from '../../constants/chatConstants'

const ChatHead = ({
    pic_url,
    name,
    userId,
    typing,
    chatId,
    username,
    smallScreen,
    backClick,
}) => {
    const dispatch = useDispatch()
    const [online, setOnline] = useState(false)
    const [user, setUser] = useState(false)
    const color = online ? 'green-400' : 'red-400'
    const memberPresence = useSelector((state) => state.memberPresence)
    const { byId: presenceById, totalOccupancy } = memberPresence
    const onClick = () => {
        backClick('list')
        dispatch({ type: CURRENT_CHAT_RESET })
    }
    useEffect(() => {
        if (presenceById[chatId]) {
            if (presenceById[chatId].occupancy > 1) setOnline(true)
            else if (presenceById[chatId].occupancy === 1) setOnline(false)
        }
    }, [totalOccupancy])

    return (
        <div className='flex px-4 items-center justify-start h-full w-full'>
            {smallScreen && (
                <motion.div
                    className={`md:mr-3 cursor-pointer`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={onClick}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-8 w-8 dark:text-white'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M15 19l-7-7 7-7'
                        />
                    </svg>
                </motion.div>
            )}

            <div className={`flex flex-row-reverse items-end`}>
                <img
                    src={pic_url}
                    className={
                        'w-12 md:w-14 h-12 md:h-14 rounded-full mr-2 overflow-hidden object-cover'
                    }
                />
                {online && (
                    <div className='bg-green-500 border border-black absolute mr-3 mb-0.5 h-3 w-3 rounded-full overflow-hidden'></div>
                )}
            </div>

            <div className='w-9/12 h-full flex items-start justify-between py-4'>
                <div
                    className={
                        'mr-2 w-3/4 h-full flex flex-col justify-start' +
                        (smallScreen ? ' pl-0' : ' pl-6')
                    }
                >
                    <Link
                        to={`/${username}`}
                        className='mb-1 dark:text-white hover:text-blue-500 dark:hover:text-blue-500 antialiased'
                    >
                        {name}
                    </Link>
                    {typing ? (
                        <p
                            className={`text-sm dark:text-gray-300 flex antialiased`}
                        >
                            <img className='mr-2' src={Typing} alt='typing' />
                            {`${name.split(' ')[0]} is typing`}
                        </p>
                    ) : (
                        <p
                            className={
                                `text-sm antialiase` +
                                (online ? ' text-green-400' : ' text-red-400')
                            }
                        >
                            {online ? 'Online' : 'Offline'}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChatHead
