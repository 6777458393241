import {
    ADD_MESSAGE_REQUEST,
    ADD_MESSAGE_SUCCESS,
    ADD_MESSAGE_FAIL,
    LIST_MESSAGES_REQUEST,
    LIST_MESSAGES_SUCCESS,
    LIST_MESSAGES_FAIL,
    LIST_CHATS_REQUEST,
    LIST_CHATS_SUCCESS,
    LIST_CHATS_FAIL,
    LIST_CHATS_REFRESH,
    CHAT_CLIENT_REQUEST,
    CHAT_CLIENT_SUCCESS,
    CHAT_CLIENT_FAIL,
    LIST_CHATS_DELETE,
    LIST_CHATS_DONE_REFRESH,
    LIST_CHATS_UPDATE,
    LIST_CHATS_RESET,
    CHAT_CLIENT_RESET,
    CURRENT_CHAT_REQUEST,
    CURRENT_CHAT_SUCCESS,
    CURRENT_CHAT_FAIL,
    CURRENT_CHAT_RESET,
    LATEST_MESSAGES_REQUEST,
    LATEST_MESSAGES_SUCCESS,
    LATEST_MESSAGES_FAIL,
    LATEST_MESSAGES_RESET,
} from '../constants/chatConstants'

export const chatsReducer = (
    state = { chats: [], participants: [] },
    action
) => {
    switch (action.type) {
        case LIST_CHATS_REQUEST:
            return { ...state, loading: true }
        case LIST_CHATS_SUCCESS:
            return { loading: false, chats: action.payload }
        case LIST_CHATS_FAIL:
            return { loading: false, error: action.payload }
        case LIST_CHATS_RESET:
            return { chats: [] }
        default:
            return state
    }
}

export const currentChatReducer = (state = { chat: {} }, action) => {
    switch (action.type) {
        case CURRENT_CHAT_REQUEST:
            return { ...state, loading: true }
        case CURRENT_CHAT_SUCCESS:
            return { loading: false, chat: action.payload }
        case CURRENT_CHAT_FAIL:
            return { loading: false, error: action.payload }
        case CURRENT_CHAT_RESET:
            return { chat: {} }
        default:
            return state
    }
}

export const listMessagesReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case LIST_MESSAGES_REQUEST:
            return { loading: true }
        case LIST_MESSAGES_SUCCESS:
            return { loading: false, messages: action.payload }
        case LIST_MESSAGES_FAIL:
            return { loading: false, error: action.payload }
        case ADD_MESSAGE_REQUEST:
            return { loading: true }
        case ADD_MESSAGE_SUCCESS:
            return {
                loading: false,
                messages: [...state.messages, action.payload],
            }
        default:
            return state
    }
}

export const latestMessagesReducer = (
    state = { latestMessages: [] },
    action
) => {
    switch (action.type) {
        case LATEST_MESSAGES_REQUEST:
            return { loading: true }
        case LATEST_MESSAGES_SUCCESS:
            return { loading: false, latestMessages: action.payload }
        case LATEST_MESSAGES_FAIL:
            return { loading: false, error: action.payload }
        case LATEST_MESSAGES_RESET:
            return { latestMessages: [] }
        default:
            return state
    }
}

export const chatClientReducer = (state = { chatClient: null }, action) => {
    switch (action.type) {
        case CHAT_CLIENT_REQUEST:
            return { ...state, loading: true }
        case CHAT_CLIENT_SUCCESS:
            return { loading: false, chatClient: action.payload }
        case CHAT_CLIENT_FAIL:
            return { loading: false, error: action.payload }
        case CHAT_CLIENT_RESET:
            return { chatClient: null }
        default:
            return state
    }
}

export const addMessageReducer = (state = {}, action) => {
    switch (action.type) {
        // case ADD_MESSAGE_REQUEST:
        // 	return { loading: true }
        // case ADD_MESSAGE_SUCCESS:
        // 	return { loading: false, message: action.payload }
        // case ADD_MESSAGE_FAIL:
        // 	return { loading: false, error: action.payload }
        default:
            return state
    }
}
