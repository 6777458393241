import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { motion, AnimatePresence } from 'framer-motion'

const UserSearch = ({ userInfo }) => {
    const [keyword, setKeyword] = useState('')
    const [users, setUsers] = useState([])
    const [showDrop, setShowDrop] = useState(false)
    const searchGlobalClick = (e) => {
        if (!e.target.classList.contains('user-searchbar')) {
            setShowDrop(false)
            document.removeEventListener('click', searchGlobalClick)
        }
    }
    useEffect(() => {
        if (showDrop) {
            document.addEventListener('click', searchGlobalClick)
        }
    }, [showDrop])
    let cancel

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        }

        const search = async () => {
            try {
                const { data } = await axios.get(
                    `/api/users?keyword=${keyword}`,
                    config
                )
                setUsers(data.users)
                console.log(data.users)
            } catch (error) {
                if (axios.isCancel()) return
                console.log(error)
            }
        }
        if (keyword !== '') {
            search()
        } else {
            setUsers([])
        }

        return () => cancel()
    }, [keyword])
    // const search = async (val) => {

    // }
    return (
        <div className={'w-full md:w-56'}>
            <div className='flex items-center justify-center h-10 w-full rounded-xl bg-gray-200 dark:bg-gray-700 px-4 shadow-lg'>
                <input
                    value={keyword}
                    onChange={(e) => {
                        setKeyword(e.target.value)
                        // search(e.target.value)
                    }}
                    onFocus={() => setShowDrop(true)}
                    className='w-full focus:outline-none bg-gray-200 dark:bg-gray-700 dark:text-white text-sm user-searchbar'
                    placeholder='Search'
                />
                <i className='fas fa-search text-lg dark:text-white user-searchbar'></i>
            </div>
            <AnimatePresence>
                {showDrop && (
                    <motion.div
                        initial={{ opacity: 0, y: 20, scale: 0.8 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, y: 20, scale: 0.8 }}
                        transition={{
                            type: 'tween',
                            ease: 'anticipate',
                            duration: 0.3,
                        }}
                        className='profile-header-button absolute mt-4 mx-auto md:mr-2 w-64 md:mr-0 z-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none user-searchbar'
                    >
                        {users && users.length > 0 ? (
                            <div className='px-2 py-2'>
                                {users.map((user) => {
                                    return (
                                        <Link
                                            to={`/${user.username}`}
                                            className={
                                                'flex items-center w-full hover:text-blue-500  dark:text-gray-700 dark:hover:text-blue-500 mb-3'
                                            }
                                        >
                                            <img
                                                src={user.imageUrl}
                                                className='object-cover h-8 w-8 md:h-11 md:w-11 rounded-full'
                                            />

                                            <div className='px-4 flex justify-center items-center'>
                                                <p>{user.username}</p>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className='h-20 bg-transparent flex items-center justify-center text-sm'>
                                <div>No results</div>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default UserSearch
