import React from 'react'
import { motion } from 'framer-motion'
import Meta from '../components/common/Meta'

const Team = () => {
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Team`} />

            <section className='relative py-20'>
                <div className='container px-4 mx-auto'>
                    <div className='max-w-3xl mx-auto mb-20 text-center'>
                        <h2 className='mt-8 mb-10 text-4xl font-semibold font-heading dark:text-white'>
                            Meet The Team Behind This Awesome Website!
                        </h2>
                        <p className='text-xl text-gray-500 dark:text-gray-200'>
                            A lot of effort and time was contributed by each
                            member of the team. Feel free to reach out to any
                            team member through the links provided.
                        </p>
                    </div>
                    <div className='flex items-center justify-around'>
                        {/* <div className="hidden md:block px-4">
                            <button className="w-14 h-14 rounded-full border hover:bg-gray-50">
                            <svg className="mx-auto" width={19} height={13} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.30024 6.25131L18.2502 6.25131" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.30029 1.25031L1.36329 6.25131L9.30029 11.2523L9.30029 1.25031Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </button>
                        </div> */}
                        <div className='relative'>
                            <div className='max-w-2xl mx-auto py-16 px-12 mb-6 lg:mb-12 border rounded-lg text-center'>
                                <div className='absolute inset-0 h-20 -mt-10'>
                                    <img
                                        className='w-20 h-20 mx-auto rounded-full mb-6 object-cover'
                                        src='/images/mypic2.jpeg'
                                        alt
                                    />
                                </div>
                                <h3 className='mb-2 text-2xl font-semibold font-heading dark:text-white'>
                                    Adrian Karanja
                                </h3>
                                <p className='mb-6 text-gray-500 dark:text-gray-400'>
                                    CEO &amp; Founder
                                </p>
                                <p className='text-gray-500 dark:text-gray-300 mb-8'>
                                    I am student pursuing a degree in computer
                                    science and I enjoy making websites in my
                                    free time. Feel free to reach out to me if
                                    you want to talk about a current project or
                                    anything in general. I hope you enjoy using
                                    the site.
                                </p>
                                <div className='flex items-center justify-between w-full mx-auto md:w-3/4 z-10'>
                                    <a
                                        href='https://www.linkedin.com/in/adrian-karanja-b80b821a4'
                                        target='_blank'
                                        title='LinkedIn'
                                        className='dark:text-white hover:text-blue-500 dark:hover:text-blue-500 hover:text-3xl transition duration-300 text-2xl'
                                    >
                                        <i className='fab fa-linkedin-in'></i>
                                    </a>
                                    <a
                                        href='https://github.com/adriankaranja'
                                        target='_blank'
                                        title='Github'
                                        className='dark:text-white hover:text-blue-500 dark:hover:text-blue-500 hover:text-3xl transition duration-300 text-2xl'
                                    >
                                        <i className='fab fa-github'></i>
                                    </a>
                                    <a
                                        href='https://www.instagram.com/adrian.karanja/?hl=en'
                                        target='_blank'
                                        title='Instagram'
                                        className='dark:text-white hover:text-blue-500 dark:hover:text-blue-500 hover:text-3xl transition duration-300 text-2xl'
                                    >
                                        <i className='fab fa-instagram'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="hidden md:block px-4">
                            <button className="w-14 h-14 rounded-full border hover:bg-gray-100">
                            <svg className="mx-auto" width={19} height={13} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.69976 6.74869L0.749756 6.74869" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.69971 11.7497L17.6367 6.74869L9.69971 1.74769V11.7497Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </button>
                        </div> */}
                    </div>
                    {/* <div className="hidden md:block w-full text-center">
                        <button className="w-2 h-2 mr-6 rounded-full bg-gray-200" />
                        <button className="w-2 h-2 mr-6 rounded-full bg-gray-200" />
                        <button className="w-2 h-2 mr-6 rounded-full bg-blue-500" />
                        <button className="w-2 h-2 mr-6 rounded-full bg-gray-200" />
                        <button className="w-2 h-2 rounded-full bg-gray-200" />
                    </div>
                    <div className="md:hidden w-full text-center">
                        <button className="w-12 h-12 rounded-full border hover:bg-gray-50">
                            <svg className="mx-auto" width={19} height={13} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.30024 6.25131L18.2502 6.25131" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.30029 1.25031L1.36329 6.25131L9.30029 11.2523L9.30029 1.25031Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <button className="w-12 h-12 p-2 rounded-full border hover:bg-gray-50">
                            <svg className="mx-auto" width={19} height={13} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.69976 6.74869L0.749756 6.74869" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.69971 11.7497L17.6367 6.74869L9.69971 1.74769V11.7497Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div> */}
                </div>
            </section>
        </motion.div>
    )
}

export default Team
