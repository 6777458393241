import React from 'react'
const HowItWorks2 = () => (
    <section>
        {/* <div className='skew skew-top mr-for-radius'>
            <svg
                className='h-8 md:h-12 lg:h-20 w-full text-gray-50 dark:text-gray-700'
                viewBox='0 0 10 10'
                preserveAspectRatio='none'
            >
                <polygon fill='currentColor' points='0 0 10 10 0 10' />
            </svg>
        </div> */}
        <div className='skew skew-top ml-for-radius'>
            <svg
                className='h-8 md:h-12 lg:h-20 w-full text-gray-100 dark:text-gray-700 rounded-tr-lg rounded-bl-lg'
                viewBox='0 0 10 10'
                preserveAspectRatio='none'
            >
                <polygon fill='currentColor' points='0 10 10 0 10 10' />
            </svg>
        </div>
        <div className='py-20 bg-gray-100 dark:bg-gray-700 radius-for-skewed px-3 rounded-tl-lg rounded-br-lg'>
            <div className='container mx-auto px-4'>
                <div className='flex flex-wrap items-center -mx-4'>
                    <div className='mb-12 lg:mb-0 w-full lg:w-1/2 px-4'>
                        <div className='max-w-md'>
                            {/* <span className='text-blue-500 font-bold'>
                                Dolor sit amet consectutar
                            </span> */}
                            <h2 className='mb-3 text-4xl lg:text-5xl font-bold font-heading dark:text-white'>
                                Explore &amp; Share without problems
                            </h2>
                            <p className='mb-6 max-w-sm text-gray-400 dark:text-gray-300 leading-loose'>
                                Once you have signed up, create a bucket list
                                and start writing down the things you would like
                                to try while you still can.
                            </p>
                            <ul className='text-gray-500 dark:text-gray-400 font-bold'>
                                <li className='mb-2 flex items-center'>
                                    <svg
                                        className='mr-2 w-5 h-5 text-blue-500'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    <span>
                                        Check off items from your lists.
                                    </span>
                                </li>
                                <li className='mb-2 flex items-center'>
                                    <svg
                                        className='mr-2 w-5 h-5 text-blue-500'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    <span>
                                        Share pics and videos from your
                                        adventures.
                                    </span>
                                </li>
                                <li className='flex items-center'>
                                    <svg
                                        className='mr-2 w-5 h-5 text-blue-500'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    <span>
                                        Explore and follow the journeys of
                                        others.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <div className='mb-4 flex flex-wrap items-end'>
                            <div className='mb-4 lg:mb-0 w-full lg:w-2/3 px-3'>
                                <img
                                    className='w-full h-32 lg:h-48 object-cover rounded'
                                    src='https://images.unsplash.com/photo-1604701145653-ab3c97c63467?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
                                    alt
                                />
                            </div>
                            <div className='w-full lg:w-1/3 px-3'>
                                <img
                                    className='w-full h-32 object-cover rounded'
                                    src='https://images.unsplash.com/photo-1521581171443-58a6b508b9ac?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
                                    alt
                                />
                            </div>
                        </div>
                        <div className='flex flex-wrap items-start'>
                            <div className='mb-4 lg:mb-0 w-full lg:w-1/3 px-3'>
                                <img
                                    className='w-full h-32 object-cover rounded'
                                    src='https://images.unsplash.com/photo-1501706649056-3cb79cccec52?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80'
                                    alt
                                />
                            </div>
                            <div className='w-full lg:w-2/3 px-3'>
                                <img
                                    className='w-full h-32 lg:h-48 object-cover rounded'
                                    src='https://images.unsplash.com/photo-1489058535093-8f530d789c3b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
                                    alt
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='skew skew-bottom mr-for-radius'>
            <svg
                className='h-8 md:h-12 lg:h-20 w-full text-gray-100 dark:text-gray-700 rounded-bl-lg'
                viewBox='0 0 10 10'
                preserveAspectRatio='none'
            >
                <polygon fill='currentColor' points='0 0 10 0 0 10' />
            </svg>
        </div>
        {/* <div className='skew skew-bottom ml-for-radius'>
            <svg
                className='h-8 md:h-12 lg:h-20 w-full text-gray-50 dark:text-gray-700'
                viewBox='0 0 10 10'
                preserveAspectRatio='none'
            >
                <polygon fill='currentColor' points='0 0 10 0 10 10' />
            </svg>
        </div> */}
    </section>
)
export default HowItWorks2
