import React from 'react'
import { Helmet } from 'react-helmet'
const Meta = ({ title, description, keywords }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content='description' />
            <meta name='keywords' content={keywords} />
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Jetlyst | Home',
    description:
        'Jetlyst is here to help you manage your bucket list, share your experiences and follow the journeys of those crossing items off their  bucket lists.',
    keywords:
        'bucket list, bucketlist, share journey, travel, track journey, nomad, adventure',
}
export default Meta
