import {
	BUCKETLISTS_REQUEST,
	BUCKETLISTS_SUCCESS,
	BUCKETLISTS_FAIL,
	BUCKETLISTS_RESET,
	ADD_LIST_ITEM_REQUEST,
	ADD_LIST_ITEM_SUCCESS,
	ADD_LIST_ITEM_FAIL,
	ADD_LIST_REQUEST,
	ADD_LIST_SUCCESS,
	ADD_LIST_FAIL,
	UPDATE_LIST_ITEM_REQUEST,
	UPDATE_LIST_ITEM_SUCCESS,
	UPDATE_LIST_ITEM_FAIL,
	UPDATE_LIST_REQUEST,
	UPDATE_LIST_SUCCESS,
	UPDATE_LIST_FAIL,
	DELETE_LIST_REQUEST,
	DELETE_LIST_SUCCESS,
	DELETE_LIST_FAIL,
	DELETE_LIST_ITEM_REQUEST,
	DELETE_LIST_ITEM_SUCCESS,
	DELETE_LIST_ITEM_FAIL,
	LIST_ITEMS_REQUEST,
	LIST_ITEMS_SUCCESS,
	LIST_ITEMS_FAIL,
	LIST_ITEM_REQUEST,
	LIST_ITEM_SUCCESS,
	LIST_ITEM_FAIL,
	OTHER_BUCKETLISTS_REQUEST,
	OTHER_BUCKETLISTS_SUCCESS,
	OTHER_BUCKETLISTS_FAIL,
	OTHER_BUCKETLISTS_RESET,
	ADD_LIST_RESET,
	ADD_LIST_ITEM_RESET,
	UPDATE_LIST_ITEM_RESET,
	UPDATE_LIST_RESET,
	DELETE_LIST_ITEM_RESET,
	DELETE_LIST_RESET,
} from '../constants/listConstants'

export const bucketListsReducer = (state = { bucketlists: [] }, action) => {
	switch (action.type) {
		case BUCKETLISTS_REQUEST:
			return { loading: true }
		case BUCKETLISTS_SUCCESS:
			return { loading: false, bucketlists: action.payload }
		case BUCKETLISTS_FAIL:
			return { loading: false, error: action.payload }
		case BUCKETLISTS_RESET:
			return { lists: [] }
		default:
			return state
	}
}

export const otherBucketListsReducer = (state = { otherLists: [] }, action) => {
	switch (action.type) {
		case OTHER_BUCKETLISTS_REQUEST:
			return { loading: true }
		case OTHER_BUCKETLISTS_SUCCESS:
			return { loading: false, otherLists: action.payload }
		case OTHER_BUCKETLISTS_FAIL:
			return { loading: false, error: action.payload }
		case OTHER_BUCKETLISTS_RESET:
			return { otherLists: [] }
		default:
			return state
	}
}

export const listItemsReducer = (state = { list: [] }, action) => {
	switch (action.type) {
		case LIST_ITEMS_REQUEST:
			return { loading: true }
		case LIST_ITEMS_SUCCESS:
			return { loading: false, list: action.payload }
		case LIST_ITEMS_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const listItemReducer = (state = {}, action) => {
	switch (action.type) {
		case LIST_ITEM_REQUEST:
			return { loading: true }
		case LIST_ITEM_SUCCESS:
			return { loading: false, listItem: action.payload }
		case LIST_ITEM_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const addListReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_LIST_REQUEST:
			return { loading: true }
		case ADD_LIST_SUCCESS:
			return { loading: false, success: true, item: action.payload }
		case ADD_LIST_FAIL:
			return { loading: false, error: action.payload }
		case ADD_LIST_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}

export const addItemReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_LIST_ITEM_REQUEST:
			return { loading: true }
		case ADD_LIST_ITEM_SUCCESS:
			return { loading: false, success: true, item: action.payload }
		case ADD_LIST_ITEM_FAIL:
			return { loading: false, error: action.payload }
		case ADD_LIST_ITEM_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}

export const updateListItemReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_LIST_ITEM_REQUEST:
			return { loading: true }
		case UPDATE_LIST_ITEM_SUCCESS:
			return { loading: false, success: true }
		case UPDATE_LIST_ITEM_FAIL:
			return { loading: false, error: action.payload }
		case UPDATE_LIST_ITEM_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}

export const updateListReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_LIST_REQUEST:
			return { loading: true }
		case UPDATE_LIST_SUCCESS:
			return { loading: false, success: true }
		case UPDATE_LIST_FAIL:
			return { loading: false, error: action.payload }
		case UPDATE_LIST_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}

export const deleteListItemReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_LIST_ITEM_REQUEST:
			return { loading: true }
		case DELETE_LIST_ITEM_SUCCESS:
			return { loading: false, success: true }
		case DELETE_LIST_ITEM_FAIL:
			return { loading: false, error: action.payload }
		case DELETE_LIST_ITEM_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}

export const deleteListReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_LIST_REQUEST:
			return { loading: true }
		case DELETE_LIST_SUCCESS:
			return { loading: false, success: true }
		case DELETE_LIST_FAIL:
			return { loading: false, error: action.payload }
		case DELETE_LIST_RESET:
			return { ...state, success: false}
		default:
			return state
	}
}
