import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import axios from 'axios'
import ReactNotification from 'react-notifications-component'
import Nav from './components/Nav'
import Footer from './components/common/Footer'
import CookieModal from './components/common/CookieModal'
import { createPubNubListener } from 'pubnub-redux'
import { PubNubProvider } from 'pubnub-react'
import { LATEST_MESSAGES_SUCCESS } from './constants/chatConstants'
import { initiateChatClient } from './actions/chatActions'
import 'react-notifications-component/dist/theme.css'
import { store as notifStore } from 'react-notifications-component'
import Notification from './components/chats/Notification'
import Meta from './components/common/Meta'
import Routes from './components/Routes'

function App({ pubnubClient, store }) {
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const currentChatUser = useSelector((state) => state.chatUser)
    const { byId } = currentChatUser
    const latestMessagesState = useSelector((state) => state.latestMessages)
    const { latestMessages } = latestMessagesState
    const currentChatObject = useSelector((state) => state.currentChat)
    const { chat: currentChat } = currentChatObject
    const augmentedChats = useSelector((state) => state.augmentedChats)
    const { chats } = augmentedChats

    const leaveApplication = () => {
        // This is required to show the current user leave immediately rather than
        // wating for the timeout period
        pubnubClient.unsubscribeAll()
    }

    const messageListener = async (message) => {
        if (
            message.message.sender !== userInfo._id &&
            currentChat.id !== message.channel
        ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let user
            if (
                chats.length > 1 &&
                chats.find((item) => item.id === message.channel)
            ) {
                user = chats.find(
                    (item) => item.id === message.channel
                ).otherMember
            } else {
                let otherMember = message.channel
                    .split('-')
                    .filter((item) => item !== userInfo._id)
                const { data } = await axios.get(
                    `/api/users/${otherMember}/details`,
                    config
                )
                user = data
            }
            let newchannels = {
                ...latestMessages.channels,
                [message.channel]: [
                    {
                        channel: message.channel,
                        message: message.message,
                        timetoken: message.timetoken,
                    },
                ],
            }
            dispatch({
                type: LATEST_MESSAGES_SUCCESS,
                payload: { channels: newchannels },
            })
            console.log(notifStore)
            notifStore.addNotification({
                title: `New message from ${user.username}`,
                message: (
                    <Notification
                        notification={{
                            currentUserId: userInfo._id,
                            user,
                            message: message.message.content.body,
                        }}
                    />
                ),
                type: 'info',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 4000,
                    onScreen: true,
                },
            })
        }
    }

    useEffect(() => {
        console.log(notifStore)
        pubnubClient.addListener(createPubNubListener(store.dispatch))
        pubnubClient.addListener({
            message: function (m) {
                messageListener(m)
            },
        })
        if (userInfo && !byId[userInfo._id]) {
            // pubnubClient.unsubscribe({ channels: [userInfo._id] })
            dispatch(initiateChatClient(userInfo))
        }
        return leaveApplication
    }, [])
    useEffect(() => {
        window.addEventListener('beforeunload', leaveApplication)
    }, [])
    const jetlystGAID = 'G-JTF685V0RL'
    const [isCookieSet, setIsCookieSet] = useState(false)
    let cookieConsent
    const setCookie = (name, value) =>
        (document.cookie = `${name}=${value}; path=/; max-age=${
            60 * 60 * 24 * 182
        };`)

    const getCookie = (name) => {
        let value = `; ${document.cookie}`
        let parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const checkIfSet = () => {
        const jetlystGaCookie = getCookie('jt_ga')
        if (
            jetlystGaCookie &&
            (jetlystGaCookie === 'granted' || jetlystGaCookie === 'declined')
        ) {
            setIsCookieSet(true)
        } else {
            setIsCookieSet(false)
        }
    }

    const consentGranted = () => {
        setCookie('jt_ga', 'granted')
        checkIfSet()
        window.gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
        })
    }

    const consentDenied = () => {
        setCookie('jt_ga', 'declined')
        checkIfSet()
    }
    return (
        <PubNubProvider client={pubnubClient}>
            <Router>
                <main className='flex flex-col min-h-screen dark:bg-gray-800 overflow-hidden'>
                    <ReactNotification
                        className={'rounded-2xl overflow-hidden'}
                    />
                    <Nav />
                    <Meta />
                    <Route
                        render={({ location }) => (
                            <Routes
                                location={location}
                                userInfo={userInfo}
                                checkIfSet={checkIfSet}
                                jetlystGAID={jetlystGAID}
                            />
                        )}
                    />
                    <CookieModal
                        isCookieSet={isCookieSet}
                        cookieConsent={cookieConsent}
                        consentDenied={consentDenied}
                        consentGranted={consentGranted}
                    />
                    <Footer />
                </main>
            </Router>
        </PubNubProvider>
    )
}

export default App
