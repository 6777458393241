import React from 'react'
import spinner from './spinner.gif'

function Spinner() {
	return (
		<div className='h-full'>
			{/* <img
				src={spinner}
				alt='Loading...'
				style={{ width: '200px', margin: 'auto', display: 'block' }}
			/> */}
			<div className='rounded-full h-full flex items-center justify-center'>
				<svg
					className='animate-spin-slow h-32 w-32 rounded-full border-dashed bg-none border-gray-800 dark:border-white border-4 m-0'
					viewBox='0 24 24 24'
				></svg>
			</div>
		</div>
	)
}

export default Spinner
