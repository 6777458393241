import React from 'react'
import { motion } from 'framer-motion'
import Question from '../components/FAQ/Question'
import Meta from '../components/common/Meta'
const howTo = (
    <>
        <hr />
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                1.{' '}
                <a href='https://jetlyst.com/' target='_blank'>
                    Go to Jetlyst | Home
                </a>
            </h3>
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                2. Click on Login
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_ccd36747-3ec7-444f-87bb-29b2358159ac?crop=focalpoint&fit=crop&fp-x=0.5534&fp-y=0.0262&fp-z=3.1018&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on Login'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                3. Login to your account
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_a9c01231-576a-497e-87d5-78ea7c5b1028?crop=focalpoint&fit=crop&fp-x=0.2766&fp-y=0.4265&fp-z=1.5047&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Login to your account'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                4. Click on Add List(on the "My Bucketlists" page)
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_892d3f4a-c2d1-4470-842a-79d99067c3d8?crop=focalpoint&fit=crop&fp-x=0.9083&fp-y=0.1357&fp-z=2.9316&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on Add List(on the "My Bucketlists" page)'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                5. Click on the Edit button
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_a897909d-7ec4-45aa-85c6-4a85559b853b?crop=focalpoint&fit=crop&fp-x=0.8005&fp-y=0.4481&fp-z=3.1601&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on the Edit button'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                6. Type in the name of the new bucket list
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_0a28aaa4-028f-45c0-a5d1-e171105f72bb?crop=focalpoint&fit=crop&fp-x=0.5146&fp-y=0.3114&fp-z=1.9512&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Type in the name of the new bucket list'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                7. Type in the description of the bucket list
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_cf3948b3-9ba3-474e-bc26-d0dae9b82d87?crop=focalpoint&fit=crop&fp-x=0.5146&fp-y=0.3936&fp-z=1.9512&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Type in the description of the bucket list'
            />
        </div>
        <div className='mb-3'>
            <h3 className='text-gray-500 dark:text-gray-300'>
                8. Click on Update Item
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_9077c859-1b18-4238-8b84-1154b8fc99f8?crop=focalpoint&fit=crop&fp-x=0.5820&fp-y=0.7431&fp-z=2.7706&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on Update Item'
            />
        </div>
        <hr />
        <div className='mb-3'>
            <a
                href='https://app.tango.us/app/workflow/b59331b9-d64d-4a53-8086-ea17b4d613cb'
                target='_blank'
            >
                <em>This workflow was created with Tango.</em>
            </a>
        </div>
    </>
)
const newChat = (
    <>
        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                1.{' '}
                <a href='https://jetlyst.com/bucketlists' target='_blank'>
                    Login to Jetlyst
                </a>
            </h3>
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                2. Click into the search input box
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_bb20802a-6597-4be7-a0c0-485d90a36885?crop=focalpoint&fit=crop&fp-x=0.4797&fp-y=0.0329&fp-z=2.4000&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click into the search input box'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                3. Type the username of the user you want to contact
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_ee270b37-452a-467a-a65c-b5944e95eeb3?crop=focalpoint&fit=crop&fp-x=0.4750&fp-y=0.0329&fp-z=2.5600&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Type the username of the user you want to contact'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                4. Click on their username in the dropdown list
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_cebabc7b-2720-49b5-98d3-acaba812ebf7?crop=focalpoint&fit=crop&fp-x=0.4818&fp-y=0.2775&fp-z=2.8571&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on their username in the dropdown list'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                5. Click on the Message button
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_52092470-f56b-4a19-b6c5-4f549906ce39?crop=focalpoint&fit=crop&fp-x=0.8885&fp-y=0.3618&fp-z=2.9316&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on the Message button'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                6. Type your message
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_43431b41-2f06-407a-b65d-6c0c7a7d626c?crop=focalpoint&fit=crop&fp-x=0.4456&fp-y=0.7684&fp-z=2.1594&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Type your message'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                7. Click on the send button
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_1ec9212f-bae0-48a5-b0ec-e927214891fa?crop=focalpoint&fit=crop&fp-x=0.9792&fp-y=0.9507&fp-z=4.0000&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='Click on the send button'
            />
        </div>

        <div>
            <h3 className='text-gray-500 dark:text-gray-300'>
                8. and thats it! :)
            </h3>
            <img
                src='https://images.tango.us/public/screenshot_efa2af57-11ea-4557-bfab-162d762ee305?crop=focalpoint&fit=crop&fp-x=0.7500&fp-y=0.2499&fp-z=2.0000&w=1200&mark-w=0.2&mark-pad=0&mark64=aHR0cHM6Ly9pbWFnZXMudGFuZ28udXMvc3RhdGljL21hZGUtd2l0aC10YW5nby13YXRlcm1hcmsucG5n&ar=1920%3A973'
                width='600'
                className='rounded-lg'
                alt='and thats it! :)'
            />
        </div>

        <hr />
        <div>
            <a
                href='https://app.tango.us/app/workflow/30c704d8-e5d9-491f-8116-a4a31cef3958'
                target='_blank'
            >
                <em>This workflow was created with Tango.</em>
            </a>
        </div>
    </>
)

const newFeatures = (
    <>
        <div className=''>
            <ul className='new-features-list pl-4'>
                <li>Voice and video call capability</li>
                <li>Group chats</li>
                <li>
                    A discover page to find new content you would be intersetd
                    in.
                </li>
                <li>A business account with added analytical features.</li>
                <li>
                    Library of images and videos under one bucket list / list
                    item{' '}
                </li>
            </ul>
        </div>
    </>
)
const FAQScreen = () => {
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | FAQ`} />

            <section className='relative py-20'>
                <div className='container px-4 mx-auto'>
                    <div className='max-w-2xl mx-auto mb-20 text-center'>
                        <h2 className='mt-8 text-4xl dark:text-white font-black'>
                            FREQUENTLY ASKED QUESTIONS
                        </h2>
                    </div>
                    {/* <div className="max-w-4xl mx-auto mb-12">
						<div className="flex flex-wrap -mx-4">
							<div className="w-full md:w-1/3 lg:w-1/5 mb-6 lg:mb-0 px-4">
								<button className="w-full py-6 rounded-xl bg-red-400">
									<svg className="mx-auto mb-5" width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.65991 17.7087L11.6508 12.5218L16.203 16.0977L20.1084 11.0573" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<circle cx="24.6606" cy="3.60028" r="2.56293" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M17.8994 2.16016H8.20906C4.1938 2.16016 1.7041 5.00379 1.7041 9.01904V19.7956C1.7041 23.8108 4.14499 26.6422 8.20906 26.6422H19.6812C23.6965 26.6422 26.1862 23.8108 26.1862 19.7956V10.4103" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									<p className="text-lg text-white font-semibold font-heading">General</p>
								</button>
							</div>
							<div className="w-full md:w-1/3 lg:w-1/5 mb-6 lg:mb-0 px-4">
								<button className="w-full py-6 rounded-xl bg-gray-50 hover:bg-gray-100">
									<svg className="mx-auto mb-5" width={26} height={28} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M24.7424 8.16474L23.9125 6.72461C23.2104 5.50605 21.6544 5.08568 20.4342 5.78487V5.78487C19.8534 6.12704 19.1603 6.22412 18.5078 6.0547C17.8553 5.88528 17.297 5.46327 16.956 4.88175C16.7366 4.51212 16.6187 4.09111 16.6142 3.6613V3.6613C16.634 2.97221 16.374 2.30445 15.8935 1.81014C15.413 1.31583 14.7529 1.03707 14.0635 1.03735H12.3915C11.7161 1.03735 11.0686 1.30647 10.5922 1.78518C10.1157 2.26389 9.84973 2.91271 9.85298 3.58808V3.58808C9.83296 4.98249 8.6968 6.10233 7.30225 6.10219C6.87245 6.09773 6.45144 5.97984 6.08181 5.76047V5.76047C4.86158 5.06127 3.30565 5.48164 2.60355 6.70021L1.71262 8.16474C1.01138 9.38178 1.42603 10.9367 2.64016 11.643V11.643C3.42937 12.0986 3.91554 12.9407 3.91554 13.852C3.91554 14.7633 3.42937 15.6054 2.64016 16.061V16.061C1.42757 16.7625 1.01247 18.3137 1.71262 19.5271V19.5271L2.55473 20.9794C2.88369 21.573 3.43563 22.011 4.08842 22.1965C4.74121 22.382 5.44102 22.2998 6.03299 21.9679V21.9679C6.61494 21.6284 7.30841 21.5353 7.95928 21.7095C8.61015 21.8837 9.16448 22.3106 9.49905 22.8955C9.71843 23.2651 9.83631 23.6861 9.84077 24.1159V24.1159C9.84077 25.5246 10.9828 26.6666 12.3915 26.6666H14.0635C15.4675 26.6667 16.6075 25.5321 16.6142 24.1281V24.1281C16.611 23.4506 16.8787 22.7999 17.3577 22.3209C17.8368 21.8418 18.4875 21.5741 19.165 21.5774C19.5937 21.5889 20.013 21.7063 20.3854 21.9191V21.9191C21.6024 22.6204 23.1574 22.2057 23.8637 20.9916V20.9916L24.7424 19.5271C25.0825 18.9432 25.1759 18.2479 25.0018 17.5951C24.8277 16.9422 24.4005 16.3857 23.8148 16.0488V16.0488C23.2292 15.7119 22.802 15.1554 22.6279 14.5025C22.4538 13.8497 22.5472 13.1543 22.8873 12.5705C23.1085 12.1844 23.4287 11.8642 23.8148 11.643V11.643C25.0217 10.9371 25.4354 9.39124 24.7424 8.17694V8.17694V8.16474Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<circle cx="13.2336" cy="13.852" r="3.51487" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									<p className="text-lg text-gray-500 font-semibold font-heading">Tool</p>
								</button>
							</div>
							<div className="w-full md:w-1/3 lg:w-1/5 mb-6 lg:mb-0 px-4">
								<button className="w-full py-6 rounded-xl bg-gray-50 hover:bg-gray-100">
									<svg className="mx-auto mb-5" width={28} height={26} viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M26.8521 16.1943H21.4543C19.4724 16.1931 17.866 14.5879 17.8647 12.6059C17.8647 10.624 19.4724 9.01879 21.4543 9.01758H26.8521" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M22.0649 12.5238H21.6493" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path fillRule="evenodd" clipRule="evenodd" d="M8.33038 1H19.855C23.7192 1 26.8518 4.13268 26.8518 7.99689V17.5663C26.8518 21.4305 23.7192 24.5631 19.855 24.5631H8.33038C4.46618 24.5631 1.3335 21.4305 1.3335 17.5663V7.99689C1.3335 4.13268 4.46618 1 8.33038 1Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M7.38086 7.05094H14.5795" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									<p className="text-lg text-gray-500 font-semibold font-heading">
									Payments
									</p>
								</button>
							</div>
							<div className="w-full md:w-1/3 lg:w-1/5 mb-6 md:mb-0 px-4">
								<button className="w-full py-6 rounded-xl bg-gray-50 hover:bg-gray-100">
									<svg className="mx-auto mb-5" width={26} height={24} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M19.4527 22.8856V4.72852" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M24.8897 17.4241L19.4527 22.8863L14.0156 17.4241" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M6.21486 1.11047V19.2675" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M0.777832 6.57196L6.21487 1.10974L11.6519 6.57196" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									<p className="text-lg text-gray-500 font-semibold font-heading">
									Returns
									</p>
								</button>
							</div>
							<div className="w-full md:w-1/3 lg:w-1/5 px-3">
								<button className="w-full py-6 rounded-xl bg-gray-50 hover:bg-gray-100">
									<svg className="mx-auto mb-5" width={30} height={24} viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.466 1.66711V4.89378" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M17.466 19.6802V22.3788" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M17.466 15.0995V8.67151" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path fillRule="evenodd" clipRule="evenodd" d="M23.936 22.6667C26.3654 22.6667 28.3332 20.7234 28.3332 18.3242V14.8676C26.7256 14.8676 25.4309 13.589 25.4309 12.0014C25.4309 10.4138 26.7256 9.1339 28.3332 9.1339L28.3318 5.67585C28.3318 3.27664 26.3627 1.33337 23.9346 1.33337H6.06506C3.63702 1.33337 1.66789 3.27664 1.66789 5.67585L1.6665 9.24651C3.27407 9.24651 4.56874 10.4138 4.56874 12.0014C4.56874 13.589 3.27407 14.8676 1.6665 14.8676V18.3242C1.6665 20.7234 3.63424 22.6667 6.06367 22.6667H23.936Z" stroke="#838EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									<p className="text-lg text-gray-500 font-semibold font-heading">
									Refunds
									</p>
								</button>
							</div>
						</div>
					</div> */}
                    <div className='max-w-4xl mx-auto'>
                        <ul className='space-y-4'>
                            <Question
                                question='Can I use this website free of charge?'
                                answer='Yes, totally! This website has been created to help others manage their 
										bucket lists free of charge. Feel free to sign up and get started on whatever 
										items you have in your lists'
                            />
                            <Question
                                question='How do I create a new bucket list?'
                                answer={howTo}
                            />
                            <Question
                                question='How can I start a new private chat?'
                                answer={newChat}
                            />
                            <Question
                                question='What new features are can I expect to see in the near future?'
                                answer={newFeatures}
                            />
                        </ul>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default FAQScreen
