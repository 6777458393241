import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { motion } from 'framer-motion'
import { USER_LOGIN_SUCCESS } from '../../constants/userConstants'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import Comment from './Comment'
import LikesModal from './LikesModal'
import { MY_POSTS_SUCCESS } from '../../constants/feedConstants'
import { deleteComment, deletePost } from '../../actions/feedActions'
SwiperCore.use([Pagination, Navigation])
const Post = ({ by, post, commentHandler }) => {
    const dispatch = useDispatch()
    const [showComments, setShowComments] = useState(false)
    const [showMoreButton, setShowMoreButton] = useState(false)
    const [longCaption, setLongCaption] = useState(false)
    const [showCopied, setShowCopied] = useState(false)
    const [comments, setComments] = useState([])
    const [likes, setLikes] = useState([])
    const [showLikes, setShowLikes] = useState(false)
    const [showUserOptions, setShowUserOptions] = useState(false)
    const [likesInfo, setLikesInfo] = useState([])
    const [longLikesInfo, setLongLikesInfo] = useState([])
    const [comment, setComment] = useState('')
    const [replyTo, setReplyTo] = useState({})
    const [commentsCount, setCommentsCount] = useState(0)
    const [liked, setLiked] = useState(false)
    const [saved, setSaved] = useState(true)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const date = moment(new Date(post.createdAt)).fromNow()
    useEffect(() => {
        if (post) {
            setLikes(post.likes)
            setLikesInfo(post.likesInfo ? post.likesInfo : [])
            setCommentsCount(post.commentsCount)
            if (userInfo && post.likes.includes(userInfo._id)) {
                setLiked(true)
            } else {
                setLiked(false)
            }

            if (userInfo && userInfo.saved.includes(post._id)) {
                setSaved(true)
            } else {
                setSaved(false)
            }
            const innerHeight = document.getElementById(
                `post-caption-${post._id}`
            ).scrollHeight
            const otherHeight = document.getElementById(
                `caption-container-${post._id}`
            ).clientHeight
            if (innerHeight > otherHeight) {
                setLongCaption(true)
                setShowMoreButton(true)
            }
        }
    }, [post])
    const resize = (val) => {
        let field = document.getElementById(`new-comment${post._id}`)
        let bottom = document.getElementById(`new-comment-bottom${post._id}`)
        if (val === '') {
            field.style.height = '2.5rem'
        } else {
            field.style.height = 'auto'
            field.style.height = field.scrollHeight + 'px'
            bottom.scrollIntoView({ behavior: 'smooth' })
        }
    }
    const handleLike = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.put(
                `/api/posts/${post._id}/like`,
                {},
                config
            )
            if (data.success) {
                setLikes(data.newLikes)
                setLiked(!liked)
                if (data.addInfo) {
                    setLikesInfo((prevState) => [
                        ...prevState,
                        {
                            _id: userInfo._id,
                            imageUrl: userInfo.imageUrl,
                            name: userInfo.name,
                            username: userInfo.username,
                        },
                    ])
                } else {
                    setLikesInfo((prevState) => [
                        ...prevState.filter(
                            (item) => item._id !== userInfo._id
                        ),
                    ])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleSave = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.put(
                `/api/posts/${post._id}/save`,
                {},
                config
            )
            if (data.success) {
                setSaved(!saved)
                console.log(data.userInfo)
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: data.userInfo,
                })
                localStorage.setItem('userInfo', JSON.stringify(data.userInfo))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getComments = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/${post._id}/comments`,
                config
            )
            setComments(data)
            setShowComments(true)
        } catch (error) {
            console.log(error)
        }
    }
    const getLikes = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/${post._id}/likes`,
                config
            )
            setLongLikesInfo(data)
            setShowLikes(true)
        } catch (error) {
            console.log(error)
        }
    }
    const handleComment = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            const { data } = await axios.post(
                `/api/posts/${post._id}/comments`,
                {
                    text: comment,
                    replyId: replyTo.id || '',
                    replyTo: replyTo.to || '',
                },
                config
            )
            if (data) {
                setComment('')
                setReplyTo({})
                if (!data.replyTo) {
                    setComments((prevState) => [
                        ...prevState,
                        {
                            ...data,
                            postedBy: {
                                _id: userInfo._id,
                                imageUrl: userInfo.imageUrl,
                                username: userInfo.username,
                            },
                        },
                    ])
                } else {
                    if (showComments) {
                        setComments((prevState) => [
                            ...prevState.map((comment) => {
                                let currReplies = comment.replies
                                if (
                                    comment._id.toString() ===
                                    data.parent.toString()
                                ) {
                                    return {
                                        ...comment,
                                        replies: [
                                            ...currReplies,
                                            {
                                                ...data,
                                                postedBy: {
                                                    _id: userInfo._id,
                                                    imageUrl: userInfo.imageUrl,
                                                    username: userInfo.username,
                                                },
                                            },
                                        ],
                                    }
                                } else {
                                    return comment
                                }
                            }),
                        ])
                    } else {
                        setCommentsCount((prevState) => prevState + 1)
                    }
                }

                setCommentsCount((prevState) => prevState + 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const seeMore = () => {
        let el = document.getElementById(`caption-container-${post._id}`)
        let otherEl = document.getElementById(`post-caption-${post._id}`)
        console.log(el)
        if (el.classList.contains('max-h-20')) {
            console.log('inhere')
            el.classList.remove('max-h-20')
        }
        if (otherEl.classList.contains('post-text')) {
            otherEl.classList.remove('post-text')
        }
        if (showMoreButton) setShowMoreButton(false)
    }

    const seeLess = () => {
        let el = document.getElementById(`caption-container-${post._id}`)
        let otherEl = document.getElementById(`post-caption-${post._id}`)
        console.log(el)
        if (!el.classList.contains('max-h-20')) {
            console.log('inhere')
            el.classList.add('max-h-20')
        }
        if (!otherEl.classList.contains('post-text')) {
            otherEl.classList.add('post-text')
        }
        if (!showMoreButton) setShowMoreButton(true)
    }

    const hideComments = () => {
        setShowComments(false)
        setComments([])
    }

    const handleCommentClick = () => {
        let input = document.getElementById(`new-comment${post._id}`)
        if (input) {
            input.focus()
            input.scrollTo({
                behavior: 'smooth',
                inline: 'nearest',
            })
        }
    }
    const handleDeletePost = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        if (
            userInfo._id === by._id &&
            window.confirm('Are you sure you want to delete this post?')
        ) {
            const mediaKeys = post.detail.media.map((item) => {
                return item.substring(item.lastIndexOf('/') + 1)
            })
            setShowUserOptions(false)
            dispatch(deletePost(post._id))
            await axios.delete(`/api/media/objects?keys=${mediaKeys}`, config)
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://jetlyst.com/posts/${post._id}`)
        setShowCopied(true)

        setTimeout(() => {
            setShowCopied(false)
        }, 1200)
    }

    const handleDelete = (id) => {
        dispatch(deleteComment(id))
        setComments((prevState) => [
            ...prevState.filter((item) => item._id !== id),
        ])
    }

    return (
        <div className='w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto bg-gray-100 dark:bg-gray-700 md:rounded-xl overflow-hidden mb-3 md:mb-6 py-3'>
            <LikesModal
                openModal={showLikes}
                setOpenModal={setShowLikes}
                likes={longLikesInfo}
            />
            <div className='h-16 mb-2 w-full flex justify-between md:pt-3 px-4 md:px-5 '>
                <div className='h-full w-5/6 md:w-3/4 flex items-center'>
                    <img
                        className='rounded-full h-12 md:h-16 w-12 md:w-16 object-cover'
                        src={by.imageUrl}
                        alt=''
                    />
                    <div className='w-full flex flex-col pl-3 md:pl-6 '>
                        <p className='font-bold dark:text-white text-sm md:text-base'>
                            {by.name}
                        </p>
                        <Link
                            to={
                                by.username === userInfo.username
                                    ? '/profile'
                                    : `/${by.username}`
                            }
                            className='font-bold text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 text-xs md:text-sm w-auto'
                        >
                            @{by.username}
                        </Link>
                        {/* <p className='text-sm text-gray-600  dark:text-gray-300'>
                            {new Date(post.createdAt).toLocaleDateString(
                                'en-CA',
                                {
                                    day: 'numeric',
                                    month: 'long',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                }
                            )}
                        </p> */}
                    </div>
                </div>
                <div className='h-full flex items-center justify-end noSelect'>
                    <div
                        onClick={handleCopy}
                        className='h-full flex items-center justify-end noSelect'
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:text-white hover:text-blue-500 dark:hover:text-blue-500 transition noSelect'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z'
                            />
                        </svg>
                        {showCopied && (
                            <motion.div
                                initial={{ opacity: 0, y: 20, scale: 0.8 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 20, scale: 1.2 }}
                                transition={{
                                    type: 'tween',
                                    ease: 'anticipate',
                                    duration: 0.3,
                                }}
                                className='absolute mb-14 rounded-lg px-4 py-2 text-sm bg-gray-300 dark:text-white dark:bg-gray-900 opacity-60'
                            >
                                link copied
                            </motion.div>
                        )}

                        {/* <svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-6 w-6 '
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth={2}
							d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
						/>
					</svg> */}
                    </div>
                    {by._id === userInfo._id && (
                        <div className='h-full md:ml-2 flex flex-col justify-center items-end noSelect'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:text-white hover:text-blue-500 dark:hover:text-blue-500 transition noSelect ml-4'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                                onClick={() =>
                                    setShowUserOptions(!showUserOptions)
                                }
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={2}
                                    d='M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z'
                                />
                            </svg>
                            {showUserOptions && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20, scale: 0.8 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    exit={{ opacity: 0, y: 20, scale: 1.2 }}
                                    transition={{
                                        type: 'tween',
                                        ease: 'anticipate',
                                        duration: 0.3,
                                    }}
                                    className='profile-header-button absolute mt-28 mr-2 md:mr-0 right-0 md:right-auto z-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                                >
                                    <div
                                        onClick={handleDeletePost}
                                        className='block px-4 py-2 text-sm text-red-500 cursor-pointer'
                                    >
                                        Delete post
                                    </div>

                                    {/* <div className='block px-4 py-2 text-sm text-gray-700 cursor-pointer'>
                                    Report...
                                </div> */}
                                </motion.div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div
                id={`caption-container-${post._id}`}
                className={
                    'h-auto max-h-20 w-full flex flex-col items-start dark:text-white px-4 md:px-5 pt-2 overflow-hidden' +
                    (longCaption ? '' : ' pb-2 mb-3')
                }
            >
                <p
                    id={`post-caption-${post._id}`}
                    className={
                        'max-w-full post-text overflow-hidden overflow-ellipsis text-sm md:text-base' +
                        (post.detail.text.includes(' ') ? '' : ' break-all')
                    }
                >
                    {post.detail.text}
                </p>
            </div>
            {longCaption &&
                (showMoreButton ? (
                    <button
                        onClick={seeMore}
                        className='w-auto text-blue-500 hover:text-blue-400 font-medium px-4 md:px-5 mb-3'
                    >
                        see more
                    </button>
                ) : (
                    <button
                        onClick={seeLess}
                        className='w-auto text-blue-500 hover:text-blue-400 font-medium px-4 md:px-5 mb-3'
                    >
                        see less
                    </button>
                ))}
            {post.detail.media.length > 0 &&
                (post.detail.media.length === 1 ? (
                    <div
                        onDoubleClick={handleLike}
                        className='h-96 md:h-96 mb-2 w-full overflow-hidden bg-black'
                    >
                        {post.detail.media[0].includes('-video') ? (
                            <video
                                src={post.detail.media[0]}
                                className='h-96 w-full object-contain bg-black my-auto'
                                controls
                                loop
                            />
                        ) : (
                            <img
                                src={post.detail.media[0]}
                                className='h-full w-full object-cover'
                            />
                        )}
                        {/* <img
                            src={post.detail.media[0]}
                            className='h-full w-full object-cover'
                        /> */}
                    </div>
                ) : (
                    <Swiper
                        onDoubleClick={handleLike}
                        pagination={{
                            clickable: true,
                        }}
                        centeredSlides
                        loop
                        slidesPerView={1}
                        className='w-full h-full flex flex-col-reverse max-h-96'
                    >
                        {post.detail.media.map((item) => (
                            <SwiperSlide className='h-full min-w-full overflow-hidden flex items-center bg-black'>
                                {item.includes('-video') ? (
                                    <video
                                        src={item}
                                        className='h-96 w-full object-contain bg-black my-auto'
                                        controls
                                        loop
                                    />
                                ) : (
                                    <img
                                        src={item}
                                        className='h-96 w-full object-cover my-auto'
                                    />
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ))}
            <div className='h-auto mb-2 w-full px-4 md:px-5 '>
                <div className='w-full flex items-center justify-between py-3 border-b dark:border-opacity-25'>
                    <div
                        onClick={handleCommentClick}
                        className='flex items-center flex-wrap dark:text-white cursor-pointer'
                    >
                        <i className='far fa-comment text-xl lg:text-2xl cursor-pointer' />
                    </div>
                    <div
                        onClick={handleLike}
                        className={
                            'flex items-center dark:text-white transition duration-200 cursor-pointer noSelect' +
                            (liked ? '  text-red-500 dark:text-red-500' : '')
                        }
                    >
                        {liked ? (
                            <i
                                className={
                                    'fas fa-heart text-xl lg:text-2xl cursor-pointer'
                                }
                            />
                        ) : (
                            <i
                                className={
                                    'far fa-heart text-xl lg:text-2xl cursor-pointer'
                                }
                            />
                        )}
                        {/* <span className='text-sm ml-2'>
                            {likes.length > 0
                                ? likes.length === 1
                                    ? '1 like'
                                    : `${likes.length} likes`
                                : ''}
                        </span> */}
                    </div>
                    <div
                        onClick={handleSave}
                        className={
                            'flex items-center dark:text-white transition duration-200 cursor-pointer noSelect' +
                            (saved ? '  text-blue-500 dark:text-blue-500' : '')
                        }
                    >
                        {saved ? (
                            <i
                                className={
                                    'fas fa-bookmark text-xl lg:text-2xl cursor-pointer'
                                }
                            />
                        ) : (
                            <i
                                className={
                                    'far fa-bookmark text-xl lg:text-2xl cursor-pointer'
                                }
                            />
                        )}
                    </div>
                </div>
                <div className='w-full flex justify-between mt-2 items-center'>
                    <p className='text-sm text-gray-600  dark:text-gray-300 mt-2'>
                        {/* {new Date(post.createdAt).toLocaleDateString('en-CA', {
                        day: 'numeric',
                        month: 'long',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                    })} */}
                        {moment(new Date(post.createdAt)).fromNow()}
                    </p>
                    <p className='dark:text-white text-sm hover:text-blue-500 dark:hover:text-blue-500 cursor-pointer'>
                        {likes && likes.length > 0 && (
                            <span
                                className='flex items-center space-x-1'
                                onClick={getLikes}
                            >
                                <span>Liked by</span>
                                <span className='flex items-center'>
                                    {likesInfo &&
                                        likesInfo.length > 0 &&
                                        likesInfo.map((item, index) => (
                                            <img
                                                src={item.imageUrl}
                                                alt='Like profile picture'
                                                className={
                                                    'rounded-full w-5 md:w-6 h-5 md:h-6 object-top object-cover' +
                                                    (index > 0 ? ' -ml-2' : '')
                                                }
                                            />
                                        ))}
                                </span>
                            </span>
                        )}
                    </p>
                </div>
                <div className='flex py-2 mt-2'>
                    {post && (commentsCount > 0 || comments.length > 0) ? (
                        !showComments ? (
                            <button
                                onClick={getComments}
                                className='text-sm text-gray-600 hover:text-blue-500 dark:hover:text-blue-500 dark:text-gray-300 transition'
                            >
                                See all{' '}
                                {commentsCount > 10 ? commentsCount : ''}{' '}
                                comments
                            </button>
                        ) : (
                            <button
                                onClick={hideComments}
                                className='text-sm text-gray-600 hover:text-blue-500 dark:hover:text-blue-500 dark:text-gray-300 transition'
                            >
                                Hide comments..
                            </button>
                        )
                    ) : (
                        <p className='text-sm text-gray-600 dark:text-gray-300 transition'>
                            Be the first to comment⚡️
                        </p>
                    )}
                </div>
                {showComments && (
                    <div className='max-h-80 overflow-y-auto no-scrollbar bg-transparent'>
                        <div className='overflow-hidden'>
                            {comments &&
                                comments.map((item) => {
                                    return (
                                        <Comment
                                            key={item._id}
                                            comment={item}
                                            postedBy={item.postedBy}
                                            setReplyTo={setReplyTo}
                                            setComment={setComment}
                                            handleDelete={handleDelete}
                                            postOwner={post.by === userInfo._id}
                                        />
                                    )
                                })}
                        </div>
                    </div>
                )}
                <div className='max-h-48 overflow-y-auto no-scrollbar'>
                    <div className='overflow-hidden flex flex-col items-center'></div>
                </div>
                {replyTo && replyTo.to && (
                    <div className='dark:text-gray-300 text-xs lg:text-sm mt-3 w-full flex items-center justify-between px-1'>
                        <div>
                            Replying to{' '}
                            <span className='text-gray-500 dark:text-gray-400 ml-1'>
                                {replyTo.to}
                            </span>
                        </div>
                        <span
                            onClick={() => setReplyTo({})}
                            className='text-red-400 hover:text-red-600 cursor-pointer'
                        >
                            cancel
                        </span>
                    </div>
                )}
                <div className='flex items-center pt-3 mb-5 w-full'>
                    <img
                        src={userInfo.imageUrl}
                        className='rounded-full object-cover h-8 md:h-11 w-8 md:w-11'
                    />
                    <div className='w-full flex flex-col  ml-2 md:ml-5 overflow-y-auto overflow-x-hidden max-h-28  no-scrollbar'>
                        <div className='w-full flex  h-full items-center justify-center'>
                            <textarea
                                type='text'
                                id={`new-comment${post._id}`}
                                placeholder='Make a comment...'
                                className={
                                    'w-full text-sm md:text-base bg-transparent border-0 outline-none placeholder-gray-400 px-1 md:px-3 dark:text-white resize-none h-10 min-h-10 pt-2 overflow-y-hidden'
                                }
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value)
                                    resize(e.target.value)
                                }}
                            />
                            <div
                                id={`new-comment-bottom${post._id}`}
                                className='hidden'
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => handleComment()}
                        className={
                            'ml-5 font-semibold text-center px-3 rounded-full oveflow-hidden text-white text-sm md:text-sm' +
                            (comment ? ' bg-blue-600' : ' bg-blue-500')
                        }
                        type='submit'
                    >
                        {/* <i className='far fa-paper-plane text-xl dark:text-white hover:text-blue-500 transition noSelect'></i> */}
                        Post
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Post
