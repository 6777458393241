import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import {
    sendMessageSocket,
    addMessage,
    loadInitialMessages,
    joinChat,
    leaveChat,
    sendMessage,
    sendTypingSignal,
    hideTypingSignal,
} from '../../actions/chatActions'
import Loader from '../Loader'
import ChatHead from './ChatHead'
import Message from './Message'
// import socket from '../socketio'
import {
    ADD_MESSAGE_SUCCESS,
    CURRENT_CHAT_RESET,
    LIST_MESSAGES_SUCCESS,
} from '../../constants/chatConstants'
// import ScrollToBottom from "react-scroll-to-bottom"
import Picker from 'emoji-picker-react'
import Typing from '../../assets/images/typing.svg'
import { setMemberships } from 'pubnub-redux'

const endpoint = 'localhost:5000'
const Chat = ({
    chatId,
    otherUserInfo,
    chatProxy,
    smallScreen,
    activeSection,
    otherParticipant,
    onClick,
}) => {
    const dispatch = useDispatch()

    const latestMessage = useRef(null)
    const chatContainer = useRef(null)
    const [typedMessage, setTypedMessage] = useState('')
    const [currentMessages, setCurrentMessages] = useState([])
    const [date, setDate] = useState(null)
    const [showDate, setShowDate] = useState(null)
    const [tempMessages, setTempMessages] = useState([])
    const [thisChatProxy, setThisChatProxy] = useState(chatProxy)
    const [boundChats, setBoundChats] = useState(new Set())
    const [boundMessages, setBoundMessages] = useState({})
    const [newMsg, setNewMsg] = useState(false)
    const [showTyping, setShowTyping] = useState(false)
    const [timeoutCache, setTimeoutCache] = useState(0)

    const [chosenEmoji, setChosenEmoji] = useState(null)
    const [showEmojis, setShowEmojis] = useState(false)
    const location = useLocation()
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const currentChatObject = useSelector((state) => state.currentChat)
    const { chat: currentChat } = currentChatObject
    const currentChatMessages = useSelector((state) => state.messages)
    const { byId: messages } = currentChatMessages
    const currentTypingSignals = useSelector((state) => state.signals)
    const { byId: typingIndicator } = currentTypingSignals
    const typingContainer = <img className='mr-2' src={Typing} alt='typing' />
    const getCurrentConversationMessages = () => {
        return messages[currentChat.id]
            ? Object.values(messages[currentChat.id])
                  .filter((message) => message.channel === currentChat.id)
                  .map((message) => {
                      // if the user is unknown queue up a request for the missing data
                      return {
                          ...message,
                          timetoken: message.timetoken,
                          sender: currentChat.otherMember
                              ? currentChat.otherMember
                              : {},
                      }
                  })
                  .sort((messageA, messageB) => {
                      if (messageA.timetoken === messageB.timetoken) {
                          return 0
                      } else if (messageA.timetoken > messageB.timetoken) {
                          return 1
                      } else {
                          return -1
                      }
                  })
            : []
    }
    const scrollDown = () => {
        if (latestMessage.current) {
            latestMessage.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    const el = chatContainer.current

    const toggleEmojis = () => {
        setShowEmojis(!showEmojis)
        let picker = document.getElementsByClassName('emoji-picker-react')[0]
        if (picker) {
            picker.focus()
        }
    }
    const resize = (val) => {
        let field = document.getElementById(`new-message`)
        if (val === '') {
            field.style.height = '2.5rem'
        } else {
            field.style.height = 'auto'
            field.style.height = field.scrollHeight + 'px'
        }
    }
    useEffect(() => {
        let footer = document.getElementById('small-footer')
        let nav = document.getElementById('navbar')
        if (smallScreen) {
            if (!footer.classList.contains('hidden')) {
                footer.classList.add('hidden')
            }
            if (!nav.classList.contains('hidden')) {
                nav.classList.add('hidden')
            }
        }

        return () => {
            if (footer.classList.contains('hidden')) {
                footer.classList.remove('hidden')
            }
            if (nav.classList.contains('hidden')) {
                nav.classList.remove('hidden')
            }
            dispatch({ type: CURRENT_CHAT_RESET })
        }
    }, [])
    useEffect(() => {
        console.log('in here')
        if (currentChat.id) {
            let fetchedMessages = getCurrentConversationMessages()
            setCurrentMessages(fetchedMessages)
        }
        if (typedMessage !== '') setTypedMessage('')
    }, [currentChat])

    const hasReachedBottom = el
        ? el.scrollHeight - el.clientHeight === el.scrollTop
        : false
    useEffect(() => {
        scrollDown()
    }, [hasReachedBottom, currentMessages.length])
    useEffect(() => {
        console.log('in here bro')
        if (messages[currentChat.id] && messages[currentChat.id].length > 0) {
            let newMessage =
                messages[currentChat.id][messages[currentChat.id].length - 1]
            console.log('in here too bro')
            console.log(newMessage)
            if (
                messages[currentChat.id].length > currentMessages.length &&
                currentMessages.length > 0
            ) {
                setCurrentMessages((prevState) => [
                    ...prevState,
                    {
                        ...newMessage,
                        timetoken: newMessage.timetoken,
                        sender: currentChat.otherMember
                            ? currentChat.otherMember
                            : {},
                    },
                ])
            } else if (
                messages[currentChat.id].length === 1 &&
                currentMessages.length === 0
            ) {
                setCurrentMessages([
                    {
                        ...newMessage,
                        timetoken: newMessage.timetoken,
                        sender: currentChat.otherMember
                            ? currentChat.otherMember
                            : {},
                    },
                ])
            }
        }
    }, [messages[currentChat.id].length])

    useEffect(() => {
        if (
            typingIndicator[currentChat.id] &&
            typingIndicator[currentChat.id][
                typingIndicator[currentChat.id].length - 1
            ] &&
            typingIndicator[currentChat.id][
                typingIndicator[currentChat.id].length - 1
            ].message.by !== userInfo._id
        ) {
            if (
                typingIndicator[currentChat.id][
                    typingIndicator[currentChat.id].length - 1
                ].message.type === 'showTyping'
            ) {
                setShowTyping(true)
            } else {
                setShowTyping(false)
            }
        }
    }, [typingIndicator[currentChat.id]?.length])
    let prevDate = null
    let prevMessage = null
    let sentPrev = false
    const publishMessage = (e) => {
        e.preventDefault()
        let newMessage = {
            content: {
                type: 'text',
                body: typedMessage,
            },
            sender: userInfo._id,
        }
        if (typedMessage) dispatch(sendMessage(newMessage))
        if (showTyping) dispatch(hideTypingSignal())
        setTypedMessage('')
    }

    const handleOnChange = (e) => {
        setTypedMessage(e.target.value)
        resize(e.target.value)
        clearTimeout(timeoutCache)

        dispatch(sendTypingSignal())

        if (!e.target.value) {
            dispatch(hideTypingSignal())
        }
        let newTimeoutCache = setTimeout(() => {
            dispatch(hideTypingSignal())
        }, 2500)

        setTimeoutCache(newTimeoutCache)
    }
    return (
        <div
            className={
                'h-full w-full bg-gray-200 dark:bg-gray-700 rounded-none overflow-none flex flex-col md:rounded-tl-xl' +
                (smallScreen && activeSection === 'chat'
                    ? ' fixed top-0 bottom-0  z-50'
                    : '')
            }
        >
            <div className='h-auto md:h-20 border-b-2 border-gray-400 border-opacity-40'>
                <ChatHead
                    pic_url={
                        currentChat.otherMember &&
                        currentChat.otherMember.imageUrl
                    }
                    chatId={currentChat.id}
                    name={
                        currentChat.otherMember && currentChat.otherMember.name
                    }
                    username={
                        currentChat.otherMember &&
                        currentChat.otherMember.username
                    }
                    typing={showTyping}
                    smallScreen={smallScreen}
                    backClick={onClick}
                />
            </div>

            <div
                className='max-h-full overflow-x-hidden h-full w-full overflow-y-auto no-scrollbar'
                ref={chatContainer}
            >
                <div className='align-middle inline-block w-full max-w-full overflow-x-hidden'>
                    <div className='overflow-hidden'>
                        <div className={'flex flex-col items-start pt-2 pb-4'}>
                            {userInfo &&
                                currentChat &&
                                currentChat.id &&
                                currentMessages.map((item, index) => {
                                    let showImage = true
                                    if (index === 0)
                                        prevMessage = item.message.sender
                                    else {
                                        if (
                                            item.message.sender === prevMessage
                                        ) {
                                            if (!sentPrev) {
                                                sentPrev = true
                                                showImage = false
                                            } else {
                                                showImage = false
                                            }
                                        } else {
                                            prevMessage = item.message.sender
                                        }
                                    }
                                    let regularTimeStamp =
                                        item.timetoken.substr(0, 13)
                                    // console.log(regularTimeStamp)
                                    let messageDate = new Date(
                                        Number(regularTimeStamp)
                                    ).toLocaleString(
                                        window.navigator.language,
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }
                                    )
                                    let messageTimeStamp = new Date(
                                        Number(regularTimeStamp)
                                    ).toLocaleString(
                                        window.navigator.language,
                                        {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false,
                                        }
                                    )
                                    // console.log(messageTimeStamp)
                                    let today = new Date().toLocaleString(
                                        window.navigator.language,
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }
                                    )
                                    if (messageDate === today) {
                                        messageDate = 'Today'
                                    }
                                    let showDay = false
                                    let day = null
                                    if (!prevDate) {
                                        prevDate = messageDate
                                        day = messageDate
                                        showDay = true
                                    } else {
                                        if (messageDate !== prevDate) {
                                            prevDate = messageDate
                                            day = messageDate
                                            showDay = true
                                        }
                                    }

                                    if (item.message.sender === userInfo._id) {
                                        return (
                                            <Message
                                                imageUrl={userInfo.imageUrl}
                                                showDay={showDay}
                                                day={messageDate}
                                                timestamp={messageTimeStamp}
                                                sent={true}
                                                body={item.message.content.body}
                                                showImage={showImage}
                                                smallScreen={smallScreen}
                                            />
                                        )
                                    } else {
                                        return (
                                            <Message
                                                imageUrl={item.sender.imageUrl}
                                                showDay={showDay}
                                                timestamp={messageTimeStamp}
                                                day={day}
                                                sent={false}
                                                body={item.message.content.body}
                                                showImage={showImage}
                                                smallScreen={smallScreen}
                                            />
                                        )
                                    }
                                })}
                            {showTyping && (
                                <Message
                                    imageUrl={currentChat.otherMember.imageUrl}
                                    body={typingContainer}
                                    showImage={
                                        currentChat.otherMember._id ===
                                        prevMessage
                                            ? false
                                            : true
                                    }
                                    typing={showTyping}
                                    scrollDown={scrollDown}
                                    smallScreen={smallScreen}
                                />
                            )}
                            <div ref={latestMessage} className={'bottom-0'} />
                        </div>
                    </div>
                </div>
            </div>

            <form
                className={
                    'w-full md:w-auto mx-0 md:mx-4 flex items-center bg-gray-300 dark:bg-gray-600 md:rounded-lg py-2 px-2 z-10 md:mb-3'
                }
                onSubmit={publishMessage}
            >
                {/* <i class="fal fa-paperclip"></i> */}
                <div className='w-full flex flex-col ml-3 overflow-y-auto max-h-40 overflow-x-hidden no-scrollbar '>
                    <div className='w-full h-full flex items-center justify-center no-scrollbar'>
                        <textarea
                            type='text'
                            placeholder='Type your message'
                            id='new-message'
                            className='w-full text-sm md:text-base bg-transparent border-0 outline-none placeholder-gray-400 px-1 md:px-3 dark:text-white resize-none h-10 min-h-10 pt-2 overflow-y-hidden'
                            value={typedMessage}
                            // onFocus={() => {
                            //     if (showEmojis) toggleEmojis()
                            // }}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>

                {/* <i className='fas fa-paperclip md:text-2xl text-gray-300 mx-4 cursor-pointer hover:text-blue-400 dark:hover:text-blue-400'></i>
                <i
                    onClick={toggleEmojis}
                    className='far fa-laugh md:text-2xl dark:text-gray-300 cursor-pointer hover:text-blue-400 dark:hover:text-blue-400'
                ></i> */}
                {/* {showEmojis && (
                    <div className='absolute mb-96 right-5 md:right-12'>
                        <Picker />
                    </div>
                )} */}
                <button
                    type='submit'
                    className='focus:outline-none mx-2 flex text-blue-500 dark:text-blue-500 items-center justify-center hover:text-blue-400 cursor-pointer'
                >
                    <i className='fas fa-paper-plane text-sm md:text-base'></i>
                </button>
            </form>
        </div>
    )
}

export default Chat
