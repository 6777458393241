import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { focusOnChat } from '../../actions/chatActions'
import { LATEST_MESSAGES_SUCCESS } from '../../constants/chatConstants'

const ContactItem = ({
    pic_url,
    name,
    last_message,
    recent_time,
    onClick,
    chat,
    _id,
    group,
}) => {
    moment.locale('en', {
        relativeTime: {
            future: 'now %s',
            past: '%s ago',
            s: 'now',
            ss: '%ss',
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1M',
            MM: '%dM',
            y: '1Y',
            yy: '%dY',
        },
    })
    const dispatch = useDispatch()
    const [online, setOnline] = useState(false)
    const [latestMessage, setLatestMessage] = useState('')
    const [latestTime, setLatestTime] = useState('')
    const [showTyping, setShowTyping] = useState(false)
    const [showUnread, setShowUnread] = useState(false)
    const [timeoutCache, setTimeoutCache] = useState(0)
    const currentChatObject = useSelector((state) => state.currentChat)
    const { chat: currentChat } = currentChatObject
    const latestMessagesState = useSelector((state) => state.latestMessages)
    const { latestMessages } = latestMessagesState
    const messages = useSelector((state) => state.messages)
    const { byId } = messages
    const joinedChats = useSelector((state) => state.joinedChats)
    const { byId: userChats } = joinedChats
    const memberPresence = useSelector((state) => state.memberPresence)
    const { byId: presenceById, totalOccupancy } = memberPresence
    const currentTypingSignals = useSelector((state) => state.signals)
    const { byId: typingIndicator } = currentTypingSignals
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const openChat = () => {
        dispatch(focusOnChat(chat))
        onClick()
    }
    useEffect(() => {
        if (
            latestMessages &&
            latestMessages.channels &&
            latestMessages.channels[chat.id]
        ) {
            setLatestMessage(
                latestMessages.channels[chat.id][0].message.content.body
            )
            console.log(latestMessages.channels[chat.id][0].timetoken / 1e4)
            let str = latestMessages.channels[chat.id][0]
                ? moment(
                      new Date(
                          latestMessages.channels[chat.id][0].timetoken / 1e4
                      )
                  )
                      .fromNow()
                      .split(' ')[0]
                : ''
            setLatestTime(str)
        }
    }, [latestMessages?.channels[chat.id]])

    useEffect(() => {}, [userChats[userInfo._id]])

    useEffect(() => {
        if (
            byId[chat.id] &&
            byId[chat.id].length > 0 &&
            latestMessages.channels &&
            latestMessages.channels[chat.id] &&
            latestMessages &&
            byId[chat.id][byId[chat.id].length - 1].timetoken !==
                latestMessages.channels[chat.id][0].timetoken &&
            byId[chat.id][byId[chat.id].length - 1].timetoken >=
                latestMessages.channels[chat.id][0].timetoken
        ) {
            let newchannels = {
                ...latestMessages.channels,
                [chat.id]: [byId[chat.id][byId[chat.id].length - 1]],
            }
            dispatch({
                type: LATEST_MESSAGES_SUCCESS,
                payload: { channels: newchannels },
            })
        }
    }, [byId[chat.id]])
    useEffect(() => {
        if (
            byId[chat.id] &&
            byId[chat.id].length > 0 &&
            !latestMessages.channels[chat.id]
        ) {
            let newMessage = byId[chat.id][byId[chat.id].length - 1]
            let newchannels = {
                ...latestMessages.channels,
                [chat.id]: [newMessage],
            }
            dispatch({
                type: LATEST_MESSAGES_SUCCESS,
                payload: { channels: newchannels },
            })
        }
    }, [byId[chat.id]?.length])

    useEffect(() => {
        if (presenceById[chat.id]) {
            if (presenceById[chat.id].occupancy > 1) setOnline(true)
            else if (presenceById[chat.id].occupancy === 1) setOnline(false)
        }
    }, [totalOccupancy])
    useEffect(() => {
        if (
            typingIndicator[chat.id] &&
            typingIndicator[chat.id][typingIndicator[chat.id].length - 1] &&
            typingIndicator[chat.id][typingIndicator[chat.id].length - 1]
                .message.by !== userInfo._id
        ) {
            if (
                typingIndicator[chat.id][typingIndicator[chat.id].length - 1]
                    .message.type === 'showTyping'
            ) {
                setShowTyping(true)
            } else {
                setShowTyping(false)
            }
        }
    }, [typingIndicator[chat.id]?.length])
    return (
        <motion.div
            className={
                'w-full flex flex-col items-center justify-center h-20 cursor-pointer z-10 rounded-l-3xl' +
                (currentChat && currentChat.id === chat.id
                    ? ' bg-gray-200 dark:bg-gray-700'
                    : '')
            }
            whileHover={{ scale: 0.97 }}
            whileTap={{ scale: 0.9 }}
            onClick={openChat}
        >
            <div className='flex py-2 pl-4 items-center w-full'>
                <div
                    className={`flex flex-row-reverse items-end min-w-12 min-h-12`}
                >
                    {group ? (
                        <div
                            className={
                                'flex items-center justify-center w-12 h-12'
                            }
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-6 w-6 dark:text-gray-500'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={2}
                                    d='M7 20l4-16m2 16l4-16M6 9h14M4 15h14'
                                />
                            </svg>
                        </div>
                    ) : (
                        <img
                            src={pic_url}
                            className={
                                'object-cover w-12 min-w-12 h-12 min-h-12 rounded-full mr-2' +
                                (showUnread ? ' border-2 border-blue-500' : '')
                            }
                        />
                    )}
                    {online && !group && (
                        <div className='bg-green-500 border border-black absolute mr-2.5 mb-0.5 h-3 w-3 rounded-full overflow-hidden'></div>
                    )}
                </div>
                <div className='w-full h-full flex items-center justify-between py-2 overflow-hidden'>
                    <div className='mr-2 w-3/4 max-w-3/4 h-full flex flex-col pl-4 justify-center overflow-hidden'>
                        <p className='text-gray-800 font-semibold dark:text-gray-200 antialiased '>
                            {name}
                        </p>
                        <p
                            className={
                                'text-sm max-w-full max-h-6  overflow-ellipsis overflow-hidden' +
                                (showUnread
                                    ? ' text-black dark:text-white font-black'
                                    : '  text-gray-600 dark:text-gray-300')
                            }
                        >
                            {showTyping ? `typing...` : latestMessage}
                        </p>
                    </div>
                    {!showTyping && (
                        <div className='w-1/4 flex self-end justify-end pr-4'>
                            <p className='text-sm text-gray-600 dark:text-gray-300'>
                                {latestTime}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    )
}

export default ContactItem
