import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {} from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import ContactItem from '../components/chats/ContactItem'
import ChatHead from '../components/chats/ChatHead'
import Chat from '../components/chats/Chat'
import getClient from '../config/twilio'
import ChatsList from '../components/chats/ChatsList'
import {
    LIST_CHATS_FAIL,
    LIST_CHATS_REQUEST,
    LIST_CHATS_SUCCESS,
} from '../constants/chatConstants'
import { getAugmentedChats } from '../actions/chatActions'
import Meta from '../components/common/Meta'

const ChatScreen = ({ history }) => {
    const dispatch = useDispatch()
    const [smallScreen, setSmallScreen] = useState(false)
    //const [chats, setChats] = useState([])
    const [activeSection, setActiveSection] = useState('list')
    const [showChat, setShowChat] = useState(false)
    const getRegSize = () => {
        if (window.innerWidth < 760) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false)
        }
    }
    window.onresize = getRegSize
    const [chatId, setChatId] = useState(null)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const joinedChats = useSelector((state) => state.joinedChats)
    const { byId: userChats } = joinedChats
    const augmentedChats = useSelector((state) => state.augmentedChats)
    const { chats } = augmentedChats
    const currentChatObject = useSelector((state) => state.currentChat)
    const { chat: currentChat } = currentChatObject

    useEffect(() => {
        getRegSize()
        if (!userInfo) {
            // if (conversationsClient) conversationsClient.shutdown()
            history.push('/login')
        } else {
            // dispatch(initiateChats())
            if (!chats || chats.length == 0)
                dispatch(getAugmentedChats(userInfo))
        }
    }, [userInfo, userChats])

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex pt-14 md:pt-20 max-h-screen'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Chats`} />

            <div
                className={
                    'flex flex-1 w-full' +
                    (smallScreen
                        ? activeSection === 'list'
                            ? ' px-4'
                            : ' px-0'
                        : ' ml-12')
                }
            >
                {smallScreen ? (
                    activeSection === 'list' ? (
                        <div className='h-full min-w-full w-full dark:border-gray-700'>
                            <ChatsList
                                chats={chats}
                                onChatClick={setActiveSection}
                                smallScreen={smallScreen}
                                activeSection={activeSection}
                            />
                        </div>
                    ) : null
                ) : (
                    <div className='h-full w-full md:w-1/4 dark:border-gray-700'>
                        <ChatsList
                            chats={chats}
                            onChatClick={setActiveSection}
                            smallScreen={smallScreen}
                            activeSection={activeSection}
                        />
                    </div>
                )}
                <div className='w-full md:w-3/4'>
                    {!smallScreen ? (
                        currentChat && currentChat.id ? (
                            <Chat
                                smallScreen={smallScreen}
                                activeSection={activeSection}
                                onClick={setActiveSection}
                            />
                        ) : (
                            <div
                                className={
                                    'h-full w-full lg:w-3/4 bg-transparent justify-start pt-44 items-center flex flex-col'
                                }
                            >
                                <p className='text-6xl dark:text-white font-black w-1/2 flex text-center'>
                                    Click on a chat to see your messages.
                                </p>
                            </div>
                        )
                    ) : activeSection === 'chat' &&
                      currentChat &&
                      currentChat.id ? (
                        <Chat
                            smallScreen={smallScreen}
                            activeSection={activeSection}
                            onClick={setActiveSection}
                        />
                    ) : null}
                </div>
            </div>
        </motion.div>
    )
}

export default ChatScreen
