import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { motion } from 'framer-motion'
import SmallLoader from '../components/SmallLoader'
import Meta from '../components/common/Meta'

const ContactScreen = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [hideButton, setHideButton] = useState(false)

    const resetForm = () => {
        setShowErrors(false)
        setMessage('')
        setEmail('')
        setName('')
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!name || !email || !message) {
            setShowErrors(true)
        } else {
            setHideButton(true)
            const { data } = await axios.post('/api/contact', {
                name,
                email,
                message,
            })
            console.log(data)
            if (data.status === 'success') {
                resetForm()
                setShowSuccess(true)
            }
            setHideButton(false)
        }
    }
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Contact Us`} />

            <section className='py-20'>
                <div className='container px-4 mx-auto mb-12 md:mb-6'>
                    <div className='flex flex-wrap items-center -m-4'>
                        <div className='w-full md:w-1/2 p-4 text-center'>
                            <svg
                                className='mb-8 mx-auto'
                                width={48}
                                height={48}
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <rect
                                    x='0.5'
                                    y='0.5'
                                    width={47}
                                    height={47}
                                    rx='23.5'
                                    fill='white'
                                    stroke='#DEE4E9'
                                />
                                <path
                                    d='M29.5 22C29.5 26.5 24 31.5 24 31.5C24 31.5 18.5 26.5 18.5 22C18.5 20.5413 19.0795 19.1424 20.1109 18.1109C21.1424 17.0795 22.5413 16.5 24 16.5C25.4587 16.5 26.8576 17.0795 27.8891 18.1109C28.9205 19.1424 29.5 20.5413 29.5 22V22Z'
                                    stroke='#838EA4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M24 24.5C25.3807 24.5 26.5 23.3807 26.5 22C26.5 20.6193 25.3807 19.5 24 19.5C22.6193 19.5 21.5 20.6193 21.5 22C21.5 23.3807 22.6193 24.5 24 24.5Z'
                                    stroke='#838EA4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                            <p className='text-gray-500 dark:text-white'>
                                Peterborough, Ontario
                            </p>
                            <p className='text-gray-500 dark:text-white'>
                                Canada
                            </p>
                        </div>
                        <div className='w-full md:w-1/2 px-4 text-center p-4'>
                            <svg
                                className='mb-8 mx-auto'
                                width={48}
                                height={48}
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <rect
                                    x='0.5'
                                    y='0.5'
                                    width={47}
                                    height={47}
                                    rx='23.5'
                                    fill='white'
                                    stroke='#DEE4E9'
                                />
                                <path
                                    d='M30.5 30.5H17.5C16.948 30.5 16.5 30.052 16.5 29.5V18.5C16.5 17.948 16.948 17.5 17.5 17.5H30.5C31.052 17.5 31.5 17.948 31.5 18.5V29.5C31.5 30.052 31.052 30.5 30.5 30.5Z'
                                    stroke='#838EA4'
                                    strokeMiterlimit={10}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M18.5 20.5L24 25L29.5 20.5'
                                    stroke='#838EA4'
                                    strokeMiterlimit={10}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                            <p className='text-gray-500 dark:text-white'>
                                jetlyst.noregrets@gmail.com
                            </p>
                        </div>
                        {/* <div className="w-full md:w-1/3 px-4 text-center p-4">
							<svg className="mb-8 mx-auto" width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect x="0.5" y="0.5" width={47} height={47} rx="23.5" fill="white" stroke="#DEE4E9" />
								<path d="M26.514 25.764L25.292 27.292C23.3984 26.1794 21.8205 24.6015 20.708 22.708L22.236 21.486C22.4151 21.3426 22.5417 21.144 22.596 20.9211C22.6502 20.6982 22.6292 20.4636 22.536 20.254L21.143 17.117C21.0431 16.8922 20.8666 16.7101 20.645 16.6034C20.4234 16.4966 20.1711 16.472 19.933 16.534L17.281 17.221C17.0336 17.2858 16.8186 17.439 16.6765 17.6517C16.5345 17.8643 16.4752 18.1216 16.51 18.375C16.9753 21.6887 18.5068 24.7609 20.8729 27.127C23.2391 29.4932 26.3112 31.0247 29.625 31.49C29.8782 31.5249 30.1355 31.4657 30.348 31.3236C30.5605 31.1815 30.7135 30.9664 30.778 30.719L31.466 28.068C31.5279 27.8299 31.5033 27.5776 31.3966 27.356C31.2898 27.1343 31.1078 26.9579 30.883 26.858L27.746 25.465C27.5364 25.372 27.302 25.351 27.0792 25.405C26.8564 25.4591 26.6577 25.5853 26.514 25.764V25.764Z" stroke="#838EA4" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<p className="text-gray-500 dark:text-white">+7-843-672-431</p>
						</div> */}
                    </div>
                </div>
                <div className='container px-4 mx-auto'>
                    <div className='max-w-3xl mx-auto p-6 lg:py-6 lg:px-20 bg-transparent rounded-xl text-center'>
                        <h2 className='mt-8 mb-14 text-4xl font-semibold font-heading dark:text-white'>
                            Feel like reaching out? Send us a message and we
                            will respond : )
                        </h2>
                        {showSuccess ? (
                            <div className='w-full rounded-lg font-semibold border border-gray-800 dark:border-gray-100 flex flex-col items-center justify-center text-green-500 dark:text-green-300 md:text-2xl px-4 md:px-10 py-5 md:py-16'>
                                <p className='text-center'>
                                    We have received your message.
                                </p>
                                <p className='text-center'>
                                    We will get back to you within 5 days!
                                </p>
                                {/* <p className='text-center'>
                                    Check your email for confirmaton that your
                                    message has been received.
                                </p>
                                <p className='text-center text-lg'>
                                    (The email might be in your spam)
                                </p> */}
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className='relative flex flex-wrap mb-6'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm rounded dark:bg-gray-50' +
                                            (!name && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='text'
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        placeholder='e.g Adrian'
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-2 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                        Name
                                    </span>
                                </div>
                                <div className='relative flex flex-wrap mb-6'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm rounded dark:bg-gray-50' +
                                            (!email && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='email'
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder='e.g hello@example.com'
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-2 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                        Your email address
                                    </span>
                                </div>
                                <div className='relative flex flex-wrap mb-6'>
                                    <textarea
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm rounded dark:bg-gray-50 resize-none' +
                                            (!message && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='text'
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        cols={30}
                                        rows={10}
                                        placeholder='Message...'
                                        defaultValue={''}
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-2 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                        Your message
                                    </span>
                                </div>
                                {hideButton ? (
                                    <div className='h-13'>
                                        <SmallLoader />
                                    </div>
                                ) : (
                                    <button
                                        type='submit'
                                        className='w-full inline-block px-6 py-4 text-sm text-white bg-blue-600 hover:bg-blue-500 rounded transition duration-200'
                                    >
                                        Send message
                                    </button>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default ContactScreen
