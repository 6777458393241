import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createNewPrivateChat } from '../../actions/chatActions'

const Notification = ({ notification }) => {
    const dispatch = useDispatch()
    let history = useHistory()
    const clicked = () => {
        dispatch(
            createNewPrivateChat(notification.currentUserId, notification.user)
        )
        history.push('/chats')
    }
    return (
        <div onClick={clicked} className='flex items-center justify-center'>
            <div
                className={`flex flex-row-reverse items-end min-w-12 min-h-12`}
            >
                <img
                    src={notification.user.imageUrl}
                    className={
                        'object-cover w-12 min-w-12 h-12 min-h-12 rounded-full mr-2'
                    }
                />
            </div>
            <div className='w-full overflow-hidden'>
                <div className='overflow-hidden'>
                    <p className='text-sm text-gray-600 dark:text-gray-300 max-w-full max-h-6 font-bold overflow-ellipsis overflow-hidden'>
                        {notification.message}
                    </p>
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default Notification
