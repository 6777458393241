import {
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_RESET,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_PROFILE_FAIL,
	USER_UPDATE_PROFILE_RESET,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_RESET,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_RESET,
	USER_FOLLOW_REQUEST,
	USER_FOLLOW_SUCCESS,
	USER_FOLLOW_FAIL,
	USER_FOLLOW_RESET,
	USER_UNFOLLOW_REQUEST,
	USER_UNFOLLOW_SUCCESS,
	USER_UNFOLLOW_FAIL,
	USER_UNFOLLOW_RESET,
	USER_LIST_FOLLOWERS_REQUEST,
	USER_LIST_FOLLOWERS_SUCCESS,
	USER_LIST_FOLLOWERS_FAIL,
	USER_LIST_FOLLOWERS_RESET,
	USER_LIST_FOLLOWING_REQUEST,
	USER_LIST_FOLLOWING_SUCCESS,
	USER_LIST_FOLLOWING_FAIL,
	USER_LIST_FOLLOWING_RESET,
	USER_LIST_FOLLOWERS_BY_ID_REQUEST,
	USER_LIST_FOLLOWERS_BY_ID_SUCCESS,
	USER_LIST_FOLLOWERS_BY_ID_FAIL,
	USER_LIST_FOLLOWERS_BY_ID_RESET,
	USER_LIST_FOLLOWING_BY_ID_REQUEST,
	USER_LIST_FOLLOWING_BY_ID_SUCCESS,
	USER_LIST_FOLLOWING_BY_ID_FAIL,
	USER_LIST_FOLLOWING_BY_ID_RESET,
	USER_BASIC_DETAILS_REQUEST,
	USER_BASIC_DETAILS_SUCCESS,
	USER_BASIC_DETAILS_FAIL,
	USER_BASIC_DETAILS_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true }
		case USER_LOGIN_SUCCESS:
			return { loading: false, userInfo: action.payload }
		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload }
		case USER_LOGOUT:
			return {}
		default:
			return state
	}
}

export const userRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTER_REQUEST:
			return { loading: true }
		case USER_REGISTER_SUCCESS:
			return { loading: false, userInfo: action.payload }
		case USER_REGISTER_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const userDetailsReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { ...state, loading: true }
		case USER_DETAILS_SUCCESS:
			return { loading: false, user: action.payload }
		case USER_DETAILS_FAIL:
			return { loading: false, error: action.payload }
		case USER_DETAILS_RESET:
			return { user: {} }
		default:
			return state
	}
}

// export const userBasicDetailsReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case USER_BASIC_DETAILS_REQUEST:
// 			return { loading: true }
// 		case USER_BASIC_DETAILS_SUCCESS:
// 			return { loading: false, basicDetails: action.payload }
// 		case USER_BASIC_DETAILS_FAIL:
// 			return { loading: false, error: action.payload }
// 		case USER_BASIC_DETAILS_RESET:
// 			return {}
// 		default:
// 			return state
// 	}
// }

export const userUpdateProfileReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_UPDATE_PROFILE_REQUEST:
			return { loading: true }
		case USER_UPDATE_PROFILE_SUCCESS:
			return { loading: false, success: true, userInfo: action.payload }
		case USER_UPDATE_PROFILE_FAIL:
			return { loading: false, error: action.payload }
		case USER_UPDATE_PROFILE_RESET:
			return {}
		default:
			return state
	}
}

export const userListReducer = (state = { users: [] }, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true }
		case USER_LIST_SUCCESS:
			return { loading: false, users: action.payload }
		case USER_LIST_FAIL:
			return { loading: false, error: action.payload }
		case USER_LIST_RESET:
			return { users: [] }
		default:
			return state
	}
}

export const userDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_DELETE_REQUEST:
			return { loading: true }
		case USER_DELETE_SUCCESS:
			return { loading: false, success: true }
		case USER_DELETE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const userUpdateReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_REQUEST:
			return { loading: true }
		case USER_UPDATE_SUCCESS:
			return { loading: false, success: true }
		case USER_UPDATE_FAIL:
			return { loading: false, error: action.payload }
		case USER_UPDATE_RESET:
			return { user: {} }
		default:
			return state
	}
}

export const userFollowReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_FOLLOW_REQUEST:
			return { loading: true }
		case USER_FOLLOW_SUCCESS:
			return { loading: false, success: true }
		case USER_FOLLOW_FAIL:
			return { loading: false, error: action.payload }
		case USER_FOLLOW_RESET:
			return {}
		default:
			return state
	}
}

export const userUnfollowReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_UNFOLLOW_REQUEST:
			return { loading: true }
		case USER_UNFOLLOW_SUCCESS:
			return { loading: false, success: true }
		case USER_UNFOLLOW_FAIL:
			return { loading: false, error: action.payload }
		case USER_UNFOLLOW_RESET:
			return {}
		default:
			return state
	}
}

export const listFollowersReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_FOLLOWERS_REQUEST:
			return { loading: true }
		case USER_LIST_FOLLOWERS_SUCCESS:
			return { loading: false, followers: action.payload }
		case USER_LIST_FOLLOWERS_FAIL:
			return { loading: false, error: action.payload }
		case USER_LIST_FOLLOWERS_RESET:
			return {}
		default:
			return state
	}
}

export const listFollowersByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_FOLLOWERS_BY_ID_REQUEST:
			return { loading: true }
		case USER_LIST_FOLLOWERS_BY_ID_SUCCESS:
			return { loading: false, otherFollowers: action.payload }
		case USER_LIST_FOLLOWERS_BY_ID_FAIL:
			return { loading: false, error: action.payload }
		case USER_LIST_FOLLOWERS_BY_ID_RESET:
			return {}
		default:
			return state
	}
}

export const listFollowingReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_FOLLOWING_REQUEST:
			return { loading: true }
		case USER_LIST_FOLLOWING_SUCCESS:
			return { loading: false, following: action.payload }
		case USER_LIST_FOLLOWING_FAIL:
			return { loading: false, error: action.payload }
		case USER_LIST_FOLLOWING_RESET:
			return {}
		default:
			return state
	}
}

export const listFollowingByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_FOLLOWING_BY_ID_REQUEST:
			return { loading: true }
		case USER_LIST_FOLLOWING_BY_ID_SUCCESS:
			return { loading: false, otherFollowing: action.payload }
		case USER_LIST_FOLLOWING_BY_ID_FAIL:
			return { loading: false, error: action.payload }
		case USER_LIST_FOLLOWING_BY_ID_RESET:
			return {}
		default:
			return state
	}
}
