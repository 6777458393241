import {
    FEED_REQUEST,
    FEED_SUCCESS,
    FEED_FAIL,
    FEED_RESET,
    MY_POSTS_REQUEST,
    MY_POSTS_SUCCESS,
    MY_POSTS_FAIL,
    MY_POSTS_RESET,
    OTHER_POSTS_REQUEST,
    OTHER_POSTS_SUCCESS,
    OTHER_POSTS_FAIL,
    OTHER_POSTS_RESET,
    SAVED_POSTS_REQUEST,
    SAVED_POSTS_SUCCESS,
    SAVED_POSTS_FAIL,
    SAVED_POSTS_RESET,
    ADD_POST_REQUEST,
    ADD_POST_SUCCESS,
    ADD_POST_FAIL,
    UPDATE_POST_REQUEST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAIL,
    DELETE_POST_REQUEST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL,
    ADD_POST_RESET,
} from '../constants/feedConstants'

export const addPostReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_POST_REQUEST:
            return { loading: true }
        case ADD_POST_SUCCESS:
            return { loading: false, newPost: action.payload, success: true }
        case ADD_POST_FAIL:
            return { loading: false, error: action.payload }
        case ADD_POST_RESET:
            return {}
        default:
            return state
    }
}

export const deletePostReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_POST_REQUEST:
            return { loading: true }
        case DELETE_POST_SUCCESS:
            return { loading: false, success: true }
        case DELETE_POST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const feedReducer = (state = { feed: [] }, action) => {
    switch (action.type) {
        case FEED_REQUEST:
            return { ...state, loading: true }
        case FEED_SUCCESS:
            if (action.payload.newPost) {
                return {
                    ...state,
                    loading: false,
                    feed: [...new Set([action.payload.newPost, ...state.feed])],
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    feed: action.payload.deletedPost
                        ? [
                              ...state.feed.filter(
                                  (post) =>
                                      post._id !== action.payload.deletedPost
                              ),
                          ]
                        : [
                              ...new Set([
                                  ...state.feed,
                                  ...action.payload.posts,
                              ]),
                          ],
                    page: action.payload.deletedPost
                        ? state.page
                        : action.payload.page,
                }
            }

        case FEED_FAIL:
            return { loading: false, error: action.payload }
        case FEED_RESET:
            return { feed: [] }
        default:
            return state
    }
}

export const myPostsReducer = (state = { myPosts: [] }, action) => {
    switch (action.type) {
        case MY_POSTS_REQUEST:
            return { ...state, loading: true }
        case MY_POSTS_SUCCESS:
            if (action.payload.newPost) {
                return {
                    ...state,
                    loading: false,
                    myPosts:
                        state.myPosts.length === 0 && !state.count
                            ? []
                            : [
                                  ...new Set([
                                      action.payload.newPost,
                                      ...state.myPosts,
                                  ]),
                              ],
                    count: state.count ? state.count + 1 : undefined,
                }
            }
            // else if (action.paylooad.deletedPost) {
            //     return {
            //         ...state,
            //         loading: false,
            //         myPosts: [
            //             ...state.myPosts.filter(
            //                 (post) => post._id !== action.payload.deletedPost
            //             ),
            //         ],
            //     }
            // }
            else {
                return {
                    ...state,
                    loading: false,
                    myPosts: action.payload.deletedPost
                        ? [
                              ...state.myPosts.filter(
                                  (post) =>
                                      post._id !== action.payload.deletedPost
                              ),
                          ]
                        : [...state.myPosts, ...action.payload.posts],
                    page: action.payload.deletedPost
                        ? state.page
                        : action.payload.page,
                    count: action.payload.deletedPost
                        ? state.count
                            ? state.count - 1
                            : undefined
                        : action.payload.count,
                }
            }

        case MY_POSTS_FAIL:
            return { loading: false, error: action.payload }
        case MY_POSTS_RESET:
            return { myPosts: [] }
        default:
            return state
    }
}

export const otherPostsReducer = (state = { otherPosts: [] }, action) => {
    switch (action.type) {
        case OTHER_POSTS_REQUEST:
            return { ...state, loading: true }
        case OTHER_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                otherPosts: [...state.otherPosts, ...action.payload.posts],
                page: action.payload.page,
                count: action.payload.count,
            }
        case OTHER_POSTS_FAIL:
            return { loading: false, error: action.payload }
        case OTHER_POSTS_RESET:
            return { otherPosts: [] }
        default:
            return state
    }
}

export const savedPostsReducer = (state = { savedPosts: [] }, action) => {
    switch (action.type) {
        case SAVED_POSTS_REQUEST:
            return { ...state, loading: true }
        case SAVED_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                savedPosts: action.payload.deletedPost
                    ? [
                          ...state.savedPosts.filter(
                              (post) => post._id !== action.payload.deletedPost
                          ),
                      ]
                    : [...state.savedPosts, ...action.payload.posts],
                page: action.payload.deletedPost
                    ? state.page
                    : action.payload.page,
                count: action.payload.deletedPost
                    ? state.count - 1
                    : action.payload.count,
            }
        case SAVED_POSTS_FAIL:
            return { loading: false, error: action.payload }
        case SAVED_POSTS_RESET:
            return { savedPosts: [] }
        default:
            return state
    }
}
