import axios from 'axios'
import {
    FEED_REQUEST,
    FEED_SUCCESS,
    FEED_FAIL,
    FEED_RESET,
    MY_POSTS_REQUEST,
    MY_POSTS_SUCCESS,
    MY_POSTS_FAIL,
    MY_POSTS_RESET,
    OTHER_POSTS_REQUEST,
    OTHER_POSTS_SUCCESS,
    OTHER_POSTS_FAIL,
    OTHER_POSTS_RESET,
    SAVED_POSTS_REQUEST,
    SAVED_POSTS_SUCCESS,
    SAVED_POSTS_FAIL,
    SAVED_POSTS_RESET,
    ADD_POST_REQUEST,
    ADD_POST_SUCCESS,
    ADD_POST_FAIL,
    UPDATE_POST_REQUEST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAIL,
    DELETE_POST_REQUEST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL,
} from '../constants/feedConstants'

export const getMyFeed =
    (pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: FEED_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/feed?pageNumber=${pageNumber}`,
                config
            )

            dispatch({
                type: FEED_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: FEED_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const getMyPosts =
    (pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: MY_POSTS_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/myposts?pageNumber=${pageNumber}`,
                config
            )

            dispatch({
                type: MY_POSTS_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: MY_POSTS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const getOtherPosts =
    (id, pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: OTHER_POSTS_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/user/${id}?pageNumber=${pageNumber}`,
                config
            )

            dispatch({
                type: OTHER_POSTS_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: OTHER_POSTS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const createPost = (detail) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_POST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/posts`, { detail }, config)

        dispatch({
            type: ADD_POST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ADD_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deletePost = (id) => async (dispatch, getState) => {
    try {
        // dispatch({
        //     type: DELETE_POST_REQUEST,
        // })

        const {
            userLogin: { userInfo },
            feed: { feed },
            savedPosts: { savedPosts },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(`/api/posts/${id}`, config)

        // let newMyPosts = [...myPosts.filter((post) => post._id !== id)]

        dispatch({
            type: MY_POSTS_SUCCESS,
            payload: { deletedPost: id },
        })

        if (feed.find((post) => post._id === id)) {
            dispatch({ type: FEED_SUCCESS, payload: { deletedPost: id } })
        }
        if (savedPosts.find((post) => post._id === id)) {
            dispatch({
                type: SAVED_POSTS_SUCCESS,
                payload: { deletedPost: id },
            })
        }
    } catch (error) {
        dispatch({
            type: DELETE_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getSavedPosts =
    (pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: SAVED_POSTS_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.get(
                `/api/posts/saved?pageNumber=${pageNumber}`,
                config
            )

            dispatch({
                type: SAVED_POSTS_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: SAVED_POSTS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const deleteComment = (commentId) => async (dispatch, getState) => {
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        },
    }
    try {
        const { data } = await axios.delete(
            `/api/posts/comments/${commentId}`,
            config
        )
        return
    } catch (error) {
        console.log(error)
    }
}
