export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST'
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS'
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL'

export const LIST_MESSAGES_REQUEST = 'LIST_MESSAGES_REQUEST'
export const LIST_MESSAGES_SUCCESS = 'LIST_MESSAGES_SUCCESS'
export const LIST_MESSAGES_FAIL = 'LIST_MESSAGES_FAIL'

export const LIST_CHATS_REQUEST = 'LIST_CHATS_REQUEST'
export const LIST_CHATS_SUCCESS = 'LIST_CHATS_SUCCESS'
export const LIST_CHATS_FAIL = 'LIST_CHATS_FAIL'
export const LIST_CHATS_REFRESH = 'LIST_CHATS_REFRESH'
export const LIST_CHATS_DELETE = 'LIST_CHATS_DELETE'
export const LIST_CHATS_DONE_REFRESH = 'LIST_CHATS_DONE_REFRESH'
export const LIST_CHATS_UPDATE = 'LIST_CHATS_UPDATE'
export const LIST_CHATS_RESET = 'LIST_CHATS_RESET'

export const LATEST_MESSAGES_REQUEST = 'LATEST_MESSAGES_REQUEST'
export const LATEST_MESSAGES_SUCCESS = 'LATEST_MESSAGES_SUCCESS'
export const LATEST_MESSAGES_FAIL = 'LATEST_MESSAGES_FAIL'
export const LATEST_MESSAGES_RESET = 'LATEST_MESSAGES_RESET'

export const CURRENT_CHAT_REQUEST = 'CURRENT_CHAT_REQUEST'
export const CURRENT_CHAT_SUCCESS = 'CURRENT_CHAT_SUCCESS'
export const CURRENT_CHAT_FAIL = 'CURRENT_CHAT_FAIL'
export const CURRENT_CHAT_RESET = 'CURRENT_CHAT_RESET'

export const CHAT_CLIENT_REQUEST = 'CHAT_CLIENT_REQUEST'
export const CHAT_CLIENT_SUCCESS = 'CHAT_CLIENT_SUCCESS'
export const CHAT_CLIENT_FAIL = 'CHAT_CLIENT_FAIL'
export const CHAT_CLIENT_RESET = 'CHAT_CLIENT_RESET'
