import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    getUserDetails,
    listFollowersById,
    listFollowingById,
    followUser,
    unfollowUser,
    listFollowing,
} from '../actions/userActions'
import { getOtherPosts } from '../actions/feedActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SmallUserBox from '../components/common/SmallUserBox'
import SmallLoader from '../components/SmallLoader'
import {
    USER_FOLLOW_RESET,
    USER_UNFOLLOW_RESET,
} from '../constants/userConstants'
import Post from '../components/feed/Post'
import { createNewPrivateChat } from '../actions/chatActions'
import Meta from '../components/common/Meta'
import { getOtherLists } from '../actions/listActions'
import OtherBucketLists from '../components/OtherBucketLists'
import { OTHER_POSTS_RESET } from '../constants/feedConstants'

const OtherUserProfile = ({ match, location, history }) => {
    const dispatch = useDispatch()
    const username = match.params.username
    const [offset, setOffset] = useState(0)

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const userFollow = useSelector((state) => state.userFollow)
    const { loading, success, error } = userFollow

    const userUnfollow = useSelector((state) => state.userUnfollow)
    const { success: successUnfollow, error: unfollowError } = userUnfollow

    const userDetails = useSelector((state) => state.userDetails)
    const { loading: loadingUser, user, error: errorUser } = userDetails

    const followers = useSelector((state) => state.otherListFollowers)
    const {
        loading: loadingFollowers,
        otherFollowers,
        error: errorFollowers,
    } = followers

    const other_following = useSelector((state) => state.otherListFollowing)
    const { otherFollowing } = other_following

    const followingList = useSelector((state) => state.listFollowing)
    const { following } = followingList

    const otherPeoplePosts = useSelector((state) => state.otherPosts)
    const {
        loading: otherPostsLoading,
        otherPosts,
        count,
        page,
        error: otherPotsError,
    } = otherPeoplePosts

    const otherBucketLists = useSelector((state) => state.otherBucketLists)
    const {
        loading: listsLoading,
        otherLists: bucketlists,
        error: listsError,
    } = otherBucketLists

    const [tab, setTab] = useState('posts')
    const [showSmallLoader, setShowSmallLoader] = useState(false)
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (username) {
                if (username === userInfo.username) {
                    history.push('/profile')
                } else {
                    if (!user || !user._id || user.username !== username) {
                        dispatch(getUserDetails(username))
                    } else if (user && user._id && user.username === username) {
                        if (!otherPosts || otherPosts.length === 0) {
                            dispatch(getOtherPosts(user._id))
                        } else {
                            if (
                                otherPosts[0] &&
                                otherPosts[0].by !== user._id
                            ) {
                                dispatch({
                                    type: OTHER_POSTS_RESET,
                                })
                                dispatch(getOtherPosts(user._id))
                            }
                        }
                        dispatch(listFollowersById(user._id))
                        dispatch(listFollowingById(user._id))
                        if (!bucketlists || bucketlists.length === 0) {
                            dispatch(getOtherLists(user._id))
                        }
                    }
                    dispatch(listFollowing())
                }
            }
        }
    }, [user, userInfo, match])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (username) {
                if (username === userInfo.username) {
                    history.push('/profile')
                } else if (user && user._id && (success || successUnfollow)) {
                    dispatch(listFollowersById(user._id))
                    dispatch(listFollowing())
                    dispatch({ type: USER_UNFOLLOW_RESET })
                    dispatch({ type: USER_FOLLOW_RESET })
                }
            }
        }
        console.log('i fired')
    }, [success, successUnfollow])

    useEffect(() => {
        if (page > 1) {
            window.scrollTo({
                top: offset,
            })
        }
    }, [page])

    const handleNext = () => {
        setOffset(window.pageYOffset)
        dispatch(getOtherPosts(user._id, page + 1))
    }

    const submitHandler = (id) => {
        if (otherFollowers) {
            if (otherFollowers.find((item) => item._id === userInfo._id)) {
                dispatch(unfollowUser(id))
            } else {
                dispatch(followUser(id))
            }
        }
    }

    const otherFollowHandler = (id, action) => {
        switch (action) {
            case 'follow':
                dispatch(followUser(id))
                break
            case 'unfollow':
                if (following && following.find((item) => item._id === id))
                    dispatch(unfollowUser(id))
                break
            default:
                break
        }
    }

    const messageHandler = () => {
        if (username && userInfo) {
            setShowSmallLoader(true)
            // const config = {
            //     headers: {
            //         Authorization: `Bearer ${userInfo.token}`,
            //     },
            // }
            // let { data } = await axios.post(
            //     '/api/twilio/create',
            //     {
            //         _otherId: user._id,
            //         uniqueName: `${userInfo._id}${user._id}`,
            //         uniqueName2: `${user._id}${userInfo._id}`,
            //     },
            //     config
            // )
            dispatch(
                createNewPrivateChat(userInfo._id, {
                    _id: user._id,
                    imageUrl: user.imageUrl,
                    name: user.name,
                    username: user.username,
                })
            )

            setShowSmallLoader(false)
            history.push('/chats')
        }
    }

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex overflow-y-auto no-scrollbar pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            {username && <Meta title={`Jetlyst | ${username}`} />}
            <div className='flex justify-center w-full'>
                {/* <div className='bg-gray-200 dark:bg-gray-700 w-14 md:w-40 rounded-r-xl pt-8 h-full'>
					<motion.ul className='text-blue-700 h-full '>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i class='fas fa-user text-lg'></i>
							<span className='ml-2 hidden md:block'>Profile</span>
						</li>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i className='fas fa-envelope text-lg'></i>
							<span className='ml-2 hidden md:block'>Messages</span>
						</li>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-2 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i class='fas fa-igloo text-lg'></i>
							<span className='ml-2 hidden md:block'>Feed</span>
						</li>

						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i className='fas fa-cogs text-lg'></i>
							<span className='ml-2 hidden md:block'>Settings</span>
						</li>
						<li></li>
						<li></li>
					</motion.ul>
				</div> */}
                {loadingUser ? (
                    <Loader />
                ) : user ? (
                    <div className='flex flex-col h-full w-full mx-0 md:w-11/12 lg:w-5/6 md:mx-6 md:rounded-t-2xl overflow-hidden'>
                        <div className='h-48 w-full z-20'>
                            <img
                                src={user.bannerImage}
                                className='w-full h-full object-cover '
                            />
                            <div className='h-40 w-40 md:h-44 md:w-44 rounded-full overflow-hidden -mt-28 mx-auto md:ml-4'>
                                <img
                                    src={user.imageUrl}
                                    className='object-cover h-full w-full'
                                />
                            </div>
                        </div>

                        <div
                            className={
                                'h-full md:mx-0' +
                                (tab === 'posts' ? ' w-full' : 'mx-6')
                            }
                        >
                            <div className='flex flex-col w-full h-full'>
                                {/* nav */}
                                <div className='w-full mt-12 md:mt-0  md:pt-4 flex flex-col md:flex-row lg:flex-row lg:justify-between md:pl-48 max-h-48 overflow-none'>
                                    <div className='w-full px-3 md:px-0 md:w-2/3 lg:w-2/3'>
                                        {user.name && (
                                            <p className=' dark:text-white text-lg font-black'>
                                                {user.name}
                                            </p>
                                        )}
                                        {user.location && (
                                            <p className=' dark:text-white text-lg font-medium md:-ml-8'>
                                                <i className='fas fa-map-marker-alt ml-0 md:ml-2 mr-0.5 md:mr-1'></i>{' '}
                                                {user.location}
                                                {/* Peterborough,ON */}
                                            </p>
                                        )}
                                        {user.description && (
                                            <p className=' text-gray-700 dark:text-gray-300'>
                                                {user.description}
                                                {/* I love
														travelling and playing football
														I love travelling and */}
                                            </p>
                                        )}
                                        <div className='sm:mt-2 md:mt-6 flex items-center justify-between w-full md:w-72 lg:w-96'>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500' +
                                                    (tab === 'posts'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() => setTab('posts')}
                                            >
                                                <span className='mr-2'>
                                                    {count & count}
                                                </span>
                                                <span>Posts</span>
                                            </button>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500' +
                                                    (tab === 'followers'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() =>
                                                    setTab('followers')
                                                }
                                            >
                                                <span className='mr-2'>
                                                    {otherFollowers
                                                        ? otherFollowers.length
                                                        : '..'}
                                                </span>
                                                <span>Followers</span>
                                            </button>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500' +
                                                    (tab === 'following'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() =>
                                                    setTab('following')
                                                }
                                            >
                                                <span className='mr-2'>
                                                    {otherFollowing
                                                        ? otherFollowing.length
                                                        : '..'}
                                                </span>
                                                <span>Following</span>
                                            </button>
                                            {user &&
                                                user._id &&
                                                bucketlists &&
                                                bucketlists.length > 0 && (
                                                    <button
                                                        className={
                                                            'hover:text-blue-500 dark:hover:text-blue-500' +
                                                            (tab ===
                                                            'bucketlists'
                                                                ? ' text-blue-500 dark:text-blue-500'
                                                                : ' dark:text-white')
                                                        }
                                                        onClick={() =>
                                                            setTab(
                                                                'bucketlists'
                                                            )
                                                        }
                                                    >
                                                        <span>Bucketlists</span>
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                    <div className='mt-2 md:mt-0 px-3 md:px-0 sm:w-full md:w-2/3 lg:w-1/3 flex flex-row md:flex-col lg:flex-row justify-between md:justify-end lg:justify-end items-center'>
                                        <button
                                            className=' flex items-center justify-evenly px-2 py-1 md:p-2 bg-blue-500 hover:bg-blue-400 transition-all ease-in duration-500 rounded-2xl text-gray-200 mr-1 md:mr-0 lg:mr-8 md:mb-4 lg:mb-0'
                                            onClick={() =>
                                                submitHandler(user._id)
                                            }
                                        >
                                            {userInfo &&
                                            otherFollowers &&
                                            otherFollowers.find(
                                                (item) =>
                                                    item._id === userInfo._id
                                            ) ? (
                                                <>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='h-5 w-5 text-white mr-2'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'
                                                    >
                                                        <path d='M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z' />
                                                    </svg>
                                                    Unfollow
                                                </>
                                            ) : (
                                                <>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='h-5 w-5 text-white mr-2'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'
                                                    >
                                                        <path d='M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z' />
                                                    </svg>
                                                    Follow
                                                </>
                                            )}
                                        </button>

                                        {showSmallLoader ? (
                                            <SmallLoader />
                                        ) : (
                                            <button
                                                className='ml-1 md:ml-0 flex items-center justify-evenly px-2 py-1 md:p-2 bg-blue-500 hover:bg-blue-400 transition-all ease-in duration-500 rounded-2xl text-gray-200'
                                                onClick={messageHandler}
                                            >
                                                <i className='far fa-comment-dots mr-2'></i>
                                                Message
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        'w-full h-auto flex items-center mt-8 pl-0 ' +
                                        (tab === 'posts' ? '' : ' md:pl-48')
                                    }
                                >
                                    {/* content */}
                                    {tab === 'posts' ? (
                                        <div className='h-full w-full pb-12'>
                                            {otherPostsLoading && <Loader />}
                                            {otherPosts &&
                                                otherPosts.map((item) => (
                                                    <Post
                                                        by={user}
                                                        post={item}
                                                        userInfo={userInfo}
                                                        profile={true}
                                                    />
                                                ))}

                                            {otherPosts.length < count ? (
                                                <div className='w-full flex items-center justify-center'>
                                                    <button
                                                        onClick={handleNext}
                                                        className='rounded-l-full rounded-r-full text-white bg-blue-700 hover:bg-blue-600 transition px-8 py-2 text-lg flex items-center justify-center'
                                                    >
                                                        More🔥
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='w-full flex items-center justify-center'>
                                                    <div className='dark:text-white px-8 py-2 text-lg flex items-center justify-center'>
                                                        That's the end 👀
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : tab === 'followers' ? (
                                        <div className='flex flex-wrap w-full h-full pb-16'>
                                            {otherFollowers &&
                                                otherFollowers.length > 0 &&
                                                otherFollowers.map((item) => {
                                                    if (
                                                        item._id !==
                                                        userInfo._id
                                                    ) {
                                                        console.log(
                                                            'got inside here'
                                                        )
                                                        let currFollow =
                                                            following &&
                                                            following.filter(
                                                                (x) =>
                                                                    x._id ===
                                                                    item._id
                                                            ).length > 0
                                                                ? true
                                                                : false
                                                        console.log(following)
                                                        let check = false
                                                        if (currFollow) {
                                                            check = true
                                                        }
                                                        console.log(currFollow)
                                                        return (
                                                            <SmallUserBox
                                                                key={item._id}
                                                                name={
                                                                    item.username
                                                                }
                                                                _id={item._id}
                                                                imageUrl={
                                                                    item.imageUrl
                                                                }
                                                                following={
                                                                    currFollow
                                                                }
                                                                handleClick={
                                                                    otherFollowHandler
                                                                }
                                                                other={true}
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <SmallUserBox
                                                                key={item._id}
                                                                name={
                                                                    item.username
                                                                }
                                                                _id={item._id}
                                                                imageUrl={
                                                                    item.imageUrl
                                                                }
                                                            />
                                                        )
                                                    }
                                                })}
                                        </div>
                                    ) : tab === 'following' ? (
                                        <div className='flex flex-wrap w-full h-full pb-16'>
                                            {otherFollowing &&
                                                otherFollowing.length > 0 &&
                                                otherFollowing.map((item) => {
                                                    if (
                                                        item._id !==
                                                        userInfo._id
                                                    ) {
                                                        console.log(
                                                            'got inside here'
                                                        )
                                                        let currFollow =
                                                            following &&
                                                            following.filter(
                                                                (x) =>
                                                                    x._id ===
                                                                    item._id
                                                            ).length > 0
                                                                ? true
                                                                : false
                                                        console.log(following)
                                                        let check = false
                                                        if (currFollow) {
                                                            check = true
                                                        }
                                                        console.log(currFollow)
                                                        return (
                                                            <SmallUserBox
                                                                key={item._id}
                                                                name={
                                                                    item.username
                                                                }
                                                                _id={item._id}
                                                                imageUrl={
                                                                    item.imageUrl
                                                                }
                                                                following={
                                                                    check
                                                                }
                                                                handleClick={
                                                                    otherFollowHandler
                                                                }
                                                                other={true}
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <SmallUserBox
                                                                key={item._id}
                                                                name={
                                                                    item.username
                                                                }
                                                                _id={item._id}
                                                                imageUrl={
                                                                    item.imageUrl
                                                                }
                                                            />
                                                        )
                                                    }
                                                })}
                                        </div>
                                    ) : tab === 'bucketlists' &&
                                      bucketlists &&
                                      bucketlists.length > 0 ? (
                                        <div className='w-full h-full flex pb-10'>
                                            <OtherBucketLists
                                                loading={listsLoading}
                                                bucketlists={bucketlists}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='h-full w-full flex items-center justify-center dark:text-white font-bold text-xl md:text-2xl'>
                        This user does not exist
                    </div>
                )}
            </div>
        </motion.div>
    )
}

export default OtherUserProfile
