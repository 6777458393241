import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Loader from './Loader'

const OtherBucketLists = ({ loading, bucketlists }) => {
    return (
        <div className='w-full overflow-x-auto max-h-screen overflow-y-auto no-scrollbar sm:-mx-6 lg:-mx-2'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2 mt-8 '>
                <div className='shadow overflow-hidden border-b border-gray-200 rounded-lg'>
                    {/* {error && <Message variant='danger'>{error}</Message>} */}
                    {loading ? (
                        <Loader />
                    ) : (
                        <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-500'>
                            <thead className='bg-gray-200 dark:bg-gray-700'>
                                <tr className=''>
                                    {/* <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'></th> */}
                                    <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                        Name
                                    </th>
                                    <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                        Description
                                    </th>
                                    <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                        Date Created
                                    </th>
                                    <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                        View
                                    </th>
                                    {/* <th scope='col' class='relative px-6 py-3'>
											<span class='sr-only'>Edit</span>
										</th> */}
                                </tr>
                            </thead>
                            <tbody className='bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-800'>
                                {bucketlists &&
                                    bucketlists.map((list) => (
                                        <tr key={list._id} className=''>
                                            <td className='px-6 py-4 whitespace-nowrap'>
                                                <div class='flex items-center'>
                                                    <div class='flex-shrink-0 h-10 w-10'>
                                                        <img
                                                            className='inline-block h-10 w-10 rounded-full ring-2 ring-white object-cover'
                                                            src={
                                                                list.bannerImage
                                                            }
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div class='ml-4'>
                                                        <div class='text-sm font-medium text-gray-900 dark:text-gray-200'>
                                                            {list.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            {/* <td>
													<Link
														to={`/bucketlist/${list._id}`}
														className='text-blue-500 hover:text-blue-800 no-underline hover:underline'
													>
														{list.name}
													</Link>
												</td> */}
                                            <td className='px-6 py-4'>
                                                <div className='text-sm text-gray-900 dark:text-gray-200 '>
                                                    {list.description}
                                                </div>
                                            </td>
                                            <td className='px-6 py-4 whitespace-nowrap'>
                                                <div className='text-sm text-gray-900 dark:text-gray-200'>
                                                    {moment(
                                                        new Date(list.createdAt)
                                                    ).fromNow()}
                                                </div>
                                            </td>

                                            <td className='px-6 py-4 whitespace-nowrap'>
                                                <Link
                                                    to={`/bucketlist/${list._id}`}
                                                    className='text-blue-500 hover:text-blue-800 no-underline hover:underline'
                                                >
                                                    <i className='fas fa-eye text-blue-500 hover:text-blue-400 cursor-pointer dark:hover:text-gray-200'></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OtherBucketLists
