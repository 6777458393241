import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    followUser,
    listFollowers,
    listFollowing,
    unfollowUser,
} from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SmallUserBox from '../components/common/SmallUserBox'
import { getMyPosts, getSavedPosts } from '../actions/feedActions'
import SmallPost from '../components/feed/SmallPost'
import Post from '../components/feed/Post'
import {
    USER_FOLLOW_RESET,
    USER_UNFOLLOW_RESET,
} from '../constants/userConstants'
import Meta from '../components/common/Meta'

const ProfileScreen = ({ history }) => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const [tab, setTab] = useState('posts')
    const [offset, setOffset] = useState(0)

    // const userFollow = useSelector((state) => state.userFollow)
    // const { loading, following, error } = userFollow
    const personalPosts = useSelector((state) => state.myPosts)
    const {
        loading: myPostsLoading,
        myPosts,
        count,
        page,
        error: myPostsError,
    } = personalPosts

    const savedFeed = useSelector((state) => state.savedPosts)
    const {
        loading: savedPostsLoading,
        savedPosts,
        page: savedPage,
        count: savedCount,
        error: savedPostsError,
    } = savedFeed

    const userFollow = useSelector((state) => state.userFollow)
    const { loading, success, error } = userFollow

    const userUnfollow = useSelector((state) => state.userUnfollow)
    const { success: successUnfollow, error: unfollowError } = userUnfollow
    const followerList = useSelector((state) => state.listFollowers)
    const {
        loading: loadingFollowers,
        followers,
        error: errorFollowers,
    } = followerList

    const followingList = useSelector((state) => state.listFollowing)
    const { following } = followingList

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!myPosts || myPosts.length === 0) dispatch(getMyPosts())
            if (!savedPosts || savedPosts.length === 0)
                dispatch(getSavedPosts())
            dispatch(listFollowing())
            dispatch(listFollowers())
        }
    }, [userInfo])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (success || successUnfollow) {
                dispatch(listFollowing())
                if (successUnfollow) dispatch({ type: USER_UNFOLLOW_RESET })
                if (success) dispatch({ type: USER_FOLLOW_RESET })
            }
        }
    }, [success, successUnfollow])

    useEffect(() => {
        window.scrollTo({
            top: offset,
        })
    }, [page, savedPage])

    const handleNext = () => {
        setOffset(window.pageYOffset)
        dispatch(getMyPosts(page + 1))
    }

    const handleNextSaved = () => {
        setOffset(window.pageYOffset)
        dispatch(getSavedPosts(savedPage + 1))
    }

    const submitHandler = (id, action) => {
        switch (action) {
            case 'follow':
                dispatch(followUser(id))
                break
            case 'unfollow':
                if (following && following.find((item) => item._id === id))
                    dispatch(unfollowUser(id))
                break
            default:
                break
        }
    }

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex overflow-y-auto no-scrollbar pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Profile`} />

            <div className='flex justify-center w-full'>
                {/* <div className='bg-gray-200 dark:bg-gray-700 w-14 md:w-40 rounded-r-xl pt-8 h-full'>
					<motion.ul className='text-blue-700 h-full '>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i class='fas fa-user text-lg'></i>
							<span className='ml-2 hidden md:block'>Profile</span>
						</li>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i className='fas fa-envelope text-lg'></i>
							<span className='ml-2 hidden md:block'>Messages</span>
						</li>
						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-2 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i class='fas fa-igloo text-lg'></i>
							<span className='ml-2 hidden md:block'>Feed</span>
						</li>

						<li className='cursor-pointer hover:bg-white dark:hover:bg-gray-800 px-4 py-4 md:px-6 md:py-4 mt-6 flex items-end font-medium dark:text-gray-300 dark:hover:text-white rounded-l-3xl'>
							<i className='fas fa-cogs text-lg'></i>
							<span className='ml-2 hidden md:block'>Settings</span>
						</li>
						<li></li>
						<li></li>
					</motion.ul>
				</div> */}
                {userInfo && (
                    <div className='flex flex-col h-full w-full mx-0 md:w-11/12 lg:w-5/6 md:mx-6 md:rounded-t-2xl overflow-hidden'>
                        <div className='h-48 w-full z-20'>
                            <img
                                src={userInfo.bannerImage}
                                className='w-full h-full object-cover '
                            />
                            <div className='h-40 w-40 md:h-44 md:w-44 overflow-hidden rounded-full -mt-28 mx-auto md:ml-4'>
                                <img
                                    src={userInfo.imageUrl}
                                    className='object-cover h-full w-full'
                                />
                            </div>
                        </div>

                        <div
                            className={
                                'h-full md:mx-0' +
                                (tab === 'posts' ? ' w-full' : 'mx-6')
                            }
                        >
                            <div className='flex flex-col w-full h-full'>
                                {/* nav */}
                                <div className='w-full mt-12 md:mt-0  md:pt-4 flex flex-col md:flex-row lg:justify-between md:pl-48 max-h-48 overflow-none'>
                                    <div className='w-full px-3 md:px-0 md:w-2/3 lg:w-2/3'>
                                        {userInfo.name && (
                                            <p className=' dark:text-white text-lg font-black'>
                                                {userInfo.name}
                                            </p>
                                        )}
                                        {userInfo.location && (
                                            <p className=' dark:text-white font-medium md:-ml-7 text-sm md:text-base'>
                                                <i className='fas fa-map-marker-alt ml-0 md:ml-2 mr-0.5 md:mr-1'></i>{' '}
                                                {userInfo.location}
                                                {/* Peterborough,ON */}
                                            </p>
                                        )}
                                        {userInfo.description && (
                                            <p className=' text-gray-700 dark:text-gray-300 text-sm md:text-base'>
                                                {userInfo.description}
                                                {/* I love travelling and
													playing football I love travelling and */}
                                            </p>
                                        )}
                                        <div className='mt-2 md:mt-6 flex items-end justify-between w-full md:w-72 lg:w-96'>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500 tracking-wide text-sm md:text-base' +
                                                    (tab === 'posts'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() => setTab('posts')}
                                            >
                                                {count && count} Posts
                                            </button>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500 tracking-wide text-sm md:text-base' +
                                                    (tab === 'followers'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() =>
                                                    setTab('followers')
                                                }
                                            >
                                                {followers && followers.length}{' '}
                                                Followers
                                            </button>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500 tracking-wide text-sm md:text-base' +
                                                    (tab === 'following'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() =>
                                                    setTab('following')
                                                }
                                            >
                                                {following && following.length}{' '}
                                                Following
                                            </button>
                                            <button
                                                className={
                                                    'hover:text-blue-500 dark:hover:text-blue-500 tracking-wide text-sm md:text-base' +
                                                    (tab === 'saved'
                                                        ? ' text-blue-500 dark:text-blue-500'
                                                        : ' dark:text-white')
                                                }
                                                onClick={() => setTab('saved')}
                                            >
                                                Saved
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className='mt-2 md:mt-0 sm:w-full md:w-2/3 lg:w-1/3 flex flex-row md:flex-col lg:flex-row justify-between md:justify-end lg:justify-end items-center'>
										<button className='w-1/3 md:w-full lg:w-1/3 flex items-center justify-evenly p-2 bg-blue-500 hover:bg-blue-400 transition-all ease-in duration-500 rounded-2xl text-gray-200 md:mr-0 lg:mr-8 md:mb-4 lg:mb-0'>
											<i className='fas fa-user-plus mr-2'></i>
											Follow
										</button>

										<button className='w-1/3 md:w-full lg:w-1/3 flex items-center justify-evenly p-2 bg-blue-500 hover:bg-blue-400 transition-all ease-in duration-500 rounded-2xl text-gray-200'>
											<i className='far fa-comment-dots mr-2'></i>
											Message
										</button>
									</div> */}
                                </div>

                                <div
                                    className={
                                        'w-full h-auto flex items-center mt-8 pl-0 ' +
                                        (tab === 'posts' || tab === 'saved'
                                            ? ''
                                            : ' md:pl-48')
                                    }
                                >
                                    {/* content */}
                                    {tab === 'posts' ? (
                                        <div className='h-full w-full pb-10'>
                                            {myPostsLoading && <Loader />}
                                            {myPosts &&
                                                myPosts.map((item) => (
                                                    <Post
                                                        by={userInfo}
                                                        post={item}
                                                        userInfo={userInfo}
                                                        profile={true}
                                                    />
                                                ))}
                                            {myPosts &&
                                            myPosts.length < count ? (
                                                <div className='w-full flex items-center justify-center'>
                                                    <button
                                                        onClick={handleNext}
                                                        className='rounded-l-full rounded-r-full text-white bg-blue-700 hover:bg-blue-600 transition px-8 py-2 text-lg flex items-center justify-center'
                                                    >
                                                        More🔥
                                                    </button>
                                                </div>
                                            ) : (
                                                !myPostsLoading && (
                                                    <div className='w-full flex items-center justify-center'>
                                                        <div className='dark:text-white px-8 py-2 text-lg flex items-center justify-center'>
                                                            That's the end 👀
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : tab === 'followers' ? (
                                        <div className='flex flex-wrap w-full h-full pb-16'>
                                            {followers &&
                                                followers.map((item) => {
                                                    let currFollow =
                                                        following &&
                                                        following.filter(
                                                            (x) =>
                                                                x._id ===
                                                                item._id
                                                        ).length > 0
                                                            ? true
                                                            : false

                                                    return (
                                                        <SmallUserBox
                                                            name={item.username}
                                                            _id={item._id}
                                                            imageUrl={
                                                                item.imageUrl
                                                            }
                                                            following={
                                                                currFollow
                                                            }
                                                            other={true}
                                                            handleClick={
                                                                submitHandler
                                                            }
                                                        />
                                                    )
                                                })}
                                        </div>
                                    ) : tab === 'following' ? (
                                        <div className='flex flex-wrap w-full h-full pb-16'>
                                            {following &&
                                                following.map((item) => {
                                                    return (
                                                        <SmallUserBox
                                                            key={item._id}
                                                            name={item.username}
                                                            _id={item._id}
                                                            imageUrl={
                                                                item.imageUrl
                                                            }
                                                            other={true}
                                                            following={true}
                                                            handleClick={
                                                                submitHandler
                                                            }
                                                        />
                                                    )
                                                })}
                                        </div>
                                    ) : (
                                        tab === 'saved' && (
                                            <div className='h-full w-full pb-20'>
                                                {savedPostsLoading && (
                                                    <Loader />
                                                )}
                                                {savedPosts &&
                                                    savedPosts.map((item) => (
                                                        <Post
                                                            by={item.postedBy}
                                                            post={item}
                                                            userInfo={userInfo}
                                                            profile={true}
                                                        />
                                                    ))}
                                                {savedPosts &&
                                                savedPosts.length <
                                                    savedCount ? (
                                                    <div className='w-full flex items-center justify-center'>
                                                        <button
                                                            onClick={
                                                                handleNextSaved
                                                            }
                                                            className='rounded-l-full rounded-r-full text-white bg-blue-700 hover:bg-blue-600 transition px-8 py-2 text-lg flex items-center justify-center'
                                                        >
                                                            More🔥
                                                        </button>
                                                    </div>
                                                ) : (
                                                    !savedPostsLoading && (
                                                        <div className='w-full flex items-center justify-center'>
                                                            <div className='dark:text-white px-8 py-2 text-lg flex items-center justify-center'>
                                                                That's the end
                                                                👀
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </motion.div>
    )
}

export default ProfileScreen
