import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Loader'
import SmallLoader from '../components/SmallLoader'
import Message from '../components/Message'
import Post from '../components/feed/Post'
import {
    createPost,
    getMyFeed,
    getMyPosts,
    likePost,
    savePost,
} from '../actions/feedActions'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import {
    ADD_POST_RESET,
    FEED_SUCCESS,
    MY_POSTS_SUCCESS,
} from '../constants/feedConstants'
import Meta from '../components/common/Meta'
import CropContainer from '../components/common/Crop/CropContainer'
import CropModal from '../components/common/Crop/CropModal'
SwiperCore.use([Pagination, Navigation])
const FeedScreen = ({ history }) => {
    const dispatch = useDispatch()
    const [files, setFiles] = useState([])
    const [images, setImages] = useState([])
    const [text, setText] = useState('')
    const [details, setDetails] = useState({})
    const [currFeed, setCurrFeed] = useState([])
    const [newPosts, setNewPosts] = useState([])
    const [showCrop, setShowCrop] = useState(false)
    const [currentImage, setCurrentImage] = useState('')
    const [currentFile, setCurrentFile] = useState(null)
    const [showFileError, setShowFileError] = useState(false)

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const stateFeed = useSelector((state) => state.feed)
    const { loading, feed, page, error } = stateFeed

    const addPost = useSelector((state) => state.addPost)
    const {
        loading: newPostLoading,
        newPost,
        error: newPostError,
        success,
    } = addPost
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!feed || feed.length === 0) dispatch(getMyFeed())
        }
    }, [history, userInfo])

    useEffect(() => {
        setCurrFeed(feed)
    }, [feed, feed.length])
    useEffect(() => {
        if (success) {
            setText('')
            setImages([])
            const newItem = (
                <Post
                    by={newPost.postedBy}
                    post={newPost}
                    userInfo={userInfo}
                />
            )
            dispatch({
                type: FEED_SUCCESS,
                payload: { newPost },
            })
            dispatch({
                type: MY_POSTS_SUCCESS,
                payload: { newPost },
            })

            dispatch({
                type: ADD_POST_RESET,
            })
        }
    }, [success])

    const handleNext = () => {
        dispatch(getMyFeed(page + 1))
    }
    const submitHandler = async (e) => {
        e.preventDefault()
        if (text) {
            let media = []
            if (files.length > 0) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                await Promise.all(
                    files.map(async (item) => {
                        const { data } = await axios.get(
                            `/api/media/s3Url?type=${
                                item.type === 'video/mp4' ? 'video' : 'image'
                            }`,
                            config
                        )
                        console.log(data.url)
                        // const formData = new FormData()
                        // formData.append("image", currentFile)
                        await axios.put(data.url, item, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                        media.push(data.url.split('?')[0])
                    })
                )
            }
            dispatch(createPost({ text, media }))
            setFiles([])
            setImages([])
            setCurrentFile(null)
            setCurrentImage('')
        }
    }
    // const resize = (val) => {
    //     let field = document.getElementById('new-post')
    //     // Reset field height
    //     field.style.height = 'inherit'
    //     if (val) {
    //         // Get the computed styles for the element
    //         var computed = window.getComputedStyle(field)

    //         // Calculate the height
    //         var height =
    //             parseInt(computed.getPropertyValue('border-top-width'), 10) +
    //             parseInt(computed.getPropertyValue('padding-top'), 10) +
    //             field.scrollHeight +
    //             parseInt(computed.getPropertyValue('padding-bottom'), 10) +
    //             parseInt(computed.getPropertyValue('border-bottom-width'), 10)

    //         field.style.height = height + 'px'
    //     }
    // }
    const resize = (val) => {
        let field = document.getElementById(`new-post`)
        let bottom = document.getElementById(`new-post-bottom`)
        if (val === '') {
            field.style.height = '2.5rem'
        } else {
            field.style.height = 'auto'
            field.style.height = field.scrollHeight + 'px'
            bottom.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const handleFiles = (e) => {
        if (e.target.files[0].size > 5000000) {
            setShowFileError(true)
            return
        } else {
            if (showFileError) setShowFileError(false)
        }
        if (e.target.files[0].type !== 'video/mp4') {
            setShowCrop(true)
            setCurrentImage(URL.createObjectURL(e.target.files[0]))
            setCurrentFile(e.target.files[0])
        } else {
            setFiles((prevstate) => [...prevstate, e.target.files[0]])
            setImages((prevstate) => [
                ...prevstate,
                URL.createObjectURL(e.target.files[0]),
            ])
        }
    }

    const handleDone = (croppedImage) => {
        setShowCrop(false)
        setFiles((prevstate) => [...prevstate, croppedImage.file])
        setImages((prevstate) => [...prevstate, croppedImage.url])
        setCurrentFile(null)
    }
    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex overflow-y-auto no-scrollbar pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Feed`} />
            <CropModal
                openModal={showCrop}
                image={currentImage}
                setOpenModal={setShowCrop}
                handleDone={handleDone}
            />
            <div className='flex flex-1 w-full'>
                <div className='w-full h-full flex flex-col items-center'>
                    <div className='h-full w-full md:w-5/6 pb-20'>
                        {userInfo && (
                            <>
                                <form
                                    onSubmit={submitHandler}
                                    className='w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto bg-gray-100 dark:bg-gray-700 md:rounded-xl overflow-hidden mb-6 py-3'
                                >
                                    <div className='pt-3 pb-2 dark:text-white w-full border-b dark:border-opacity-25 font-bold flex justify-between px-4 md:px-5'>
                                        <span>
                                            Post Something{' '}
                                            {showFileError && (
                                                <span className='ml-2 text-sm text-red-500'>
                                                    Files must be less than 5MB
                                                    in size
                                                </span>
                                            )}
                                        </span>
                                        <button className='' type='submit'>
                                            <i className='far fa-paper-plane text-xl dark:text-white hover:text-blue-500 transition noSelect'></i>
                                        </button>
                                    </div>
                                    {images &&
                                        (images.length === 1 ? (
                                            <div className='max-h-96 w-full overflow-hidden mt-4 rounded-lg px-4 md:px-5 relative'>
                                                {files[0] &&
                                                    (files[0].type ===
                                                    'video/mp4' ? (
                                                        <video
                                                            src={images[0]}
                                                            className='h-full w-full max-h-96 object-contain bg-black'
                                                            controls
                                                            loop
                                                            autoPlay
                                                        />
                                                    ) : (
                                                        <img
                                                            src={images[0]}
                                                            className='h-96 w-full object-cover'
                                                        />
                                                    ))}
                                                <button
                                                    onClick={() => {
                                                        setImages([])
                                                        setFiles([])
                                                        setCurrentFile([])
                                                        setCurrentImage([])
                                                    }}
                                                    className='absolute top-0 right-0 font-bold text-red-500 dark:text-red-500 hover:text-red-400 dark:hover:text-red-500 text-xs'
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='h-6 w-6'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        ) : (
                                            <Swiper
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                centeredSlides
                                                loop
                                                slidesPerView={1}
                                                className='w-full h-full flex flex-col-reverse'
                                            >
                                                {images &&
                                                    images.map(
                                                        (item, index) => (
                                                            <SwiperSlide className='h-full min-w-full overflow-hidden flex items-center bg-black relative'>
                                                                {console.log(
                                                                    files[index]
                                                                )}
                                                                {files[index] &&
                                                                    (files[
                                                                        index
                                                                    ].type ===
                                                                    'video/mp4' ? (
                                                                        <video
                                                                            src={
                                                                                item
                                                                            }
                                                                            className='h-full w-full object-cover bg-black my-auto'
                                                                            controls
                                                                            loop
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                item
                                                                            }
                                                                            className='h-full w-full object-contain my-auto'
                                                                        />
                                                                    ))}
                                                                <button
                                                                    onClick={() => {
                                                                        setImages(
                                                                            (
                                                                                prevState
                                                                            ) => [
                                                                                ...prevState.filter(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item !==
                                                                                        images[
                                                                                            index
                                                                                        ]
                                                                                ),
                                                                            ]
                                                                        )
                                                                        setFiles(
                                                                            (
                                                                                prevState
                                                                            ) => [
                                                                                ...prevState.filter(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item !==
                                                                                        files[
                                                                                            index
                                                                                        ]
                                                                                ),
                                                                            ]
                                                                        )
                                                                    }}
                                                                    className='absolute top-0 right-0 font-bold text-red-500 dark:text-red-500 hover:text-red-400 dark:hover:text-red-500 text-xs'
                                                                >
                                                                    <svg
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        className='h-6 w-6'
                                                                        viewBox='0 0 20 20'
                                                                        fill='currentColor'
                                                                    >
                                                                        <path
                                                                            fillRule='evenodd'
                                                                            d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                                                                            clipRule='evenodd'
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </SwiperSlide>
                                                        )
                                                    )}
                                            </Swiper>
                                        ))}
                                    <div className='flex items-center pt-4 mb-2 w-full px-4 md:px-5'>
                                        <img
                                            src={userInfo.imageUrl}
                                            className='rounded-full object-cover h-11 w-11'
                                        />
                                        <div className='w-full flex items-center'>
                                            <div className='w-full flex flex-col ml-5 overflow-y-auto max-h-40 overflow-x-hidden no-scrollbar '>
                                                <div className='w-full h-full flex items-center no-scrollbar'>
                                                    <textarea
                                                        type='text'
                                                        id='new-post'
                                                        placeholder="What's on your mind?"
                                                        className={
                                                            'w-full bg-transparent border-0 outline-none placeholder-gray-400 h-10 min-h-10 dark:text-white resize-none overflow-hidden pt-2 px-1 md:px-3 ' +
                                                            (text ? '' : '')
                                                        }
                                                        value={text}
                                                        onChange={(e) => {
                                                            setText(
                                                                e.target.value
                                                            )
                                                            resize(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <div
                                                        id={`new-post-bottom`}
                                                        className='hidden'
                                                    />
                                                </div>
                                            </div>

                                            <label htmlFor='post-pic'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='h-6 w-6 dark:text-white cursor-pointer hover:text-blue-500 dark:hover:text-blue-500 noSelect'
                                                    viewBox='0 0 20 20'
                                                    fill='currentColor'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>
                                            </label>
                                            <input
                                                type='file'
                                                id='post-pic'
                                                onChange={(e) => {
                                                    handleFiles(e)
                                                }}
                                                className='hidden'
                                            />
                                        </div>
                                    </div>
                                </form>
                                {newPosts && newPosts}
                                {currFeed &&
                                    currFeed.length > 0 &&
                                    currFeed.map((post) => {
                                        return (
                                            <Post
                                                key={post._id}
                                                by={post.postedBy}
                                                post={post}
                                            />
                                        )
                                    })}
                                {loading && <Loader />}
                                <div className='w-full flex items-center justify-center'>
                                    <button
                                        onClick={handleNext}
                                        className='rounded-l-full rounded-r-full text-white bg-blue-700 hover:bg-blue-600 transition px-8 py-2 text-lg flex items-center justify-center'
                                    >
                                        More🔥
                                    </button>
                                </div>
                                {/* <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} />
                                <Post by={userInfo} userInfo={userInfo} /> */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default FeedScreen
