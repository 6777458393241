import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import axios from 'axios'
import moment from 'moment'
import { USER_LOGIN_SUCCESS } from '../constants/userConstants'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import Comment from '../components/feed/Comment'
import LikesModal from '../components/feed/LikesModal'
import Loader from '../components/Loader'
import Post from '../components/feed/Post'
import Meta from '../components/common/Meta'

SwiperCore.use([Pagination, Navigation])
const PostScreen = ({ match }) => {
    const dispatch = useDispatch()
    const postId = match.params.id
    const [post, setPost] = useState({})
    const [by, setBy] = useState({})
    const [loading, setLoading] = useState(false)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        const fetchPost = async () => {
            try {
                setLoading(true)
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const { data } = await axios.get(`/api/posts/${postId}`, config)
                setPost(data)
                setBy(data.postedBy)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        fetchPost()
    }, [])
    // useEffect(() => {
    //     if (post) {

    //         if (userInfo && post.likes && post.likes.includes(userInfo._id)) {
    //             setLiked(true)
    //         } else {
    //             setLiked(false)
    //         }

    //         if (userInfo && userInfo.saved.includes(post._id)) {
    //             setSaved(true)
    //         } else {
    //             setSaved(false)
    //         }
    //     }
    // }, [post])

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex overflow-y-auto no-scrollbar pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Post`} />

            <div className='flex flex-1 w-full'>
                <div className='w-full h-full flex flex-col items-center'>
                    {loading ? (
                        <Loader />
                    ) : post && post._id ? (
                        <Post key={post._id} by={by} post={post} />
                    ) : (
                        <div className='h-full w-full flex items-center justify-center dark:text-white font-bold text-xl md:text-2xl'>
                            This post does not exist
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    )
}

export default PostScreen
