import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import ContactItem from './ContactItem'

const ChatsList = ({ chats, onChatClick, selectedChatId, participants }) => {
    const currentChatObject = useSelector((state) => state.currentChat)
    const { chat: currentChat } = currentChatObject
    useEffect(() => {
        let footer = document.getElementById('small-footer')
        let nav = document.getElementById('navbar')
        if (footer.classList.contains('hidden')) {
            footer.classList.remove('hidden')
        }
        if (nav.classList.contains('hidden')) {
            nav.classList.remove('hidden')
        }
    }, [])
    useEffect(() => {
        if (currentChat && currentChat.id) {
            onChatClick('chat')
        }
    }, [currentChat])
    return (
        <>
          {/* <div className='w-full h-auto md:h-20 pb-2 px-2'>
                <form className='flex items-center justify-center h-12 w-full bg-transparent border-b border-gray-400 px-4 shadow-lg'>
                    <i className='fas fa-search mr-4 text-xl dark:text-white'></i>
                    <input
                        className='w-full focus:outline-none bg-transparent dark:text-white'
                        placeholder='Search'
                    />
                </form>
            </div>*/}
            <div className='w-full h-auto md:h-20 pb-2 px-2'>
                <h1 className='text-xl md:text-3xl dark:text-white font-black text-center'>
                    Chats
                </h1>
            </div>
            <div className='max-h-full w-full overflow-y-auto'>
                <div className='min-h-full w-full overflow-hidden'>
                    <div className='min-h-full flex flex-col pb-2'>
                        {chats &&
                            chats.length > 0 &&
                            chats.map((chat) => {
                                return (
                                    <ContactItem
                                        key={chat.id}
                                        chat={chat}
                                        pic_url={
                                            chat.otherMember &&
                                            chat.otherMember.imageUrl
                                        }
                                        name={
                                            chat.otherMember &&
                                            chat.otherMember.name
                                        }
                                        // recent_time='9:00'
                                        // _id={}
                                        onClick={() => onChatClick('chat')}
                                    />
                                )
                            })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatsList
