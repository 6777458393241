export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_BASIC_DETAILS_REQUEST = 'USER_BASIC_DETAILS_REQUEST'
export const USER_BASIC_DETAILS_SUCCESS = 'USER_BASIC_DETAILS_SUCCESS'
export const USER_BASIC_DETAILS_FAIL = 'USER_BASIC_DETAILS_FAIL'
export const USER_BASIC_DETAILS_RESET = 'USER_BASIC_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_FOLLOW_REQUEST = 'USER_FOLLOW_REQUEST'
export const USER_FOLLOW_SUCCESS = 'USER_FOLLOW_SUCCESS'
export const USER_FOLLOW_FAIL = 'USER_FOLLOW_FAIL'
export const USER_FOLLOW_RESET = 'USER_FOLLOW_RESET'

export const USER_UNFOLLOW_REQUEST = 'USER_UNFOLLOW_REQUEST'
export const USER_UNFOLLOW_SUCCESS = 'USER_UNFOLLOW_SUCCESS'
export const USER_UNFOLLOW_FAIL = 'USER_UNFOLLOW_FAIL'
export const USER_UNFOLLOW_RESET = 'USER_UNFOLLOW_RESET'

export const USER_LIST_FOLLOWERS_REQUEST = 'USER_LIST_FOLLOWERS_REQUEST'
export const USER_LIST_FOLLOWERS_SUCCESS = 'USER_LIST_FOLLOWERS_SUCCESS'
export const USER_LIST_FOLLOWERS_FAIL = 'USER_LIST_FOLLOWERS_FAIL'
export const USER_LIST_FOLLOWERS_RESET = 'USER_LIST_FOLLOWERS_RESET'

export const USER_LIST_FOLLOWERS_BY_ID_REQUEST =
	'USER_LIST_FOLLOWERS_BY_ID_REQUEST'
export const USER_LIST_FOLLOWERS_BY_ID_SUCCESS =
	'USER_LIST_FOLLOWERS_BY_ID_SUCCESS'
export const USER_LIST_FOLLOWERS_BY_ID_FAIL = 'USER_LIST_FOLLOWERS_BY_ID_FAIL'
export const USER_LIST_FOLLOWERS_BY_ID_RESET = 'USER_LIST_FOLLOWERS_BY_ID_RESET'

export const USER_LIST_FOLLOWING_REQUEST = 'USER_LIST_FOLLOWING_REQUEST'
export const USER_LIST_FOLLOWING_SUCCESS = 'USER_LIST_FOLLOWING_SUCCESS'
export const USER_LIST_FOLLOWING_FAIL = 'USER_LIST_FOLLOWING_FAIL'
export const USER_LIST_FOLLOWING_RESET = 'USER_LIST_FOLLOWING_RESET'

export const USER_LIST_FOLLOWING_BY_ID_REQUEST =
	'USER_LIST_FOLLOWING_BY_ID_REQUEST'
export const USER_LIST_FOLLOWING_BY_ID_SUCCESS =
	'USER_LIST_FOLLOWING_BY_ID_SUCCESS'
export const USER_LIST_FOLLOWING_BY_ID_FAIL = 'USER_LIST_FOLLOWING_BY_ID_FAIL'
export const USER_LIST_FOLLOWING_BY_ID_RESET = 'USER_LIST_FOLLOWING_BY_ID_RESET'
