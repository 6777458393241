import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import ContextMenu from './ContextMenu'
import { deleteComment } from '../../actions/feedActions'

const Comment = ({
    comment,
    postedBy,
    setReplyTo,
    setComment,
    reply,
    handleDelete,
    postOwner = false,
}) => {
    moment.locale('en', {
        relativeTime: {
            future: 'now %s',
            past: '%s ago',
            s: 'now',
            ss: '%ss',
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1M',
            MM: '%dM',
            y: '1Y',
            yy: '%dY',
        },
    })
    const dispatch = useDispatch()
    const [liked, setLiked] = useState(false)
    const [likesCount, setLikesCount] = useState(0)
    const userLogin = useSelector((state) => state.userLogin)
    const [showReplies, setShowReplies] = useState(false)
    const [replies, setReplies] = useState([])
    const [replyCount, setReplyCount] = useState(3)
    const { userInfo } = userLogin
    const [showDelete, setShowDelete] = useState(false)
    const handleClick = () => {
        if (showDelete) setShowDelete(false)
    }

    const handleShowDelete = (e) => {
        e.preventDefault()
        setShowDelete(true)
    }
    useEffect(() => {
        setReplies(comment.replies)
        setLikesCount(comment.likes.length)
        if (comment && comment.likes.includes(userInfo._id)) {
            setLiked(true)
        } else {
            setLiked(false)
        }
        if (comment && (postOwner || comment.by === userInfo._id)) {
            let commentContainer = document.getElementById(
                `comment-${comment._id}`
            )
            document.addEventListener('click', handleClick)
            commentContainer.addEventListener('contextmenu', handleShowDelete)
        }
        return () => {
            if (postOwner || comment.by === userInfo._id) {
                let commentContainer = document.getElementById(
                    `comment-${comment._id}`
                )
                document.addEventListener('click', handleClick)
                if (commentContainer)
                    commentContainer.removeEventListener(
                        'contextmenu',
                        handleShowDelete
                    )
            }
        }
    }, [comment])

    const handleLike = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.put(
                `/api/posts/comments/${comment._id}/like`,
                {},
                config
            )
            if (data.success) {
                if (data.addInfo) {
                    setLiked(true)
                    setLikesCount((prevState) => prevState + 1)
                } else {
                    setLiked(false)
                    setLikesCount((prevState) => prevState - 1)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleReply = () => {
        setReplyTo({ to: postedBy.username, id: comment._id })
        let input = document.getElementById(`new-comment${comment.post}`)
        if (input) {
            input.focus()
            input.scrollTo({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            })
        }
    }

    const handleShowReplies = () => {
        setShowReplies(true)
    }
    const handleHideReplies = () => {
        setShowReplies(false)
    }
    const handleSeeMoreReplies = () => {
        setReplyCount((prevState) => prevState + 3)
    }

    return (
        <div
            id={`comment-${comment._id}`}
            className='w-full flex items-start mb-4'
        >
            {/* <ContextMenu
                allowed={
                    postOwner || comment.by === userInfo._id ? true : false
                }
                id={comment._id}
            /> */}
            <img
                src={postedBy && postedBy.imageUrl}
                alt="Commenter's Profile picture"
                className='rounded-full object-cover h-8 md:h-11 w-8 md:w-11'
            />

            <div className='w-full flex flex-col justify-center'>
                <div className='w-full flex items-start'>
                    <div className='w-full mx-2 md:ml-4 flex flex-col items-start dark:text-gray-200'>
                        <div className='flex items-center'>
                            <Link
                                to={
                                    postedBy &&
                                    postedBy.username === userInfo.username
                                        ? '/profile'
                                        : `/${postedBy && postedBy.username}`
                                }
                                className='font-bold text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 text-sm w-auto'
                            >
                                {postedBy && postedBy.username}
                            </Link>
                            <p className='text-xs text-gray-600  dark:text-gray-300'>
                                {/* {new Date(post.createdAt).toLocaleDateString('en-CA', {
                        day: 'numeric',
                        month: 'long',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                    })} */}
                                <span className='text-sm mx-1'>-</span>
                                {
                                    moment(new Date(comment.createdAt))
                                        .fromNow()
                                        .split(' ')[0]
                                }
                            </p>
                        </div>
                        <div className='text-sm mb-1'>
                            {comment.replyTo && (
                                <Link
                                    to={
                                        comment.replyTo === userInfo.username
                                            ? '/profile'
                                            : `/${comment.replyTo}`
                                    }
                                    className='font-bold text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 text-sm w-auto mr-1'
                                >
                                    @{comment.replyTo}
                                </Link>
                            )}
                            {comment.text}
                        </div>
                        <div className='flex items-center'>
                            <button
                                onClick={handleReply}
                                className='font-semibold text-gray-500 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-500 text-xs'
                            >
                                reply
                            </button>
                            <span className='text-xs text-gray-500 dark:text-gray-400 ml-4 md:ml-6'>
                                {likesCount > 0 && likesCount}{' '}
                                {likesCount > 0 &&
                                    (likesCount === 1 ? 'like' : 'likes')}
                            </span>
                            {showDelete && (
                                <button
                                    onClick={() => {
                                        handleDelete(comment._id)
                                    }}
                                    className='font-bold text-red-500 dark:text-red-400 hover:text-red-400 dark:hover:text-red-500 text-xs ml-4 md:ml-6'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-5 w-5'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                </button>
                            )}
                        </div>

                        {replies &&
                            replies.length > 0 &&
                            (!showReplies ? (
                                <div
                                    onClick={handleShowReplies}
                                    className='w-full text-gray-400 hover:text-blue-500 text-sm cursor-pointer'
                                >
                                    ...view replies
                                </div>
                            ) : (
                                <div
                                    onClick={handleHideReplies}
                                    className='w-full text-gray-400 hover:text-blue-500 text-sm cursor-pointer'
                                >
                                    ...hide replies
                                </div>
                            ))}
                    </div>
                    <div
                        onClick={handleLike}
                        className={
                            'flex flex-col ml-2 items-center justify-center dark:text-white transition duration-200 cursor-pointer noSelect' +
                            (liked ? '  text-red-500 dark:text-red-500' : '')
                        }
                    >
                        {liked ? (
                            <i
                                className={
                                    'fas fa-heart text-xs lg:text-xs cursor-pointer'
                                }
                            />
                        ) : (
                            <i
                                className={
                                    'far fa-heart text-xs lg:text-xs cursor-pointer'
                                }
                            />
                        )}
                    </div>
                </div>
                {showReplies && replies && replies.length > 0 && (
                    <div className='w-full pl-3 md:pl-5 mt-3'>
                        <div className='w-full'>
                            {replies &&
                                replies.slice(0, replyCount).map((item) => {
                                    return (
                                        <Comment
                                            key={item._id}
                                            comment={item}
                                            postedBy={item.postedBy}
                                            setReplyTo={setReplyTo}
                                            setComment={setComment}
                                            reply={true}
                                            handleDelete={handleDelete}
                                            postOwner={postOwner}
                                        />
                                    )
                                })}
                            {replies &&
                                replies.length > 0 &&
                                replies.length > 3 &&
                                replyCount < replies.length && (
                                    <button
                                        className='text-gray-400 text-left pl-8 md:pl-11 ml-2 md:ml-4 hover:text-blue-500 text-sm cursor-pointer'
                                        onClick={handleSeeMoreReplies}
                                    >
                                        see more replies
                                    </button>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Comment
