import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import HomeScreen from '../screens/HomeScreen'
import ProfileScreen from '../screens/ProfileScreen'
import OtherUserProfile from '../screens/OtherUserProfile'
import LoginScreen from '../screens/LoginScreen'
import FAQScreen from '../screens/FAQScreen'
import Team from '../screens/Team'
import ContactScreen from '../screens/ContactScreen'
import SignUpScreen from '../screens/SignUpScreen'
import BucketListsScreen from '../screens/BucketListsScreen'
import BucketListScreen from '../screens/BucketListScreen'
import AboutScreen from '../screens/AboutScreen'
import ChatScreen from '../screens/ChatScreen'
import NotFound from '../screens/NotFound'
import FeedScreen from '../screens/FeedScreen'
import ExploreScreen from '../screens/ExploreScreen'
import AccountSettings from '../screens/AccountSettings'
import PostScreen from '../screens/PostScreen'

function Routes({ location, userInfo, jetlystGAID, checkIfSet }) {
    useEffect(() => {
        checkIfSet()
    }, [])
    useEffect(() => {
        window.scrollTo({ top: 0 })

        const handleRouteChange = (url) => {
            window.gtag('config', jetlystGAID, {
                page_path: url,
            })
        }
        handleRouteChange(location.pathname)
        return () => {}
    }, [location])
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
                <Route path='/' exact component={HomeScreen} />
                <Route path='/about' component={AboutScreen} />
                <Route path='/contact' component={ContactScreen} />
                <Route path='/chats' component={ChatScreen} />
                <Route path='/faq' component={FAQScreen} />
                <Route path='/login' component={LoginScreen} />
                <Route path='/team' component={Team} />
                {/* <Route
                                        path='/explore'
                                        component={ExploreScreen}
                                    /> */}
                <Route path='/feed' component={FeedScreen} />
                <Route path='/profile' component={ProfileScreen} />
                <Route path='/settings' component={AccountSettings} />

                <Route path='/signup' component={SignUpScreen} />
                <Route path='/bucketlists' component={BucketListsScreen} />
                <Route
                    path='/bucketlist/:listid'
                    component={BucketListScreen}
                />
                <Route path='/posts/:id' component={PostScreen} />
                {userInfo && (
                    <Route path='/:username' component={OtherUserProfile} />
                )}

                <Route component={NotFound} />
            </Switch>
        </AnimatePresence>
    )
}

export default Routes
