import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SmallLoader from '../SmallLoader'

export default function AddItemModal({
    name,
    description,
    image,
    button,
    button2,
    openModal,
    setOpenModal,
    submitHandler,
    setCurrentFile,
    setName,
    setDescription,
    setImage,
    submitError,
    formLoad,
}) {
    const [open, setOpen] = useState(openModal)
    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    let nameTimeout = null
    let descriptionTimeout = null

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                static
                className='fixed z-50 inset-0 overflow-y-auto'
                open={openModal}
                onClose={() => {
                    setCurrentFile(null)
                    setOpenModal(false)
                }}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='hidden sm:inline-block sm:align-middle sm:h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800'>
                                <div className='sm:flex sm:items-start'>
                                    <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 sm:mx-0 sm:h-10 sm:w-10'>
                                        {/* <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                                    </div>
                                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                        <Dialog.Title
                                            as='h3'
                                            className='text-lg leading-6 font-medium text-gray-900 dark:text-gray-300'
                                        >
                                            Add New Item
                                        </Dialog.Title>
                                        <div className='mt-2 w-full'>
                                            <form
                                                onSubmit={submitHandler}
                                                className='flex flex-col justify-center items-center w-full'
                                            >
                                                {submitError && (
                                                    <div className='p-2 bg-red-600 dark:text-white border-2 mb-2 border-red-300 font-light mt-2 rounded-lg w-full'>
                                                        Fill in all required
                                                        fields
                                                    </div>
                                                )}
                                                <div className='w-full flex flex-col mb-2'>
                                                    <label className='mb-2 dark:text-gray-300'>
                                                        Name:
                                                    </label>
                                                    <input
                                                        type='text'
                                                        name='name'
                                                        value={name}
                                                        className='appearance-none rounded-none relative block w-full dark:bg-gray-200 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm'
                                                        onChange={(e) => {
                                                            if (nameTimeout) {
                                                                clearTimeout(
                                                                    nameTimeout
                                                                )
                                                            }
                                                            if (
                                                                e.target.value
                                                                    .length <=
                                                                40
                                                            )
                                                                setName(
                                                                    e.target
                                                                        .value
                                                                )
                                                            else {
                                                                setNameError(
                                                                    true
                                                                )
                                                                nameTimeout =
                                                                    setTimeout(
                                                                        () => {
                                                                            setNameError(
                                                                                false
                                                                            )
                                                                        },
                                                                        4000
                                                                    )
                                                            }
                                                        }}
                                                    />
                                                    {nameError && (
                                                        <div className='p-2 text-red-500'>
                                                            Must be 40
                                                            characters or less
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='w-full flex flex-col mb-2'>
                                                    <label className='mb-2 dark:text-gray-300'>
                                                        Description:
                                                    </label>
                                                    <textarea
                                                        type='text'
                                                        name='name'
                                                        value={description}
                                                        className='appearance-none rounded-none relative dark:bg-gray-200 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm max-h-48'
                                                        onChange={(e) => {
                                                            if (
                                                                descriptionTimeout
                                                            ) {
                                                                clearTimeout(
                                                                    descriptionTimeout
                                                                )
                                                            }
                                                            if (
                                                                e.target.value
                                                                    .length <=
                                                                200
                                                            )
                                                                setDescription(
                                                                    e.target
                                                                        .value
                                                                )
                                                            else {
                                                                setDescriptionError(
                                                                    true
                                                                )
                                                                descriptionTimeout =
                                                                    setTimeout(
                                                                        () => {
                                                                            setDescriptionError(
                                                                                false
                                                                            )
                                                                        },
                                                                        4000
                                                                    )
                                                            }
                                                        }}
                                                    />
                                                    {descriptionError && (
                                                        <div className='p-2 text-red-500'>
                                                            Must be 200
                                                            characters or less
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='w-full flex flex-col mt-1'>
                                                    <label className='mb-2 dark:text-gray-300'>
                                                        Image:
                                                    </label>
                                                    <div className='w-auto h-auto max-h-40 overflow-hidden rounded-lg mb-4'>
                                                        {image && (
                                                            <img
                                                                src={image}
                                                                className='h-full w-full object-cover'
                                                            />
                                                        )}
                                                    </div>
                                                    <input
                                                        type='file'
                                                        name='name'
                                                        className='dark:text-gray-300'
                                                        onChange={(e) => {
                                                            setCurrentFile(
                                                                e.target
                                                                    .files[0]
                                                            )
                                                            setImage(
                                                                URL.createObjectURL(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            )
                                                        }}
                                                        // className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm'
                                                        // onChange={(e) => setEditValue(e.target.value)}
                                                    />
                                                </div>
                                                <div className='w-full px-4 py-3 sm:flex sm:flex-row-reverse sm:items-center'>
                                                    {!formLoad ? (
                                                        <button
                                                            type='submit'
                                                            className='w-full inline-flex justify-center rounded-md border dark:bg-gray-200 border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                                        >
                                                            {button}
                                                        </button>
                                                    ) : (
                                                        <SmallLoader />
                                                    )}
                                                    <button
                                                        className='mt-3 sm:mt-0  w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                                                        onClick={() =>
                                                            setOpenModal(false)
                                                        }
                                                    >
                                                        {button2}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
