import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer,
    userFollowReducer,
    listFollowersReducer,
    listFollowingReducer,
    userUnfollowReducer,
    listFollowersByIdReducer,
    listFollowingByIdReducer,
} from './reducers/userReducers'
import {
    listItemsReducer,
    listItemReducer,
    addListReducer,
    addItemReducer,
    updateListItemReducer,
    updateListReducer,
    deleteListItemReducer,
    deleteListReducer,
    bucketListsReducer,
    otherBucketListsReducer,
} from './reducers/listReducers'
import {
    addPostReducer,
    deletePostReducer,
    feedReducer,
    myPostsReducer,
    savedPostsReducer,
    otherPostsReducer,
} from './reducers/feedReducers'
import {
    addMessageReducer,
    chatClientReducer,
    chatsReducer,
    listMessagesReducer,
    currentChatReducer,
    latestMessagesReducer,
} from './reducers/chatReducers'
import getClient from './config/twilio'
import {
    createChannelDataReducer,
    createChannelMembersReducer,
    createMembershipReducer,
    createMessageReducer,
    createNetworkStatusReducer,
    createPresenceReducer,
    createSignalReducer,
    createUserDataReducer,
} from 'pubnub-redux'
// const pubnubConfig = Object.assign(
//     {},
//     {
//         restore: true,
//         heartbeatInterval: 0,
//     },
//     keyConfiguration
// )
// const pubnub = new Pubnub(pubnubConfig)

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userFollow: userFollowReducer,
    userUnfollow: userUnfollowReducer,
    listFollowers: listFollowersReducer,
    otherListFollowers: listFollowersByIdReducer,
    listFollowing: listFollowingReducer,
    otherListFollowing: listFollowingByIdReducer,
    bucketLists: bucketListsReducer,
    otherBucketLists: otherBucketListsReducer,
    listItems: listItemsReducer,
    listItem: listItemReducer,
    addList: addListReducer,
    addItem: addItemReducer,
    updateListItem: updateListItemReducer,
    updateList: updateListReducer,
    deleteListItem: deleteListItemReducer,
    chatClient: chatClientReducer,
    feed: feedReducer,
    savedPosts: savedPostsReducer,
    addPost: addPostReducer,
    deletePost: deletePostReducer,
    myPosts: myPostsReducer,
    otherPosts: otherPostsReducer,
    deleteList: deleteListReducer,
    augmentedChats: chatsReducer,
    currentChat: currentChatReducer,
    latestMessages: latestMessagesReducer,
    networkStatus: createNetworkStatusReducer(false),
    messages: createMessageReducer(),
    memberPresence: createPresenceReducer(),
    chatUser: createUserDataReducer(),
    channelData: createChannelDataReducer(),
    joinedChats: createMembershipReducer(),
    chatMembers: createChannelMembersReducer(),
    signals: createSignalReducer(),
})
// const getClientInfo = async () => {
// 	return await getClient(userInfo)
// }
const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}

const createReduxStore = (thunkArgument) => {
    const middleware = [thunk.withExtraArgument(thunkArgument)]
    return createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    )
}

export default createReduxStore
