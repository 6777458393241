export const BUCKETLISTS_REQUEST = 'BUCKETLISTS_REQUEST'
export const BUCKETLISTS_SUCCESS = 'BUCKETLISTS_SUCCESS'
export const BUCKETLISTS_FAIL = 'BUCKETLISTS_FAIL'
export const BUCKETLISTS_RESET = 'BUCKETLISTS_RESET'

export const OTHER_BUCKETLISTS_REQUEST = 'OTHER_BUCKETLISTS_REQUEST'
export const OTHER_BUCKETLISTS_SUCCESS = 'OTHER_BUCKETLISTS_SUCCESS'
export const OTHER_BUCKETLISTS_FAIL = 'OTHER_BUCKETLISTS_FAIL'
export const OTHER_BUCKETLISTS_RESET = 'OTHER_BUCKETLISTS_RESET'

export const ADD_LIST_REQUEST = 'ADD_LIST_REQUEST'
export const ADD_LIST_SUCCESS = 'ADD_LIST_SUCCESS'
export const ADD_LIST_FAIL = 'ADD_LIST_FAIL'
export const ADD_LIST_RESET = 'ADD_LIST_RESET'


export const LIST_ITEMS_REQUEST = 'LIST_ITEMS_REQUEST'
export const LIST_ITEMS_SUCCESS = 'LIST_ITEMS_SUCCESS'
export const LIST_ITEMS_FAIL = 'LIST_ITEMS_FAIL'

export const LIST_ITEM_REQUEST = 'LIST_ITEM_REQUEST'
export const LIST_ITEM_SUCCESS = 'LIST_ITEM_SUCCESS'
export const LIST_ITEM_FAIL = 'LIST_ITEM_FAIL'

export const ADD_LIST_ITEM_REQUEST = 'ADD_LIST_ITEM_REQUEST'
export const ADD_LIST_ITEM_SUCCESS = 'ADD_LIST_ITEM_SUCCESS'
export const ADD_LIST_ITEM_FAIL = 'ADD_LIST_ITEM_FAIL'
export const ADD_LIST_ITEM_RESET = 'ADD_LIST_ITEM_RESET'

export const UPDATE_LIST_ITEM_REQUEST = 'UPDATE_LIST_ITEM_REQUEST'
export const UPDATE_LIST_ITEM_SUCCESS = 'UPDATE_LIST_ITEM_SUCCESS'
export const UPDATE_LIST_ITEM_FAIL = 'UPDATE_LIST_ITEM_FAIL'
export const UPDATE_LIST_ITEM_RESET = 'UPDATE_LIST_ITEM_RESET'

export const UPDATE_LIST_REQUEST = 'UPDATE_LIST_REQUEST'
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS'
export const UPDATE_LIST_FAIL = 'UPDATE_LIST_FAIL'
export const UPDATE_LIST_RESET = 'UPDATE_LIST_RESET'

export const DELETE_LIST_REQUEST = 'DELETE_LIST_REQUEST'
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS'
export const DELETE_LIST_FAIL = 'DELETE_LIST_FAIL'
export const DELETE_LIST_RESET = 'DELETE_LIST_RESET'

export const DELETE_LIST_ITEM_REQUEST = 'DELETE_LIST_ITEM_REQUEST'
export const DELETE_LIST_ITEM_SUCCESS = 'DELETE_LIST_ITEM_SUCCESS'
export const DELETE_LIST_ITEM_FAIL = 'DELETE_LIST_ITEM_FAIL'
export const DELETE_LIST_ITEM_RESET = 'DELETE_LIST_ITEM_RESET'
