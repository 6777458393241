import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Fade } from 'react-reveal'
import Nav from '../components/Nav'
import Footer from '../components/common/Footer'
// import JetlystLoop from '../assets/images/jetlyst1-loop.svg'
import JetlystLoop from '../assets/images/loop2.svg'
import Newsletter from '../components/Newsletter'
import HowItWorks from '../components/homepage/HowItWorks'
import HowItWorks2 from '../components/homepage/HowItWorks2'
import Testimonials from '../components/homepage/Testimonials'
import Table from '../components/homepage/Table'
import Meta from '../components/common/Meta'

const HomeScreen = () => {
    useEffect(() => {
        const main = document.getElementsByTagName('body')[0]
        if (main) {
            main.classList.add('no-scrollbar')
        }
        return () => {
            if (main && main.classList.contains('no-scrollbar')) {
                main.classList.remove('no-scrollbar')
            }
        }
    }, [])
    const learnMore = () => {
        const title = document.getElementById('learn-more')
        if (title) {
            title.scrollIntoView({
                behavior: 'smooth',
                // block: 'end',
            })
        }
    }
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta />

            {/* <Nav /> */}

            <div className='dark:bg-gray-800 flex flex-col h-full items-center justify-center w-full'>
                <div className='h-screen flex flex-col h-full items-center w-full'>
                    <img
                        src='/images/homebg.jpeg'
                        className='relative top-0 h-screen -mt-14 md:-mt-20 w-screen object-cover rounded-b-xl shadow-2xl'
                        alt='Hero Section'
                    />
                    {/* <svg
					className='hidden lg:block right-1/2 absolute inset-y-0 h-full w-48 text-white dark:text-gray-800 transform translate-x-24'
					fill='currentColor'
					viewBox='0 0 100 100'
					preserveAspectRatio='none'
					aria-hidden='true'
				>
					<polygon points='50,0 100,0 50,100 0,100' />
				</svg> */}
                    <div className='absolute z-10 flex flex-wrap items-center w-full md:w-11-12 h-full px-2 md:px-12'>
                        <div className='w-full md:w-1/2 px-4 mb-6 md:mb-0'>
                            <h2 className='mt-8 mb-6 lg:mb-12 text-4xl lg:text-5xl font-black text-white'>
                                We help you manage your bucket list ideas.
                            </h2>
                            <div className='max-w-lg mb-6 lg:mb-12'>
                                <p className='text-xl text-gray-200 peace'>
                                    Create bucket lists. Tick off items from
                                    your lists. Share your journey and watch as
                                    others tackle their lists. Grab a hold of
                                    your dreams and live with no regrets
                                    <Fade className='emoji' right>
                                        <i className='fas fa-hand-peace ml-2'></i>
                                    </Fade>
                                </p>
                            </div>
                            <div className='flex flex-wrap'>
                                <Link
                                    className='inline-block px-6 py-4 mb-3 mr-4 text-sm font-semibold leading-normal bg-blue-500 hover:bg-blue-400 text-white rounded-lg transition duration-200'
                                    to='/signup'
                                >
                                    Let's do this!
                                </Link>
                                <button
                                    className='inline-block px-6 py-4 mb-3 text-sm font-semibold leading-normal text-white hover:text-gray-800 hover:bg-white transition rounded-lg border'
                                    onClick={learnMore}
                                >
                                    Learn More
                                </button>
                            </div>
                        </div>
                        <div className='relative w-full md:h-full md:w-1/2 px-4'>
                            <img
                                className='relative h-full'
                                src={JetlystLoop}
                                alt='logo'
                            />
                        </div>
                        {/* <img
                            className='h-1/6 max-h-1/2 w-full md:w-2/3 lg:w-1/2  z-20 object-contain'
                            src={JetlystLoop}
                            alt='logo'
                        /> */}

                        {/* <div className='w-full flex flex-col justify-self-stretch -mt-16 md:-mt-20 mb-8 md:mx-auto px-4'>
                            <div className='text-center z-20 w-full space-y-6'>
                               
                                <svg className='name' viewbox='0 0 1802 362'>
                                    <text
                                        x='50%'
                                        y='50%'
                                        dy='.35em'
                                        text-anchor='middle'
                                    >
                                        For your bucketlists
                                    </text>
                                </svg>
                                
                                <div className='mt-5 w-full flex justify-center z-20'>
                                    <div className='rounded-md shadow'>
                                        <Link
                                            to='#'
                                            href='#'
                                            className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10'
                                        >
                                            Learn More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <h1
                    id='learn-more'
                    className='font-black pb-0 pt-16 md:py-16 text-4xl md:text-5xl dark:text-white text-center px-4'
                >
                    How does Jetlyst work?
                </h1>
                <section className='relative py-14 md:py-20'>
                    <div className='relative container px-4 mx-auto'>
                        <div className='flex flex-wrap items-center -mx-4'>
                            <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                                <div className='max-w-lg flex flex-col items-center md:items-start'>
                                    <h2 className='mt-8 mb-6 lg:mb-10 lg:pr-8 text-3xl md:text-4xl font-semibold mb-8 dark:text-white text-center md:text-left'>
                                        Create your profile
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center md:text-left'>
                                        Head over to the sign up page and give
                                        us your details which we will then use
                                        to create your account. During the
                                        creation of your account we will assign
                                        you a random avatar to go with your
                                        profile which you will be able to
                                        update.
                                    </p>
                                    <Link
                                        className='inline-block px-6 py-4 mb-3 mr-4 text-sm font-semibold leading-normal bg-blue-500 hover:bg-blue-400 text-white rounded-lg transition duration-200'
                                        to='/signup'
                                    >
                                        Sign Up!
                                    </Link>
                                </div>
                            </div>
                            <div className='relative w-full lg:w-1/2 px-4'>
                                <img
                                    className='h-full w-full rounded-xl object-cover'
                                    src='images/illustrations/createProfile.svg'
                                    alt=''
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='relative py-20'>
                    <div className='relative container px-4 mx-auto'>
                        <div className='flex flex-wrap flex-row-reverse items-center -mx-4'>
                            <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                                <div className='max-w-lg flex flex-col items-center md:items-start'>
                                    <h2 className='mt-8 mb-6 lg:mb-10 lg:pr-8 text-3xl md:text-4xl font-semibold mb-8 dark:text-white text-center md:text-left'>
                                        Create bucket lists
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center md:text-left'>
                                        Once you have signed up you can now
                                        create various bucketlists. For example,
                                        Travel bucket list, for all the places
                                        you would like to visit, Extreme sport
                                        bucket list, for the wild activities you
                                        have your eyes set on and so forth.
                                    </p>
                                    <p className='text-xl text-gray-500 dark:text-gray-400 text-center md:text-left'>
                                        After creating a bucket list you can add
                                        list items and update the banner image
                                        for the specific bucekt list to fit the
                                        theme of the list. You can also download
                                        PDFs of your bucket lists as a backup.
                                    </p>
                                </div>
                            </div>
                            <div className='relative w-full lg:w-1/2 px-4'>
                                <img
                                    className='h-full w-full rounded-xl object-cover'
                                    src='images/illustrations/createLists.svg'
                                    alt=''
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='relative py-14 md:py-20'>
                    <div className='relative container px-4 mx-auto'>
                        <div className='flex flex-wrap items-center -mx-4'>
                            <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                                <div className='max-w-lg flex flex-col items-center md:items-start'>
                                    <h2 className='mt-8 mb-6 lg:mb-10 lg:pr-8 text-3xl md:text-4xl font-semibold mb-8 dark:text-white text-center md:text-left'>
                                        Update bucket list items
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center md:text-left'>
                                        Once you have completed an item on your
                                        bucket list, go ahead and tick it off
                                        your list. Keep track of your journey
                                        and how far you have come. You can also
                                        share your progress with friends.
                                    </p>
                                </div>
                            </div>
                            <div className='relative w-full lg:w-1/2 px-4'>
                                <img
                                    className='h-full w-full rounded-xl object-cover'
                                    src='images/illustrations/checkItems.svg'
                                    alt=''
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <h1 className='font-black text-4xl md:text-5xl dark:text-white text-center mt-10 px-4'>
                    What else can I do on Jetlyst?
                </h1>
                <section className='relative py-14 md:py-20'>
                    <div className='relative container px-4 mx-auto'>
                        <div className='flex flex-wrap items-center lg:items-start -mx-4 mb-12'>
                            <div className='w-full md:w-1/4 px-3 flex flex-col items-center py-2'>
                                <div>
                                    <img
                                        className='h-36 w-auto rounded-xl object-cover'
                                        src='images/illustrations/follow.svg'
                                        alt=''
                                    />
                                </div>
                                <div className='max-w-lg flex flex-col items-center'>
                                    <h2 className='mt-8 mb-6 lg:pr-8 text-2xl font-semibold mb-8 dark:text-white text-center'>
                                        Follow other users
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center'>
                                        Follow your friends and others to follow
                                        their journey. See when they post new
                                        content on your feed and interact with
                                        them.
                                    </p>
                                </div>
                            </div>
                            <div className='w-full md:w-1/4 px-3 flex flex-col items-center py-2'>
                                <div>
                                    <img
                                        className='h-36 w-auto rounded-xl object-cover'
                                        src='images/illustrations/newPost.svg'
                                        alt=''
                                    />
                                </div>
                                <div className='max-w-lg flex flex-col items-center'>
                                    <h2 className='mt-8 mb-6 lg:pr-8 text-2xl font-semibold mb-8 dark:text-white text-center'>
                                        Post updates
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center'>
                                        You can create feed posts that will be
                                        seen by your followers. Post a video or
                                        picture related to or of you completing
                                        a bucket list item.
                                    </p>
                                </div>
                            </div>
                            <div className='w-full md:w-1/4 px-3 flex flex-col items-center py-2'>
                                <div>
                                    <img
                                        className='h-36 w-auto rounded-xl object-cover'
                                        src='images/illustrations/text.svg'
                                        alt=''
                                    />
                                </div>
                                <div className='max-w-lg flex flex-col items-center'>
                                    <h2 className='mt-8 mb-6 lg:pr-8 text-2xl font-semibold mb-8 dark:text-white text-center'>
                                        Chat with friends
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center'>
                                        Chat with friends about your bucketlist
                                        ideas or anything else through private
                                        1:1 chats or group chats.
                                    </p>
                                </div>
                            </div>
                            <div className='w-full md:w-1/4 px-3 flex flex-col items-center py-2'>
                                <div>
                                    <img
                                        className='h-36 w-auto rounded-xl object-cover'
                                        src='images/illustrations/explore.svg'
                                        alt=''
                                    />
                                </div>
                                <div className='max-w-lg flex flex-col items-center'>
                                    <h2 className='mt-8 mb-6 lg:pr-8 text-2xl font-semibold mb-8 dark:text-white text-center'>
                                        Explore
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400 text-center'>
                                        Discover users who post about topics
                                        that you are interested in and follow
                                        them or just browse through different
                                        posts from other users.
                                    </p>
                                </div>
                            </div>

                            {/* <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                                <div className='max-w-lg flex flex-col items-center md:items-start'>
                                    <h2 className='mt-8 mb-6 lg:mb-10 lg:pr-8 text-4xl font-semibold mb-8 dark:text-white'>
                                        Update bucket list items
                                    </h2>
                                    <p className='text-xl text-gray-500 mb-8 dark:text-gray-400'>
                                        Once you have completed an item on your
                                        bucket list, go ahead and tick it off
                                        your list. Keep track of your journey
                                        and how far you have come. You can also
                                        share your progress with friends
                                    </p>
                                </div>
                            </div>
                            <div className='relative w-full lg:w-1/2 px-4'>
                                <img
                                    className='h-full w-full rounded-xl object-cover'
                                    src='images/illustrations/checkItems.svg'
                                    alt=''
                                />
                            </div> */}
                        </div>
                    </div>
                </section>
                <HowItWorks2 />
                {/* <Newsletter /> */}
                {/* <Testimonials /> */}
                {/* <Table /> */}
            </div>
            {/* <Footer /> */}
        </motion.div>
    )
}

export default HomeScreen
