const avatarUrl = [
	'/images/profile/bighead.svg',
	'/images/profile/bighead1.svg',
	'/images/profile/bighead2.svg',
	'/images/profile/bighead3.svg',
	'/images/profile/bighead4.svg',
	'/images/profile/bighead5.svg',
	'/images/profile/bighead6.svg',
	'/images/profile/bighead7.svg',
	'/images/profile/bighead8.svg',
	'/images/profile/bighead9.svg',
	'/images/profile/bighead10.svg',
	'/images/profile/bighead11.svg',
	'/images/profile/bighead12.svg',
	'/images/profile/bighead13.svg',
	'/images/profile/bighead14.svg',
	'/images/profile/bighead15.svg',
	'/images/profile/bighead16.svg',
	'/images/profile/bighead17.svg',
	'/images/profile/bighead18.svg',
	'/images/profile/bighead19.svg',
	'/images/profile/bighead20.svg',
	'/images/profile/bighead21.svg',
	'/images/profile/bighead22.svg',
	'/images/profile/bighead23.svg',
	'/images/profile/bighead24.svg',
	'/images/profile/bighead25.svg',
	'/images/profile/bighead26.svg',
	'/images/profile/bighead27.svg',
	'/images/profile/bighead28.svg',
	'/images/profile/bighead29.svg',
	'/images/profile/bighead30.svg',
	'/images/profile/bighead31.svg',
	'/images/profile/bighead32.svg',
	'/images/profile/bighead33.svg',
	'/images/profile/bighead34.svg',
	'/images/profile/bighead35.svg',
	'/images/profile/bighead36.svg',
	'/images/profile/bighead37.svg',
	'/images/profile/bighead38.svg',
	'/images/profile/bighead39.svg',
	'/images/profile/bighead40.svg',
	'/images/profile/bighead41.svg',
	'/images/profile/bighead42.svg',
	'/images/profile/bighead43.svg',
	'/images/profile/bighead44.svg',
	'/images/profile/bighead45.svg',
	'/images/profile/bighead46.svg',
	'/images/profile/bighead47.svg',
	'/images/profile/bighead48.svg',
	'/images/profile/bighead49.svg',
	'/images/profile/bighead50.svg',
	'/images/profile/bighead51.svg',
	'/images/profile/bighead52.svg',
	'/images/profile/bighead53.svg',
	'/images/profile/bighead54.svg',
]


export default avatarUrl
