import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Picker from 'emoji-picker-react'

const NotFound = () => {
    const [chosenEmoji, setChosenEmoji] = useState(null)

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject)
    }
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <div className='w-full'>
                <div className='w-5/6 h-full pt-12 pl-12 flex items-center'>
                    <img
                        src='/images/404-1.png'
                        className='h-1/2 w-1/2 object-cover'
                    />

                    <div className='flex flex-col w-1/2 pl-8 items-start justify-center'>
                        <p className='dark:text-white text-5xl font-bold'>
                            Looks like you got lost. Go back, this page does not
                            exist.
                        </p>
                        <Link
                            className='px-12 py-4 text-lg bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-full transition duration-200'
                            to='/'
                        >
                            Start again
                        </Link>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default NotFound
