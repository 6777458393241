import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { register } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import avatarUrl from '../components/avatars'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import Meta from '../components/common/Meta'

const SignUpScreen = ({ location, history }) => {
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState(new Date())
    const [repeatPassword, setRepeatPassword] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const [showUsernameError, setShowUsernameError] = useState(false)

    const dispatch = useDispatch()
    const userRegister = useSelector((state) => state.userRegister)
    const range = {
        before: new Date(2013, 1, 1),
    }
    const { loading, error, userInfo } = userRegister

    const redirect = location.search
        ? location.search.split('=')[1]
        : '/bucketlists'

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [userInfo, history, redirect])
    const submitHandler = (e) => {
        e.preventDefault()
        if (
            !username ||
            !name ||
            !email ||
            !password ||
            !repeatPassword ||
            !dateOfBirth
        ) {
            setShowErrors(true)
        } else {
            setShowErrors(false)
            if (password === repeatPassword && !showUsernameError) {
                // 	setMessage('Passwords do not match')
                // } else {
                const randomIndex = Math.floor(Math.random() * avatarUrl.length)
                const imageUrl = avatarUrl[randomIndex]
                dispatch(
                    register(
                        name,
                        email,
                        password,
                        imageUrl,
                        username,
                        dateOfBirth
                    )
                )
            }
        }
    }

    const checkUsername = async (val) => {
        try {
            const { data } = await axios.post('/api/users/checkusername', {
                username: val,
            })
            if (data.valid) {
                setShowUsernameError(false)
            } else {
                setShowUsernameError(true)
            }
        } catch (error) {}
    }
    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 pt-14 md:pt-14'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Sign Up`} />

            <div className='dark:bg-gray-800 md:flex h-full'>
                <div className='md:w-2/3 max-w-7xl px-4 sm:mt-5 sm:px-4 lg:px-8 '>
                    <div className='flex flex-col h-full items-center justify-start py-2 2xl:py-12'>
                        <h2 className='text-6xl dark:text-gray-100 font-bold text-gray-800'>
                            Sign Up
                        </h2>
                        {error && (
                            <div className='p-4 z-20 bg-red-200 dark:text-gray-800 border-2 border-red-300 font-light mt-4 rounded-lg md:w-full lg:w-3/4 w-full'>
                                {error}
                            </div>
                        )}
                        {showUsernameError && (
                            <div className='p-4 z-20 bg-red-200 dark:text-gray-800 border-2 border-red-300 font-light mt-4 rounded-lg md:w-full lg:w-3/4 w-full'>
                                Username already exists
                            </div>
                        )}
                        {showErrors &&
                            (!username ||
                                !name ||
                                !email ||
                                !password ||
                                !repeatPassword ||
                                !dateOfBirth) && (
                                <div className='p-4 z-20 bg-red-200 dark:text-gray-800 border-2 border-red-300 font-light mt-4 rounded-lg md:w-full lg:w-3/4 w-full'>
                                    Please fill in all the required fields.
                                </div>
                            )}
                        {loading && <Loader />}
                        <div className='mt-4 md:h-auto md:w-full lg:w-3/4 z-20 w-full rounded-lg bg-white dark:bg-gray-800 px-2 py-3 shadow-xl'>
                            <form
                                onSubmit={submitHandler}
                                className='h-full w-full space-y-6 p-4'
                            >
                                <div className='relative flex flex-wrap mb-6 w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                            (!name && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='text'
                                        name='name'
                                        value={name}
                                        placeholder='e.g., Sam Dean'
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm flex'>
                                        Name
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-3 w-3 text-red-400'
                                            viewBox='0 0 20 20'
                                            fill='currentColor'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                    </span>
                                </div>
                                {/* <div className='flex flex-col w-full space-y-1'>
                                    <label className='text-gray-600 dark:text-gray-200'>
                                        Name:
                                    </label>
                                    <input
                                        type='text'
                                        name='name'
                                        value={name}
                                        placeholder='e.g., Sam Dean'
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        className={
                                            'appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 focus:z-10 sm:text-sm' +
                                            (showErrors && !name
                                                ? ' border-2 border-red-500'
                                                : ' border border-gray-300')
                                        }
                                    />
                                </div> */}
                                <div className='flex flex-col lg:flex-row w-full justify-between items-center space-y-6 lg:space-y-0'>
                                    <div className='relative flex flex-wrap w-full lg:w-2/3 lg:mr-4'>
                                        <input
                                            className={
                                                'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                                (!username && showErrors
                                                    ? ' border-2 border-red-700'
                                                    : ' border')
                                            }
                                            type='text'
                                            name='username'
                                            value={username}
                                            placeholder='e.g., sammy123'
                                            onChange={(e) => {
                                                setUsername(e.target.value)
                                                checkUsername(e.target.value)
                                            }}
                                        />
                                        <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm flex'>
                                            Username
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-3 w-3 text-red-400'
                                                viewBox='0 0 20 20'
                                                fill='currentColor'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                                    clipRule='evenodd'
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className='relative flex flex-wrap w-full lg:w-1/3'>
                                        <DayPickerInput
                                            modifiers={range}
                                            onDayChange={(date) =>
                                                setDateOfBirth(date)
                                            }
                                            component={(props) => (
                                                <input
                                                    {...props}
                                                    placeholder='e.g., 2000-8-2'
                                                    className={
                                                        'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                                        (showErrors &&
                                                        !dateOfBirth
                                                            ? ' border-2 border-red-500'
                                                            : ' border border-gray-300')
                                                    }
                                                />
                                            )}
                                        />
                                        <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                            Birthday <i>- optional</i>
                                        </span>
                                    </div>
                                </div>

                                {/* <div className='flex flex-col w-full space-y-1'>
									<label className='text-gray-600 dark:text-gray-200'>
										Last Name:
									</label>
									<input
										type='text'
										name='lname'
										value={lname}
										onChange={(e) => setLname(e.target.value)}
										className={'appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 focus:z-10 sm:text-sm' + ((showErrors&&) ? ' border-2 border-red-500':' border border-gray-300')}
									/>
								</div> */}
                                <div className='relative flex flex-wrap w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                            (!email && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='email'
                                        name='email'
                                        value={email}
                                        placeholder='e.g., you@example.com'
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm flex'>
                                        Email Address
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-3 w-3 text-red-400'
                                            viewBox='0 0 20 20'
                                            fill='currentColor'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                    </span>
                                </div>

                                <div className='relative flex flex-wrap w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                            (!password && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='password'
                                        name='password'
                                        value={password}
                                        placeholder='e.g., ***********'
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm flex'>
                                        Password
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-3 w-3 text-red-400'
                                            viewBox='0 0 20 20'
                                            fill='currentColor'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                    </span>
                                </div>

                                <div className='relative flex flex-wrap w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                            (!repeatPassword && showErrors
                                                ? ' border-2 border-red-700'
                                                : ' border')
                                        }
                                        type='password'
                                        name='repeatPassword'
                                        value={repeatPassword}
                                        placeholder='e.g., ***********'
                                        onChange={(e) => {
                                            setRepeatPassword(e.target.value)
                                        }}
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm flex'>
                                        Re-enter Password
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-3 w-3 text-red-400'
                                            viewBox='0 0 20 20'
                                            fill='currentColor'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                    </span>
                                    {repeatPassword !== password && (
                                        <p className='py-2 px-2 text-red-400 rounded-lg bg-gray-200 bg-red-200 dark:text-gray-800'>
                                            Passwords must match
                                        </p>
                                    )}
                                </div>
                                <div className='flex space-x-1 mb-2 dark:text-white'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-3 w-3 text-red-400'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    <span className='text-sm'>
                                        Required field
                                    </span>
                                </div>

                                {/* <label className='inline-flex mb-10 text-left'>
                                    <input
                                        className='mr-2'
                                        type='checkbox'
                                        name='terms'
                                        defaultValue={1}
                                    />
                                    <span className='-mt-1 inline-block text-sm text-gray-500 dark:text-gray-400'>
                                        By signing up, you agree to our{' '}
                                        <a
                                            className='text-blue-500 hover:underline'
                                            href='#'
                                        >
                                            Terms, Data Policy
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            className='text-blue-500 hover:underline'
                                            href='#'
                                        >
                                            Cookies Policy
                                        </a>
                                        .
                                    </span>
                                </label> */}
                                <div className='flex flex-col w-full space-y-1'>
                                    <button
                                        type='submit'
                                        class='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800'
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        </div>
                        <p className='text-gray-800 dark:text-white mt-5 z-20'>
                            Already have an account?{' '}
                            <Link
                                to='/login'
                                className='ml-1 text-blue-400 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-600'
                            >
                                Login
                            </Link>
                        </p>
                    </div>
                </div>
                <div className='md:w-2/3 z-10 md:-ml-8 lg:-ml-32 xl:-ml-48 min-h-full max-h-full rounded-bl-xl overflow-hidden'>
                    <img
                        // src='/images/illus4.png'
                        src='https://images.unsplash.com/photo-1489058535093-8f530d789c3b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
                        alt='Island'
                        className='h-full w-full object-cover'
                    />
                </div>
            </div>
        </motion.div>
    )
}

export default SignUpScreen
