import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { motion } from 'framer-motion'
import Settings from '../components/Settings'
import Message from '../components/Message'
import Loader from '../components/Loader'
import SmallLoader from '../components/SmallLoader'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { updateUserProfile } from '../actions/userActions'
import avatarUrl from '../components/avatars'
import Meta from '../components/common/Meta'

const AccountSettings = ({ history }) => {
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [location, setLocation] = useState('')
    const [description, setDescription] = useState('')
    const [profilePic, setProfilePic] = useState('')
    const [oldProfilePic, setOldProfilePic] = useState('')
    const [newProfileFile, setNewProfileFile] = useState(null)
    const [oldBannerImage, setOldBannerImage] = useState('')
    const [newBannerFile, setNewBannerFile] = useState(null)
    const [bannerImage, setBannerImage] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState(new Date())
    const [repeatPassword, setRepeatPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const [formLoad, setFormLoad] = useState(false)
    const [showUsernameError, setShowUsernameError] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)

    const range = {
        before: new Date(2013, 1, 1),
    }
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { loading, success, userInfo: updatedUserInfo } = userUpdateProfile

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            setName(userInfo.name)
            setUsername(userInfo.username)
            setDescription(userInfo.description)
            setDateOfBirth(userInfo.dateOfBirth)
            setEmail(userInfo.email)
            setProfilePic(userInfo.imageUrl)
            setBannerImage(userInfo.bannerImage)
            setLocation(userInfo.location)
            setOldBannerImage(userInfo.bannerImage)
        }
    }, [userInfo])

    useEffect(() => {
        if (updatedUserInfo) {
            setFormLoad(false)
            setName(updatedUserInfo.name)
            setUsername(updatedUserInfo.username)
            setDescription(updatedUserInfo.description)
            setDateOfBirth(updatedUserInfo.dateOfBirth)
            setEmail(updatedUserInfo.email)
            setProfilePic(updatedUserInfo.imageUrl)
            setBannerImage(updatedUserInfo.bannerImage)
            setOldBannerImage(updatedUserInfo.bannerImage)
            setLocation(updatedUserInfo.location)
            if (password) {
                setPassword('')
                setConfirmPassword('')
                setPasswordChanged(true)
                setTimeout(() => {
                    setPasswordChanged(false)
                }, 5000)
            }
        }
    }, [success])

    const submitHandler = async (e) => {
        e.preventDefault()
        console.log('in here')
        if (!name || !email || !username || password !== confirmPassword) {
            setShowErrors(true)
            if (password !== confirmPassword) {
                setShowPasswordError(true)
            }
        } else {
            setFormLoad(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }
            let newProfile, newBanner
            if (newProfileFile) {
                const { data } = await axios.get(
                    `/api/media/s3Url?type='image'`,
                    config
                )
                console.log(data.url)
                // const formData = new FormData()
                // formData.append("image", currentFile)
                await axios.put(data.url, newProfileFile, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                newProfile = data.url.split('?')[0]
                setNewProfileFile(null)
            }

            if (newBannerFile) {
                const { data } = await axios.get(
                    `/api/media/s3Url?type='image'`,
                    config
                )
                console.log(data.url)
                // const formData = new FormData()
                // formData.append("image", currentFile)
                await axios.put(data.url, newBannerFile, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                newBanner = data.url.split('?')[0]
                setNewBannerFile(null)
            }

            if (
                (oldBannerImage !== '/images/anime1.jpg' &&
                    (oldBannerImage !== bannerImage ||
                        (newBanner && oldBannerImage !== newBanner))) ||
                (oldProfilePic &&
                    !oldProfilePic.includes('/images/profile/bighead'))
            ) {
                let mediaKeys = []
                mediaKeys = [
                    oldBannerImage.substring(
                        oldBannerImage.lastIndexOf('/') + 1
                    ),
                ]

                if (
                    oldProfilePic &&
                    !oldProfilePic.includes('/images/profile/bighead')
                ) {
                    mediaKeys = [
                        ...mediaKeys,
                        oldProfilePic.substring(
                            oldProfilePic.lastIndexOf('/') + 1
                        ),
                    ]
                }

                await axios.delete(
                    `/api/media/objects?keys=${mediaKeys}`,
                    config
                )
            }
            dispatch(
                updateUserProfile({
                    name,
                    email,
                    username,
                    imageUrl: newProfile ? newProfile : profilePic,
                    bannerImage: newBanner ? newBanner : bannerImage,
                    location,
                    description,
                    password,
                })
            )
        }
    }

    const checkUsername = async (val) => {
        try {
            if (val !== userInfo.username) {
                const { data } = await axios.post('/api/users/checkusername', {
                    username: val,
                })
                if (data.valid) {
                    setShowUsernameError(false)
                } else {
                    setShowUsernameError(true)
                }
            }
        } catch (error) {}
    }
    const checkEmail = async (val) => {
        try {
            if (val !== userInfo.email) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const { data } = await axios.post(
                    '/api/users/checkemail',
                    { email: val },
                    config
                )
                if (data.valid) {
                    setShowEmailError(false)
                } else {
                    setShowEmailError(true)
                }
            }
        } catch (error) {}
    }

    const shuffleBigHead = () => {
        const randomIndex = Math.floor(Math.random() * avatarUrl.length)
        const imageUrl = avatarUrl[randomIndex]
        setProfilePic(imageUrl)
    }

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Settings`} />

            <form
                onSubmit={submitHandler}
                className='h-full w-full flex flex-col px-6 md:px-12 lg:px-20 bg-transparent'
            >
                <h2 className='text-4xl md:text-5xl dark:text-white mb-2 font-black mx-auto'>
                    Settings
                </h2>
                <div className='flex flex-col items-center mt-14 w-full'>
                    <h2 className='text-2xl text-gray-700 dark:text-gray-200 mb-2 font-bold lg:w-3/4 xl:w-2/3'>
                        Profile Information
                    </h2>
                    <div className='flex flex-col w-full lg:flex-row lg:justify-between items-center lg:w-3/4 xl:w-2/3'>
                        <div className='flex flex-col items-center space-y-6 justify-between mb-8'>
                            <div className='h-40 w-40 rounded-full overflow-hidden'>
                                <img
                                    src={profilePic}
                                    className='object-cover h-full w-full'
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor='new-profile-pic'
                                    className='text-blue-600 hover:text-blue-700 cursor-pointer font-bold'
                                >
                                    Change Profile Picture
                                </label>
                                <button
                                    onClick={shuffleBigHead}
                                    className='text-blue-600 hover:text-blue-700 cursor-pointer font-bold ml-5'
                                >
                                    <i className='fas fa-random mr-1'></i>
                                    Avatar
                                </button>
                            </div>

                            <input
                                type='file'
                                name='name'
                                id='new-profile-pic'
                                onChange={(e) => {
                                    setNewProfileFile(e.target.files[0])
                                    setOldProfilePic(profilePic)
                                    setProfilePic(
                                        URL.createObjectURL(e.target.files[0])
                                    )
                                }}
                                className='hidden'
                            />
                        </div>
                        <div className='flex flex-col w-full items-center space-y-6 md:w-2/3 justify-between mb-8'>
                            <div className='relative h-40 w-full z-20 rounded-xl overflow-hidden'>
                                <img
                                    src={bannerImage}
                                    className='w-full h-full object-cover '
                                />
                                {bannerImage !== '/images/anime1.jpg' && (
                                    <button
                                        onClick={() => {
                                            setBannerImage('/images/anime1.jpg')
                                        }}
                                        className='absolute top-0 right-0 font-bold text-red-500 dark:text-red-500 hover:text-red-400 dark:hover:text-red-500 text-xs'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-6 w-6'
                                            viewBox='0 0 20 20'
                                            fill='currentColor'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                            <label
                                htmlFor='new-banner-image'
                                className='text-blue-600 hover:text-blue-700 cursor-pointer font-bold'
                            >
                                Change Banner Picture
                            </label>
                            <input
                                type='file'
                                name='name'
                                id='new-banner-image'
                                onChange={(e) => {
                                    setNewBannerFile(e.target.files[0])
                                    setBannerImage(
                                        URL.createObjectURL(e.target.files[0])
                                    )
                                }}
                                className='hidden'
                            />
                        </div>
                    </div>

                    <div className='w-full lg:w-3/4 xl:w-2/3 flex flex-col items-center space-y-8'>
                        <div className='flex flex-col md:flex-row items-center w-full md:space-x-6 lg:space-x-10 xl:space-x-14 justify-between pt-3'>
                            <div className='relative flex flex-wrap w-full mb-6'>
                                <input
                                    className={
                                        'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                        (!name && showErrors
                                            ? ' border-2 border-red-500'
                                            : ' border border-gray-300')
                                    }
                                    type='text'
                                    value={name}
                                    placeholder='e.g., Sam Dean'
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm flex'>
                                    Name
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-3 w-3 text-red-400'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                </span>
                            </div>

                            <div className='relative flex flex-wrap w-full mb-6'>
                                <input
                                    className={
                                        'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm'
                                    }
                                    type='text'
                                    value={location}
                                    placeholder='e.g., Peterborough, Ontario'
                                    onChange={(e) =>
                                        setLocation(e.target.value)
                                    }
                                />
                                <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm'>
                                    Location <i>- optional</i>
                                </span>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row items-center w-full md:space-x-6 lg:space-x-10 xl:space-x-14 justify-between'>
                            <div className='relative flex flex-wrap w-full'>
                                <textarea
                                    className={
                                        'relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm rounded dark:bg-gray-50 resize-none  border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400'
                                    }
                                    type='text'
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    cols={30}
                                    rows={10}
                                    placeholder='About me...'
                                />
                                <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm'>
                                    About <i>- optional</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-col items-center mt-8'>
                    <h2 className='text-2xl text-gray-700 dark:text-gray-200 mb-5 font-bold lg:w-3/4 xl:w-2/3'>
                        Account Information
                    </h2>
                    <div className='w-full lg:w-3/4 xl:w-2/3 flex flex-col items-center space-y-8'>
                        <div className='flex flex-col md:flex-row items-center w-full space-y-4 md:space-y-0 md:space-x-6 lg:space-x-10 xl:space-x-14 justify-between'>
                            <div className='relative flex flex-wrap w-full'>
                                <input
                                    className={
                                        'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                        (showErrors && !username
                                            ? ' border-2 border-red-500'
                                            : ' border border-gray-300')
                                    }
                                    type='text'
                                    name='name'
                                    value={username}
                                    placeholder='e.g., Sam Dean'
                                    onChange={(e) => {
                                        setUsername(e.target.value)
                                        checkUsername(e.target.value)
                                    }}
                                />
                                <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm flex'>
                                    Username{' '}
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-3 w-3 text-red-400'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    {showUsernameError && (
                                        <span className='text-red-500 font-bold ml-2'>
                                            Username already exists
                                        </span>
                                    )}
                                </span>
                            </div>

                            <div className='relative flex flex-wrap w-full'>
                                <input
                                    className={
                                        'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                        (showErrors && !email
                                            ? ' border-2 border-red-500'
                                            : ' border border-gray-300')
                                    }
                                    type='email'
                                    name='email'
                                    value={email}
                                    placeholder='e.g., you@example.com'
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        checkEmail(e.target.value)
                                    }}
                                />
                                <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm flex'>
                                    Email Address
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-3 w-3 text-red-400'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    {showEmailError && (
                                        <span className='text-red-500 font-bold ml-2'>
                                            Email is already registered
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col items-center mt-8'>
                        <h2 className='text-2xl text-gray-700 dark:text-gray-200 mb-5 font-bold lg:w-3/4 xl:w-2/3'>
                            Update Password
                            {showPasswordError && (
                                <span className='text-red-500 font-bold ml-2 text-lg'>
                                    Passwords must match
                                </span>
                            )}
                            {passwordChanged && (
                                <span className='text-green-500 font-bold ml-2 text-lg'>
                                    Password was changed successfully
                                </span>
                            )}
                        </h2>
                        <div className='w-full lg:w-3/4 xl:w-2/3 flex flex-col items-center space-y-8'>
                            <div className='flex flex-col md:flex-row items-center w-full space-y-4 md:space-y-0 md:space-x-6 lg:space-x-10 xl:space-x-14 justify-between'>
                                <div className='relative flex flex-wrap w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm border border-gray-300'
                                        }
                                        type='password'
                                        name='password'
                                        value={password}
                                        placeholder='e.g., *********'
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm flex'>
                                        New Password{' '}
                                    </span>
                                </div>

                                <div className='relative flex flex-wrap w-full'>
                                    <input
                                        className={
                                            'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                            (showErrors && !confirmPassword
                                                ? ' border-2 border-red-500'
                                                : ' border border-gray-300')
                                        }
                                        type='password'
                                        name='confirmPassword'
                                        value={confirmPassword}
                                        placeholder='e.g., *********'
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value)
                                        }}
                                    />
                                    <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded dark:text-white text-sm flex'>
                                        Confirm Password
                                    </span>
                                </div>
                            </div>
                            <div className='flex w-full flex-col-reverse space-y-2 md:space-y-0 md:flex-row items-center md:justify-end mt-14'>
                                <button
                                    className='mt-3 sm:mt-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                                    onClick={history.goBack}
                                >
                                    Cancel
                                </button>
                                {!formLoad ? (
                                    <button
                                        type='submit'
                                        className='w-full inline-flex justify-center rounded-md dark:bg-blue-600 border-gray-300 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-500 dark:hover:bg-blue-500 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    >
                                        Save Changes
                                    </button>
                                ) : (
                                    <SmallLoader />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </motion.div>
    )
}

export default AccountSettings
