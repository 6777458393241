import React from 'react'
import LoopLogo from '../assets/images/logo4-loop.svg'
import { Link } from 'react-router-dom'

const Logo = () => {
    return (
        <Link to='/' className='px-1 md:px-3 rounded-md z-30'>
            {/* <span className='text-3xl text-white hover:text-gray-500 dark:text-blue-800 dark:hover:text-blue-600 cursor-default font-black'>
				B
			</span> */}
            <img
                src={LoopLogo}
                alt='logo'
                className='h-11 w-11 bg-transparent'
            />
        </Link>
    )
}

export default Logo
