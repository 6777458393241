import React from 'react'
import { Link } from 'react-router-dom'
import Follow from '../../assets/images/addUser.svg'
import Unfollow from '../../assets/images/removeUser.svg'
import { motion } from 'framer-motion'

const SmallUserBox = ({
    imageUrl,
    name,
    _id,
    handleClick,
    following = null,
    other,
}) => {
    const link = `/${name}`

    return (
        <div className='rounded-lg px-4 py-2 md:p-0 w-full md:h-48 md:w-48 bg-none bg-gray-200 md:bg-gray-200 dark:bg-gray-700 flex flex-row-reverse justify-between md:flex-col md:justify-start items-center mx-auto md:mx-0 md:mr-5 mb-2 md:mb-5 overflow-hidden'>
            {following !== null ? (
                <div className='w-auto md:w-full flex justify-end'>
                    <div
                        className={
                            'h-7 w-7 md:h-9 md:w-9 p-1 md:p-2 rounded flex justify-center cursor-pointer items-center' +
                            (!following ? ' bg-green-400' : ' bg-red-400')
                        }
                        onClick={() => {
                            if (following) handleClick(_id, 'unfollow')
                            else handleClick(_id, 'follow')
                        }}
                    >
                        {!following ? (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-5 w-5 text-gray-800'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                            >
                                <path d='M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z' />
                            </svg>
                        ) : (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-5 w-5 text-gray-800'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                            >
                                <path d='M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z' />
                            </svg>
                        )}
                    </div>
                </div>
            ) : (
                <div className='h-7 w-7'></div>
            )}
            <Link
                to={link}
                className={
                    'flex flex-row justify-between md:flex-col md:justify-start items-center' +
                    (following !== null ? ' mt-0 md:-mt-9' : ' ')
                }
            >
                <div className='h-16 w-16 md:h-24 md:w-24 rounded-full border border-gray-800 dark:border-gray-300 mt-0 md:mt-4 overflow-hidden'>
                    <img
                        src={imageUrl}
                        className='object-cover h-full w-full'
                    />
                </div>
                <div className='w-auto md:w-full px-4 md:px-0 py-4 md:py-8 dark:text-gray-200 flex justify-center items-center'>
                    <p>{name}</p>
                </div>
            </Link>
        </div>
    )
}

export default SmallUserBox
