import axios from 'axios'
import {
    BUCKETLISTS_REQUEST,
    BUCKETLISTS_SUCCESS,
    BUCKETLISTS_FAIL,
    ADD_LIST_ITEM_REQUEST,
    ADD_LIST_ITEM_SUCCESS,
    ADD_LIST_ITEM_FAIL,
    ADD_LIST_REQUEST,
    ADD_LIST_SUCCESS,
    ADD_LIST_FAIL,
    UPDATE_LIST_ITEM_REQUEST,
    UPDATE_LIST_ITEM_SUCCESS,
    UPDATE_LIST_ITEM_FAIL,
    UPDATE_LIST_REQUEST,
    UPDATE_LIST_SUCCESS,
    UPDATE_LIST_FAIL,
    DELETE_LIST_REQUEST,
    DELETE_LIST_SUCCESS,
    DELETE_LIST_FAIL,
    DELETE_LIST_ITEM_REQUEST,
    DELETE_LIST_ITEM_SUCCESS,
    DELETE_LIST_ITEM_FAIL,
    LIST_ITEMS_REQUEST,
    LIST_ITEMS_SUCCESS,
    LIST_ITEMS_FAIL,
    LIST_ITEM_REQUEST,
    LIST_ITEM_SUCCESS,
    LIST_ITEM_FAIL,
    OTHER_BUCKETLISTS_REQUEST,
    OTHER_BUCKETLISTS_SUCCESS,
    OTHER_BUCKETLISTS_FAIL,
    UPDATE_LIST_RESET,
    ADD_LIST_RESET,
    DELETE_LIST_RESET,
} from '../constants/listConstants'

export const getLists = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: BUCKETLISTS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/lists`, config)

        dispatch({
            type: BUCKETLISTS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: BUCKETLISTS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getOtherLists = (userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OTHER_BUCKETLISTS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/lists/user/${userId}`, config)

        dispatch({
            type: OTHER_BUCKETLISTS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: OTHER_BUCKETLISTS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getListItems =
    (id, refresh = false) =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: LIST_ITEMS_REQUEST,
            })

            const {
                userLogin: { userInfo },
                bucketLists: { bucketlists },
            } = getState()

            let list = bucketlists.find((item) => item._id === id) || null
            if (bucketlists && list && !refresh) {
                dispatch({
                    type: LIST_ITEMS_SUCCESS,
                    payload: list,
                })
            } else {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }

                const { data } = await axios.get(`/api/lists/${id}`, config)

                dispatch({
                    type: LIST_ITEMS_SUCCESS,
                    payload: data,
                })
            }
        } catch (error) {
            dispatch({
                type: LIST_ITEMS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const addBucketlist = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post('/api/lists/', {}, config)

        dispatch({
            type: ADD_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ADD_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteBucketlist = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(`/api/lists/${id}`, config)

        dispatch({
            type: DELETE_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DELETE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateBucketList = (list) => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(`/api/lists/${list._id}`, list, config)

        dispatch({
            type: UPDATE_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: UPDATE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const addListItem = (listId, item) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_LIST_ITEM_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/lists/${listId}`, item, config)

        dispatch({
            type: ADD_LIST_ITEM_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ADD_LIST_ITEM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteListItem =
    (listId, itemId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: DELETE_LIST_ITEM_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.delete(
                `/api/lists/${listId}/items/${itemId}`,
                config
            )

            dispatch({
                type: DELETE_LIST_ITEM_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: DELETE_LIST_ITEM_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const getListItem = (listId, itemId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LIST_ITEM_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(
            `/api/lists/${listId}/items/${itemId}`,
            config
        )

        dispatch({
            type: LIST_ITEM_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LIST_ITEM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateListItem =
    (listId, itemId, item) => async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_LIST_ITEM_REQUEST,
            })

            const {
                userLogin: { userInfo },
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const { data } = await axios.put(
                `/api/lists/${listId}/items/${itemId}`,
                item,
                config
            )

            dispatch({
                type: UPDATE_LIST_ITEM_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: UPDATE_LIST_ITEM_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const resetListsOperations = () => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_LIST_RESET
    })
    dispatch({
        type: ADD_LIST_RESET
    })
    dispatch({
        type: DELETE_LIST_RESET
    })
}