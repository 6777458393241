import React from 'react'

const SmallPost = ({ post }) => {
    return (
        <div className='h-46 w-48 m-2'>
            <img
                src={post.detail.media[0]}
                alt='Post cover'
                className='h-48 w-full object-cover'
            />
        </div>
    )
}

export default SmallPost
