import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/common/Meta'

const LoginScreen = ({ history, location }) => {
    const [info, setInfo] = useState('')
    const [password, setPassword] = useState('')
    const [showErrors, setShowErrors] = useState('')

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, userInfo, error } = userLogin

    const redirect = location.search
        ? location.search.split('=')[1]
        : '/bucketlists'

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [userInfo, history, redirect])
    const submitHandler = (e) => {
        e.preventDefault()
        if (info && password) dispatch(login(info, password))
        else {
            setShowErrors(true)
        }
    }
    return (
        <motion.div
            className='dark:bg-gray-800 flex-1 pt-14 md:pt-14 relative'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Login`} />

            <div className='md:absolute bg-white dark:bg-gray-800 md:flex bottom-0 top-0 md:pt-14 w-full'>
                <div className='h-full md:w-2/3 max-w-7xl px-4 sm:mt-5 sm:px-4 lg:px-8 '>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className='flex flex-col h-full items-center w-full justify-start py-2 md:py-12'>
                            <h2 className='text-6xl dark:text-gray-100 font-bold text-gray-800'>
                                Login
                            </h2>
                            {error && (
                                <div className='p-4 bg-red-400 dark:text-white border-2 border-red-300 font-medium mt-4 rounded-lg md:w-1/3 w-full'>
                                    {error}
                                </div>
                            )}
                            <div className='mt-2 md:mt-4 h-auto w-full lg:w-3/4 z-20 rounded-lg bg-white dark:bg-gray-800 p-2 shadow-xl'>
                                <form
                                    onSubmit={submitHandler}
                                    className='h-full w-full space-y-10 p-4'
                                >
                                    <div className='relative flex flex-wrap mb-6 w-full'>
                                        <input
                                            className={
                                                'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                                (!info && showErrors
                                                    ? ' border-2 border-red-700'
                                                    : ' border')
                                            }
                                            type='text'
                                            value={info}
                                            placeholder='e.g., y@x.com or dave'
                                            onChange={(e) =>
                                                setInfo(e.target.value)
                                            }
                                        />
                                        <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                            Email Address/Username
                                        </span>
                                    </div>
                                    <div className='relative flex flex-wrap mb-6 w-full'>
                                        <input
                                            className={
                                                'relative mb-2 md:mb-0 w-full py-3 pl-4 w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-400 sm:text-sm' +
                                                (!password && showErrors
                                                    ? ' border-2 border-red-700'
                                                    : ' border')
                                            }
                                            type='password'
                                            value={password}
                                            placeholder='e.g., ***********'
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        <span className='absolute top-0 left-0 ml-4 -mt-3 px-2 py-0.5 inline-block bg-white dark:bg-gray-700 rounded text-gray-500 dark:text-white text-sm'>
                                            Password
                                        </span>
                                    </div>

                                    <div className='flex flex-col w-full space-y-1'>
                                        <button
                                            type='submit'
                                            className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800'
                                        >
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <p className='text-gray-800 dark:text-white mt-5'>
                                Don't have an account yet?{' '}
                                <Link
                                    to='/signup'
                                    className='ml-1 text-blue-400 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-600'
                                >
                                    Sign Up
                                </Link>
                            </p>
                        </div>
                    )}
                </div>
                <div className='md:w-2/3 z-10 md:-ml-8 lg:-ml-32 xl:-ml-48 min-h-full max-h-full rounded-bl-xl overflow-hidden'>
                    <img
                        // src='/images/illus4.png'
                        src='/images/sunset4.jpg'
                        alt='Island'
                        className='h-full w-full object-cover'
                    />
                </div>
            </div>
        </motion.div>
    )
}

export default LoginScreen
