import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
// import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import { Dialog } from '@headlessui/react'

const dogImg =
    'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'

const CropContainer = ({ handleDone, image }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage.url)
            handleDone(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    return (
        <div className='w-full'>
            <div className='sm:flex sm:items-center '>
                <div className='mx-auto z-20 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-400 sm:mx-0 sm:h-10 sm:w-10'>
                    {/* <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                </div>
                <div className='mt-3 z-20 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900 dark:text-gray-300'
                    >
                        Crop Image
                    </Dialog.Title>
                </div>
            </div>
            <div className={'h-96 w-full'}>
                <Cropper
                    image={image}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    // aspect={11 / 12}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div className={'w-full py-8 flex justify-center'}>
                <button
                    onClick={showCroppedImage}
                    className='z-20 w-full inline-flex justify-center rounded-md border dark:bg-gray-200 border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                >
                    Done!
                </button>
            </div>
            {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
        </div>
    )
}

export default CropContainer
