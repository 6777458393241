import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import SmallLoader from '../SmallLoader'

export default function LieksModal({ openModal, setOpenModal, likes }) {
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                static
                className='fixed z-50 inset-0 overflow-y-hidden'
                open={openModal}
                onClose={setOpenModal}
            >
                <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='hidden sm:inline-block sm:align-middle sm:h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-4 md:max-w-lg w-full'>
                            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800 max-h-96 overflow-y-auto'>
                                <h1 className='w-full dark:text-white text-xl font-semibold mb-5'>
                                    Liked by:
                                </h1>
                                {likes.map((like) => {
                                    return (
                                        <Link
                                            to={`/${like.username}`}
                                            className={
                                                'flex items-center w-full focus:outline-none hover:text-blue-500  dark:text-gray-200 dark:hover:text-blue-500 mb-3'
                                            }
                                        >
                                            <img
                                                src={like.imageUrl}
                                                className='object-cover h-8 w-8 md:h-11 md:w-11 rounded-full'
                                            />

                                            <div className='px-4 flex justify-center items-center'>
                                                <p>{like.username}</p>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
