export const FEED_REQUEST = 'FEED_REQUEST'
export const FEED_SUCCESS = 'FEED_SUCCESS'
export const FEED_FAIL = 'FEED_FAIL'
export const FEED_RESET = 'FEED_RESET'

export const MY_POSTS_REQUEST = 'MY_POSTS_REQUEST'
export const MY_POSTS_SUCCESS = 'MY_POSTS_SUCCESS'
export const MY_POSTS_FAIL = 'MY_POSTS_FAIL'
export const MY_POSTS_RESET = 'MY_POSTS_RESET'

export const OTHER_POSTS_REQUEST = 'OTHER_POSTS_REQUEST'
export const OTHER_POSTS_SUCCESS = 'OTHER_POSTS_SUCCESS'
export const OTHER_POSTS_FAIL = 'OTHER_POSTS_FAIL'
export const OTHER_POSTS_RESET = 'OTHER_POSTS_RESET'

export const SAVED_POSTS_REQUEST = 'SAVED_POSTS_REQUEST'
export const SAVED_POSTS_SUCCESS = 'SAVED_POSTS_SUCCESS'
export const SAVED_POSTS_FAIL = 'SAVED_POSTS_FAIL'
export const SAVED_POSTS_RESET = 'SAVED_POSTS_RESET'

export const ADD_POST_REQUEST = 'ADD_POST_REQUEST'
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS'
export const ADD_POST_FAIL = 'ADD_POST_FAIL'
export const ADD_POST_RESET = 'ADD_POST_RESET'

export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAIL = 'UPDATE_POST_FAIL'

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL'
