import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import {
    getLists,
    addBucketlist,
    deleteBucketlist,
    updateBucketList,
    resetOperations,
    resetListsOperations
} from '../actions/listActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import UpdateModal from '../components/common/UpdateModal'
import Meta from '../components/common/Meta'

const BucketListsScreen = ({ location, history }) => {
    const dispatch = useDispatch()
    const [editValue, setEditValue] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [formLoad, setFormLoad] = useState(false)
    const [bannerImage, setBannerImage] = useState('')
    const [deleteImage, setDeleteImage] = useState('')
    const [name, setName] = useState('')

    const [isPublic, setIsPublic] = useState(false)
    const [description, setDescription] = useState('')
    const [listId, setListId] = useState(0)
    const [showError, setShowError] = useState('')
    const [currentFile, setCurrentFile] = useState(null)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const addList = useSelector((state) => state.addList)
    const { success: successAdd } = addList

    const deleteList = useSelector((state) => state.deleteList)
    const { success: successDelete } = deleteList

    const updateList = useSelector((state) => state.updateList)
    const {
        loading: loadingUpdate,
        error: errorUpdate,
        success: successUpdate,
    } = updateList

    const bucketLists = useSelector((state) => state.bucketLists)
    const { loading, error, bucketlists } = bucketLists

    const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!bucketlists || bucketlists.length === 0) dispatch(getLists())
        }
    }, [history, userInfo])

    useEffect(() => {
        if(successAdd || successDelete || successUpdate){
            dispatch(getLists())
            setFormLoad(false)
            dispatch(resetListsOperations())
        }
    }, [successAdd, successDelete, successUpdate])

    const addHandler = (e) => {
        dispatch(addBucketlist())
    }
    const deleteHandler = async (id, imageS3) => {
        if (
            window.confirm('Are you sure you want to delete this bucket list?')
        ) {
            dispatch(deleteBucketlist(id))

            if (imageS3 && imageS3 !== '/images/sunset4.jpg') {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const mediaKeys = imageS3.substring(
                    imageS3.lastIndexOf('/') + 1
                )

                await axios.delete(
                    `/api/media/objects?keys=${mediaKeys}`,
                    config
                )
            }
        }
    }
    const editListHandler = (e) => {
        e.preventDefault()
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setFormLoad(true)
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        let imgUrl
        if (currentFile) {
            const { data } = await axios.get(
                `/api/media/s3Url?type='image'`,
                config
            )
            console.log(data.url)
            // const formData = new FormData()
            // formData.append("image", currentFile)
            await axios.put(data.url, currentFile, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            imgUrl = data.url.split('?')[0]
            setCurrentFile(null)
            console.log(bannerImage)
        }
        if (
            deleteImage !== '/images/sunset4.jpg' &&
            (deleteImage !== bannerImage || (imgUrl && deleteImage !== imgUrl))
        ) {
            const mediaKeys = deleteImage.substring(
                deleteImage.lastIndexOf('/') + 1
            )

            await axios.delete(`/api/media/objects?keys=${mediaKeys}`, config)
        }
        console.log(imgUrl)
        dispatch(
            updateBucketList({
                _id: listId,
                name,
                bannerImage: imgUrl ? imgUrl : bannerImage,
                description,
                isPublic,
            })
        )
        setShowModal(false)
    }

    const setValue = () => {
        setIsPublic((prev) => !prev)
    }

    // const uploadFileHandler = async (e) => {
    //     const file = e.target.files[0]
    //     setCurrentFile(file)
    // const formData = new FormData()
    // formData.append("image", file)
    //     setUploading(true)

    //     try {
    //         const config = {
    //             headers: {
    //                 "Content-Type": "multipart/form-data"
    //             }
    //         }

    //         const {data} = await axios.post("/api/upload", formData, config)

    //         setBannerImage(data)
    //         setUploading(false)
    //     } catch (error) {
    //         console.error(error)
    //         setUploading(false)
    //     }
    // }

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 py-14 md:py-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            <Meta title={`Jetlyst | Bucket Lists`} />

            <div className='h-full md:flex w-full md:items-center md:justify-center px-8 bg-transparent'>
                <div className='h-full md:w-full sm:w-full py-6 lg:px-12 sm:px-8 bg-transparent'>
                    <div className='flex flex-col md:flex-row sm:items-start mb-2 md:justify-between md:items-center w-full '>
                        <h2 className='text-3xl md:text-5xl dark:text-white mb-2 font-bold text-center md:text-left'>
                            My Bucket Lists
                        </h2>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            transition={{ ease: 'easeIn', duration: 0.2 }}
                            onClick={addHandler}
                            className='group relative md:w-48 sm:w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800'
                        >
                            <i className='fas fa-plus mr-4'></i> Add List
                        </motion.button>
                    </div>

                    <div className='shadow-md h-40 flex flex-col w-full rounded-xl dark:bg-gray-600 overflow-hidden '>
                        <div className='h-full w-full'>
                            <img
                                src='./images/anime1.jpg'
                                className='h-full w-full object-cover sm:h-72 md:h-full lg:w-full lg:h-full'
                            />
                        </div>
                    </div>
                    <div className='-my-2 overflow-x-auto max-h-screen overflow-y-auto no-scrollbar sm:-mx-6 lg:-mx-2'>
                        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2 mt-8 '>
                            <div className='shadow overflow-hidden border-b border-gray-200 rounded-lg'>
                                {error && (
                                    <Message variant='danger'>{error}</Message>
                                )}
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-500'>
                                        <thead className='bg-gray-200 dark:bg-gray-700'>
                                            <tr className=''>
                                                {/* <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'></th> */}
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Name
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Description
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Date Created
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    View
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Edit
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Delete
                                                </th>
                                                {/* <th scope='col' class='relative px-6 py-3'>
											<span class='sr-only'>Edit</span>
										</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className='bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-800'>
                                            {bucketlists &&
                                                bucketlists.map((list) => (
                                                    <tr
                                                        key={list._id}
                                                        className=''
                                                    >
                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <div class='flex items-center'>
                                                                <div class='flex-shrink-0 h-10 w-10'>
                                                                    <img
                                                                        className='inline-block h-10 w-10 rounded-full ring-2 ring-white object-cover'
                                                                        src={
                                                                            list.bannerImage
                                                                        }
                                                                        alt=''
                                                                    />
                                                                </div>
                                                                <div class='ml-4'>
                                                                    <div class='text-sm font-medium text-gray-900 dark:text-gray-200'>
                                                                        {
                                                                            list.name
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        {/* <td>
													<Link
														to={`/bucketlist/${list._id}`}
														className='text-blue-500 hover:text-blue-800 no-underline hover:underline'
													>
														{list.name}
													</Link>
												</td> */}
                                                        <td className='px-6 py-4 break-words'>
                                                            <div className='text-sm text-gray-900 dark:text-gray-200'>
                                                                {
                                                                    list.description
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <div className='text-sm text-gray-900 dark:text-gray-200'>
                                                                {moment(
                                                                    new Date(
                                                                        list.createdAt
                                                                    )
                                                                ).fromNow()}
                                                            </div>
                                                        </td>

                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <Link
                                                                to={`/bucketlist/${list._id}`}
                                                                className='text-blue-500 hover:text-blue-800 no-underline hover:underline'
                                                            >
                                                                <i className='fas fa-eye text-blue-500 hover:text-blue-400 cursor-pointer dark:hover:text-gray-200'></i>
                                                            </Link>
                                                        </td>
                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <i
                                                                className='far fa-edit text-blue-500 hover:text-blue-400 cursor-pointer dark:hover:text-gray-200'
                                                                onClick={() => {
                                                                    setName(
                                                                        list.name
                                                                    )
                                                                    setShowModal(
                                                                        true
                                                                    )
                                                                    setDescription(
                                                                        list.description
                                                                    )
                                                                    setBannerImage(
                                                                        list.bannerImage
                                                                    )
                                                                    setDeleteImage(
                                                                        list.bannerImage
                                                                    )
                                                                    setListId(
                                                                        list._id
                                                                    )
                                                                    setIsPublic(
                                                                        list.isPublic
                                                                    )
                                                                }}
                                                            ></i>
                                                        </td>
                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <i
                                                                onClick={() =>
                                                                    deleteHandler(
                                                                        list._id,
                                                                        list.bannerImage
                                                                    )
                                                                }
                                                                className='fas fa-trash-alt text-red-500 hover:text-red-700 cursor-pointer'
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <UpdateModal
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    image={bannerImage}
                    setImage={setBannerImage}
                    content=''
                    button='Update Item'
                    button2='Cancel'
                    openModal={showModal}
                    setOpenModal={setShowModal}
                    submitHandler={submitHandler}
                    setFile={setCurrentFile}
                    formLoad={formLoad}
                    toggleLabel='Public'
                    setValue={setValue}
                    value={isPublic}
                />
            )}
        </motion.div>
    )
}

export default BucketListsScreen
