import React, { useState, useEffect, useRef, createRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import {
    getListItems,
    addListItem,
    deleteListItem,
    updateListItem,
} from '../actions/listActions'
import Loader from '../components/Loader'
import AddItemModal from '../components/common/AddItemModal'
import UpdateModal from '../components/common/UpdateModal'
import ReactToPDF from 'react-to-pdf'
import Message from '../components/Message'
import Meta from '../components/common/Meta'

const BucketListsScreen = ({ match, location, history }) => {
    const listId = match.params.listid
    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [currentFile, setCurrentFile] = useState(null)
    const [description, setDescription] = useState('')
    const [itemId, setItemId] = useState(null)
    const [submitError, setSubmitError] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [currentRef, setCurrentRef] = useState(null)
    const [deleteImage, setDeleteImage] = useState('')
    const componentRef = createRef()

    const [formLoad, setFormLoad] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const listItems = useSelector((state) => state.listItems)
    const { loading, error, list } = listItems

    const addItem = useSelector((state) => state.addItem)
    const { success: successAdd, error: errorAdd } = addItem

    const updateItem = useSelector((state) => state.updateListItem)
    const { success: successUpdate, error: errorUpdate } = updateItem

    const deleteItem = useSelector((state) => state.deleteListItem)
    const { success: successDelete } = deleteItem

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getListItems(listId))
        }
    }, [history, userInfo])

    useEffect(() => {
        console.log(componentRef)
        if (componentRef.current) {
            setCurrentRef(componentRef)
        }
    }, [componentRef.current])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getListItems(listId, true))
        }
    }, [
        dispatch,
        successAdd,
        successUpdate,
        successDelete,
        errorAdd,
        errorUpdate,
    ])

    const toggleModal = () => {
        setShowModal(!showModal)
        setSubmitError(false)
    }
    // const uploadFileHandler = async (e) => {
    // 	const file = e.target.files[0]
    // 	const formData = new FormData()
    // 	formData.append('image', file)
    // 	setUploading(true)

    // 	try {
    // 		const config = {
    // 			headers: {
    // 				'Content-Type': 'multipart/form-data',
    // 			},
    // 		}

    // 		const { data } = await axios.post('/api/upload', formData, config)

    // 		setImage(data)
    // 		setUploading(false)
    // 	} catch (error) {
    // 		console.error(error)
    // 		setUploading(false)
    // 	}
    // }

    const uploadCurrentFile = async () => {
        let imgUrl
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const { data } = await axios.get(
            `/api/media/s3Url?type='image'`,
            config
        )
        console.log(data.url)
        // const formData = new FormData()
        // formData.append("image", currentFile)
        await axios.put(data.url, currentFile, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        imgUrl = data.url.split('?')[0]
        return imgUrl
    }
    const addHandler = async (e) => {
        e.preventDefault()
        setFormLoad(true)
        if (name === '' || description === '') {
            setSubmitError(true)
            setFormLoad(false)
        } else {
            let imgUrl
            if (currentFile) {
                imgUrl = await uploadCurrentFile()
            }
            console.log(imgUrl)
            dispatch(
                addListItem(listId, {
                    name,
                    description,
                    image: imgUrl ? imgUrl : '/images/sunset4.jpg',
                })
            )
            setShowModal(false)
            setFormLoad(false)
            setImage('')
            setCurrentFile(null)
            setName('')
            setDescription('')
        }
    }

    const deleteHandler = async (listItemId, imageS3) => {
        if (
            list &&
            list.user === userInfo._id &&
            window.confirm(
                'Are you sure you want to delete this bucket list item?'
            )
        ) {
            dispatch(deleteListItem(listId, listItemId))
            if (imageS3 && imageS3 !== '/images/sunset4.jpg') {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const mediaKeys = imageS3.substring(
                    imageS3.lastIndexOf('/') + 1
                )

                await axios.delete(
                    `/api/media/objects?keys=${mediaKeys}`,
                    config
                )
            }
        }
    }

    const updateHandler = async (e) => {
        e.preventDefault()
        setFormLoad(true)
        if (name === '' || description === '') {
            setSubmitError(true)
            setFormLoad(false)
        } else {
            let imgUrl
            if (currentFile) {
                imgUrl = await uploadCurrentFile()
            }
            if (
                deleteImage !== '/images/sunset4.jpg' &&
                (deleteImage !== image || (imgUrl && deleteImage !== imgUrl))
            ) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                const mediaKeys = deleteImage.substring(
                    deleteImage.lastIndexOf('/') + 1
                )

                await axios.delete(
                    `/api/media/objects?keys=${mediaKeys}`,
                    config
                )
            }
            console.log(imgUrl)
            dispatch(
                updateListItem(listId, itemId, {
                    name,
                    description,
                    image: imgUrl ? imgUrl : image,
                    completed,
                })
            )
            setShowUpdateModal(false)
            setFormLoad(false)
        }
    }

    const setValue = () => {
        setCompleted((prev) => !prev)
    }

    return (
        <motion.div
            className='bg-white dark:bg-gray-800 flex-1 flex pt-14 md:pt-20'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', ease: 'anticipate', duration: 0.5 }}
        >
            {loading && (
                <div className='w-full'>
                    <Loader />
                </div>
            )}
            {error && <Message variant='danger'>{error}</Message>}
            {list && <Meta title={`List | ${list.name}`} />}
            {list && (
                <div className='h-full md:flex w-full md:items-center md:justify-center px-12'>
                    <div className='h-full md:w-full sm:w-full flex-col justify-center items-center py-6 lg:px-12 sm:px-8'>
                        <div className='flex flex-col md:flex-row sm:items-start mb-2 md:justify-between md:items-center w-full'>
                            <h2 className='text-3xl md:text-5xl dark:text-white mb-4 font-bold text-center md:text-left'>
                                {!loading && !error && list.name}
                            </h2>
                            {list.user === userInfo._id && (
                                <button
                                    onClick={() => setShowModal(true)}
                                    className='group relative md:w-48 sm:w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800'
                                >
                                    <i className='fas fa-plus mr-4'></i> Add
                                    List Item
                                </button>
                            )}
                        </div>
                        <div className='shadow-md h-40 flex flex-col w-full rounded-xl dark:bg-gray-600 overflow-hidden'>
                            <div className='h-full w-full'>
                                <img
                                    src={list && list.bannerImage}
                                    className='h-full w-full object-cover sm:h-72 md:h-full lg:w-full lg:h-full'
                                />
                            </div>
                        </div>

                        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-2'>
                            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2 mt-8 '>
                                <div className='shadow overflow-hidden border-b border-gray-200 rounded-lg'>
                                    <table
                                        ref={componentRef}
                                        className='min-w-full divide-y divide-gray-200 dark:divide-gray-500'
                                    >
                                        <thead className='bg-gray-200 dark:bg-gray-700'>
                                            <tr className=''>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Name
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Description
                                                </th>
                                                <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                    Date Created
                                                </th>
                                                {list.user === userInfo._id && (
                                                    <>
                                                        <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                            Edit
                                                        </th>
                                                        <th className='px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider dark:text-white'>
                                                            Delete
                                                        </th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className='bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-600'>
                                            {list &&
                                                list.items &&
                                                list.items.map((item) => (
                                                    <tr
                                                        key={item._id}
                                                        className={
                                                            'dark:bg-gray-800' +
                                                            (item.completed
                                                                ? ' line-through decoration-wavy decoration-2'
                                                                : '')
                                                        }
                                                    >
                                                        <td className='px-6 py-4 whitespace-nowrap'>
                                                            <div className='flex items-center'>
                                                                <div className='flex-shrink-0 h-10 w-10'>
                                                                    <img
                                                                        className='inline-block h-10 w-10 rounded-full ring-2 ring-white object-cover'
                                                                        src={
                                                                            item.image
                                                                        }
                                                                        alt=''
                                                                    />
                                                                </div>
                                                                <div className='ml-4'>
                                                                    <div className='text-sm font-medium text-gray-900 dark:text-white'>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='px-6 py-4'>
                                                            <div className='text-sm text-gray-900 dark:text-gray-200 w-24 min-w-full '>
                                                                {
                                                                    item.description
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className='px-6 py-4'>
                                                            <div className='text-sm text-gray-900 dark:text-white'>
                                                                {moment(
                                                                    new Date(
                                                                        item.createdAt
                                                                    )
                                                                ).fromNow()}
                                                            </div>
                                                        </td>
                                                        {list.user ===
                                                            userInfo._id && (
                                                            <>
                                                                <td className='px-6 py-4 whitespace-nowrap'>
                                                                    <i
                                                                        className='far fa-edit text-blue-500 hover:text-blue-400 cursor-pointer dark:hover:text-white'
                                                                        onClick={() => {
                                                                            setName(
                                                                                item.name
                                                                            )
                                                                            setShowUpdateModal(
                                                                                true
                                                                            )
                                                                            setDescription(
                                                                                item.description
                                                                            )
                                                                            setImage(
                                                                                item.image
                                                                            )
                                                                            setDeleteImage(
                                                                                item.image
                                                                            )
                                                                            setItemId(
                                                                                item._id
                                                                            )
                                                                            setCompleted(
                                                                                item.completed
                                                                            )
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                                <td className='px-6 py-4 whitespace-nowrap'>
                                                                    <i
                                                                        className='fas fa-trash-alt text-red-500 hover:text-red-700 cursor-pointer'
                                                                        onClick={() =>
                                                                            deleteHandler(
                                                                                item._id,
                                                                                item.image
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {currentRef &&
                        list &&
                        list.items &&
                        list.items.length > 0 ? (
                            <ReactToPDF
                                scale={0.7}
                                targetRef={componentRef}
                                filename={`${list.name}-bucketlist.pdf`}
                            >
                                {({ toPdf }) => (
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        transition={{
                                            ease: 'easeIn',
                                            duration: 0.2,
                                        }}
                                        onClick={toPdf}
                                        className='group relative md:w-48 sm:w-full flex items-center justify-center mb-3 md:mb-0 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 mt-3'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='h-6 w-6 mr-4'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                            stroke='currentColor'
                                        >
                                            <path
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth={2}
                                                d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4'
                                            />
                                        </svg>
                                        Download List
                                    </motion.button>
                                )}
                            </ReactToPDF>
                        ) : null}
                    </div>
                    {showModal && (
                        <AddItemModal
                            name={name}
                            setName={setName}
                            description={description}
                            setDescription={setDescription}
                            image={image}
                            setImage={setImage}
                            content=''
                            button='Add Item'
                            button2='Cancel'
                            submitError={submitError}
                            openModal={showModal}
                            setOpenModal={toggleModal}
                            submitHandler={addHandler}
                            setCurrentFile={setCurrentFile}
                            formLoad={formLoad}
                        />
                    )}
                    {showUpdateModal && (
                        <UpdateModal
                            name={name}
                            setName={setName}
                            description={description}
                            setDescription={setDescription}
                            image={image}
                            setImage={setImage}
                            content=''
                            button='Update Item'
                            button2='Cancel'
                            openModal={showUpdateModal}
                            setOpenModal={setShowUpdateModal}
                            submitHandler={updateHandler}
                            setFile={setCurrentFile}
                            formLoad={formLoad}
                            toggleLabel='Completed'
                            setValue={setValue}
                            value={completed}
                        />
                    )}
                </div>
            )}
        </motion.div>
    )
}

export default BucketListsScreen
