import React from 'react'
import ReactDOM from 'react-dom'
import keyConfiguration from './config/pubnub-keys'
import Pubnub from 'pubnub'
import { Provider } from 'react-redux'
import createReduxStore from './store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
const pubnubConfig = Object.assign(
    {},
    {
        // Ensure that subscriptions will be retained if the network connection is lost
        restore: true,
        heartbeatInterval: 0,
    },
    keyConfiguration
)
const pubnub = new Pubnub(pubnubConfig)
const store = createReduxStore({
    pubnub: {
        api: pubnub,
    },
})

ReactDOM.render(
    <Provider store={store}>
        <App pubnubClient={pubnub} store={store} />
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
