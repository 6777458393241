import React, { useState } from 'react'

const Question = ({ question, answer }) => {
    const [open, setOpen] = useState(false)
    const changeOpen = () => {
        setOpen(!open)
    }
    return (
        <li className='p-12 border rounded-lg'>
            <button
                className='w-full flex justify-between items-center text-left font-semibold font-heading'
                onClick={changeOpen}
            >
                <span className='text-2xl dark:text-white font-semibold font-heading'>
                    {question}
                </span>
                {open ? (
                    <svg
                        className='flex-shrink-0'
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M19.25 10C19.25 4.892 15.109 0.75 10 0.75C4.892 0.75 0.75 4.892 0.75 10C0.75 15.108 4.892 19.25 10 19.25C15.109 19.25 19.25 15.108 19.25 10Z'
                            stroke='#45C1FF'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M13.4712 11.4423L10.0002 7.95626L6.52919 11.4423'
                            stroke='#45C1FF'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                ) : (
                    <svg
                        className='flex-shrink-0'
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.75 10C0.75 15.108 4.891 19.25 10 19.25C15.108 19.25 19.25 15.108 19.25 10C19.25 4.892 15.108 0.75 10 0.75C4.891 0.75 0.75 4.892 0.75 10Z'
                            stroke='#838EA4'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M6.52881 8.55768L9.99981 12.0437L13.4708 8.55768'
                            stroke='#838EA4'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                )}
            </button>
            {open && (
                <p className='mt-4 max-w-2xl text-gray-500 dark:text-gray-300 leading-loose'>
                    {answer}
                </p>
            )}
        </li>
    )
}

export default Question
