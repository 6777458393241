import axios from 'axios'
import {
    ADD_MESSAGE_REQUEST,
    ADD_MESSAGE_SUCCESS,
    ADD_MESSAGE_FAIL,
    LIST_MESSAGES_REQUEST,
    LIST_MESSAGES_SUCCESS,
    LIST_MESSAGES_FAIL,
    LIST_CHATS_REQUEST,
    LIST_CHATS_SUCCESS,
    LIST_CHATS_FAIL,
    CHAT_CLIENT_SUCCESS,
    LIST_CHATS_REFRESH,
    LIST_CHATS_DELETE,
    LIST_CHATS_DONE_REFRESH,
    LIST_CHATS_UPDATE,
    CHAT_CLIENT_REQUEST,
    CHAT_CLIENT_FAIL,
    CURRENT_CHAT_REQUEST,
    CURRENT_CHAT_SUCCESS,
    CURRENT_CHAT_FAIL,
    LATEST_MESSAGES_REQUEST,
    LATEST_MESSAGES_SUCCESS,
    LATEST_MESSAGES_FAIL,
} from '../constants/chatConstants'
import {
    fetchUserData,
    fetchMemberships,
    setMemberships,
    setUserData,
    removeChannelData,
    removeMemberships,
    setChannelMembers,
    sendMessage as sendPubnubMessage,
    sendSignal,
    createSignalReducer,
    Signal,
    fetchMessageHistory,
} from 'pubnub-redux'
import getClient from '../config/twilio'
import { getUserDetails } from './userActions'

export const joinMyChannel =
    (userInfo) => async (dispatch, getState, context) => {
        try {
            await dispatch(
                setMemberships({
                    uuid: userInfo._id,
                    channels: [{ id: userInfo._id }],
                })
            )
        } catch (error) {
            dispatch({
                type: CHAT_CLIENT_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const initiateChatClient =
    (userInfo) => async (dispatch, getState, context) => {
        try {
            dispatch({ type: CHAT_CLIENT_REQUEST })
            if (!context.pubnub.api.getUUID()) {
                context.pubnub.api.setUUID(userInfo._id)
                console.log('blue')
            }

            const pubnubLogin = async () => {
                try {
                    await dispatch(fetchUserData({ uuid: userInfo._id }))
                } catch (error) {
                    if (
                        error.status &&
                        error.status.statusCode &&
                        error.status.statusCode === 404
                    ) {
                        try {
                            await dispatch(
                                setUserData({
                                    uuid: userInfo._id,
                                    data: {
                                        externalId: userInfo._id,
                                    },
                                })
                            )
                        } catch (error) {
                            throw new Error(error)
                        }
                        await dispatch(fetchUserData({ uuid: userInfo._id }))
                    }
                }
                await context.pubnub.api.subscribe({
                    channels: [userInfo._id],
                    withPresence: true,
                })
                console.log('yello')
                // await dispatch(
                //     removeChannelData({ id: '614a5d0e8283770602c8cf5c' })
                // )
                // await dispatch(
                //     removeChannelData({ id: '614a5d0e8283770602c8cf5d' })
                // )
                // await dispatch(
                //     removeMemberships({
                //         uuid: userInfo._id,
                //         channels: ['614a5d0e8283770602c8cf5d'],
                //     })
                // )
                // await dispatch(
                //     removeChannelData({ id: '614a5d0e8283770602c8cf5d' })
                // )

                await dispatch(
                    // Load the conversations that this user has joined
                    fetchMemberships({
                        uuid: userInfo._id,
                        include: {
                            channelFields: true,
                            customChannelFields: false,
                            customFields: true,
                            totalCount: false,
                        },
                    })
                )

                const { joinedChats } = getState()
                // Subscribe to messages on the user's joined conversations
                const conversationChannels = joinedChats.byId[userInfo._id].map(
                    (membership) => membership.id
                )

                // await dispatch(
                //     removeMemberships({
                //         uuid: userInfo._id,
                //         channels: [
                //             'testchannel',
                //             '614a5d0e8283770602c8cf5c-614a5d0e8283770602c8cf5d',
                //         ],
                //     })
                // )

                // await dispatch(
                //     removeChannelData({
                //         id: 'testchannel',
                //     })
                // )
                // await dispatch(
                //     removeChannelData({
                //         id: '614a5d0e8283770602c8cf5c-614a5d0e8283770602c8cf5d',
                //     })
                // )

                await context.pubnub.api.subscribe({
                    channels: conversationChannels,
                    withPresence: true,
                })
            }

            await pubnubLogin()
        } catch (error) {
            dispatch({
                type: CHAT_CLIENT_FAIL,
                payload:
                    error.status && error.status.errorData
                        ? error.status.errorData.error.message
                        : error.status,
            })
        }
    }

export const getAugmentedChats =
    (userInfo) => async (dispatch, getState, context) => {
        try {
            dispatch({ type: LIST_CHATS_REQUEST })
            const { joinedChats } = getState()

            if (joinedChats.byId && joinedChats.byId[userInfo._id]) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
                dispatch({ type: LATEST_MESSAGES_REQUEST })
                console.log(joinedChats.byId[userInfo._id])
                try {
                    let result = await context.pubnub.api.fetchMessages({
                        channels: [
                            ...joinedChats.byId[userInfo._id].map(
                                (item) => item.id
                            ),
                        ],
                        reverse: false,
                        count: 1,
                    })
                    console.log(result)
                    dispatch({
                        type: LATEST_MESSAGES_SUCCESS,
                        payload: result,
                    })
                } catch (error) {
                    dispatch({
                        type: LATEST_MESSAGES_FAIL,
                        payload: error,
                    })
                }

                let chatsWithData = await Promise.all(
                    joinedChats.byId[userInfo._id].map(async (item) => {
                        // await dispatch(
                        //     fetchMessageHistory({
                        //         channel: item.id,
                        //         reverse: false,
                        //         count: 1,
                        //     })
                        // )
                        if (item.id.includes('group_')) {
                            return item
                        } else if (item.id.includes('-')) {
                            let otherMember = item.id
                                .split('-')
                                .filter((item) => item !== userInfo._id)
                            const { data } = await axios.get(
                                `/api/users/${otherMember}/details`,
                                config
                            )
                            return {
                                id: item.id,
                                otherMember: data,
                            }
                        }
                    })
                )

                dispatch({
                    type: LIST_CHATS_SUCCESS,
                    payload: chatsWithData,
                })
                // setChats(chatsWithData)
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: LIST_CHATS_FAIL,
                payload:
                    error.status && error.status.errorData
                        ? error.status.errorData.error.message
                        : error.status,
            })
        }
    }

export const focusOnChat =
    (chatObject) => async (dispatch, getState, context) => {
        try {
            const {
                currentChat: { chat },
            } = getState()
            if (chat.id !== chatObject.id) {
                dispatch({ type: CURRENT_CHAT_REQUEST })
                await dispatch(
                    fetchMessageHistory({
                        channel: chatObject.id,
                        reverse: true,
                        count: 50,
                        stringifiedTimeToken: true,
                        includeMeta: true,
                    })
                )
                dispatch({
                    type: CURRENT_CHAT_SUCCESS,
                    payload: chatObject,
                })
            }

            // setChats(chatsWithData)
        } catch (error) {
            dispatch({
                type: CURRENT_CHAT_FAIL,
                payload: error,
            })
        }
    }

export const createNewPrivateChat =
    (userId, otherUser) => async (dispatch, getState, context) => {
        try {
            dispatch({ type: CHAT_CLIENT_REQUEST })
            const { joinedChats, augmentedChats:{chats}} = getState()
            let newChatId
            if (
                joinedChats.byId[userId].filter((item) => {
                    if (item.id === `${userId}-${otherUser._id}`) {
                        newChatId = `${userId}-${otherUser._id}`
                        return true
                    } else if (item.id === `${otherUser._id}-${userId}`) {
                        newChatId = `${otherUser._id}-${userId}`
                        return true
                    }
                    return false
                }).length === 0
            ) {
                newChatId = `${userId}-${otherUser._id}`
                await dispatch(
                    setMemberships({
                        uuid: userId,
                        channels: [{ id: `${userId}-${otherUser._id}` }],
                    })
                )
                await dispatch(
                    setChannelMembers({
                        channel: `${userId}-${otherUser._id}`,
                        uuids: [otherUser._id],
                    })
                )
                await context.pubnub.api.subscribe({
                    channels: [`${userId}-${otherUser._id}`],
                    withPresence: true,
                })
                dispatch({
                type: LIST_CHATS_SUCCESS,
                payload: [
                           {
                               id: newChatId, 
                               otherMember:
                                  {
                                      imageUrl: otherUser.imageUrl, 
                                      _id: otherUser._id, 
                                      name: otherUser.name, 
                                      username: otherUser.username}
                                  }, 
                               ...chats
                        ]
                })
            }
            
            dispatch(focusOnChat({ id: newChatId, otherMember: otherUser }))
        } catch (error) {
            dispatch({
                type: CHAT_CLIENT_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const createNewGroupChat =
    (userId, groupName) => async (dispatch, getState, context) => {
        try {
            dispatch({ type: CHAT_CLIENT_REQUEST })
            dispatch(
                setMemberships({
                    uuid: userId,
                    channels: [{ id: `group_${groupName}` }],
                })
            )
            await context.pubnub.api.subscribe({
                channels: [`group_${groupName}`],
                withPresence: true,
            })
        } catch (error) {
            dispatch({
                type: CHAT_CLIENT_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const sendMessage = (message) => async (dispatch, getState, context) => {
    const {
        currentChat: { chat },
        userLogin: { userInfo },
    } = getState()
    return dispatch(
        sendPubnubMessage({
            channel: chat.id,
            message,
        })
    )
}

export const sendTypingSignal = () => async (dispatch, getState, context) => {
    const {
        currentChat: { chat },
        userLogin: { userInfo },
    } = getState()
    return dispatch(
        sendSignal({
            message: {
                type: 'showTyping',
                by: userInfo._id,
            },
            channel: chat.id,
        })
    )
}
export const hideTypingSignal = () => async (dispatch, getState, context) => {
    const {
        currentChat: { chat },
        userLogin: { userInfo },
    } = getState()
    return dispatch(
        sendSignal({
            message: {
                type: 'hideTyping',
                by: userInfo._id,
            },
            channel: chat.id,
        })
    )
}
// export const sendMessage = (message) => async (dispatch, getState, context) => {
//     const {
//         currentChat: { chat },
//     } = getState()
//     return dispatch(
//         sendPubnubMessage({
//             channel: chat.id,
//             message,
//         })
//     )
// }

// return dispatch(
//     sendSignal({
//         channel: getCurrentConversationId(state),
//         message: { type: typingIndicatorType },
//     })
// )
