import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const CookieModal = ({
    isCookieSet,
    cookieConsent,
    consentGranted,
    consentDenied,
}) => {
    // const [showCookie, setShowCookie] = useState(false)
    // useEffect(() => {
    //     if (!isCookieSet)
    //         setTimeout(() => {
    //             setShowCookie(true)
    //         }, 2000)
    // }, [isCookieSet])
    return (
        <AnimatePresence>
            {!isCookieSet && (
                <motion.div
                    className={'fixed bottom-0 inset-x-0 z-50'}
                    initial={{
                        opacity: 0,
                        y: -200,
                        scale: 0.8,
                    }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: 200, scale: 0.8 }}
                    transition={{
                        type: 'tween',
                        ease: 'anticipate',
                        duration: 0.3,
                    }}
                    ref={(ref) => (cookieConsent = ref)}
                >
                    <div className='max-w-md lg:max-w-3xl mx-4 md:mx-0 md:ml-10 mb-6 py-6 pl-6 pr-16 bg-gray-800 dark:bg-gray-700 text-white rounded-xl'>
                        <div className='flex flex-wrap items-center -mx-4'>
                            <div className='w-full lg:w-1/2 px-4'>
                                <p className='font-bold font-heading'>
                                    Cookie Policy
                                </p>
                                <p className='mb-4 mt-3 mb-6 text-gray-400 text-sm'>
                                    We use{' '}
                                    <a
                                        className='text-purple-600 underline'
                                        href='#'
                                    >
                                        cookies
                                    </a>{' '}
                                    to personalise content, to provide social
                                    media features and to analyse our traffic.
                                </p>
                            </div>
                            <div className='w-full lg:w-1/2 px-4 lg:text-right'>
                                <button
                                    className='mr-4 py-2 px-4 rounded-l-xl rounded-t-xl border-2 border-purple-600 hover:border-purple-700 bg-purple-600 hover:bg-purple-700 transition duration-200'
                                    onClick={() => consentGranted()}
                                >
                                    Allow
                                </button>
                                <button
                                    className='py-2 px-4 rounded-r-xl rounded-t-xl border-2 border-gray-400 hover:bg-gray-700 transition duration-200'
                                    onClick={() => consentDenied()}
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default CookieModal
