import axios from 'axios'
import {
    CHAT_CLIENT_RESET,
    LATEST_MESSAGES_RESET,
    LIST_CHATS_RESET,
} from '../constants/chatConstants'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_FAIL,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_FOLLOW_REQUEST,
    USER_FOLLOW_SUCCESS,
    USER_FOLLOW_FAIL,
    USER_FOLLOW_RESET,
    USER_UNFOLLOW_REQUEST,
    USER_UNFOLLOW_SUCCESS,
    USER_UNFOLLOW_FAIL,
    USER_UNFOLLOW_RESET,
    USER_LIST_FOLLOWERS_REQUEST,
    USER_LIST_FOLLOWERS_SUCCESS,
    USER_LIST_FOLLOWERS_FAIL,
    USER_LIST_FOLLOWERS_RESET,
    USER_LIST_FOLLOWING_REQUEST,
    USER_LIST_FOLLOWING_SUCCESS,
    USER_LIST_FOLLOWING_FAIL,
    USER_LIST_FOLLOWING_RESET,
    USER_LIST_FOLLOWERS_BY_ID_REQUEST,
    USER_LIST_FOLLOWERS_BY_ID_SUCCESS,
    USER_LIST_FOLLOWERS_BY_ID_FAIL,
    USER_LIST_FOLLOWERS_BY_ID_RESET,
    USER_LIST_FOLLOWING_BY_ID_REQUEST,
    USER_LIST_FOLLOWING_BY_ID_SUCCESS,
    USER_LIST_FOLLOWING_BY_ID_FAIL,
    USER_LIST_FOLLOWING_BY_ID_RESET,
} from '../constants/userConstants'
import {
    FEED_RESET,
    MY_POSTS_RESET,
    OTHER_POSTS_RESET,
    SAVED_POSTS_RESET,
} from '../constants/feedConstants'
import { initiateChatClient, joinMyChannel } from './chatActions'

export const login = (info, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            '/api/users/login',
            { info, password },
            config
        )
        dispatch(initiateChatClient(data))
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const logout =
    (conversationsClient) => async (dispatch, getState, context) => {
        localStorage.removeItem('userInfo')
        dispatch({ type: USER_LOGOUT })
        dispatch({ type: USER_DETAILS_RESET })
        dispatch({ type: FEED_RESET })
        dispatch({ type: SAVED_POSTS_RESET })
        dispatch({ type: MY_POSTS_RESET })
        dispatch({ type: OTHER_POSTS_RESET })
        dispatch({ type: LATEST_MESSAGES_RESET })
        dispatch({ type: LIST_CHATS_RESET })
        await context.pubnub.api.unsubscribeAll()
        // if (conversationsClient) {
        //     dispatch({ type: CHAT_CLIENT_RESET })
        //     dispatch({ type: LIST_CHATS_RESET })
        //     conversationsClient.shutdown()
        // }
        localStorage.removeItem('chatToken')

        // dispatch({ type: ORDER_LIST_MY_RESET })
        // dispatch({ type: USER_LIST_RESET })
    }

export const register =
    (name, email, password, imageUrl, username, dateOfBirth) =>
    async (dispatch) => {
        try {
            dispatch({
                type: USER_REGISTER_REQUEST,
            })

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }

            const { data } = await axios.post(
                '/api/users/',
                { name, email, password, imageUrl, username, dateOfBirth },
                config
            )

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: data,
            })
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data,
            })
            dispatch(initiateChatClient(data))
            localStorage.setItem('userInfo', JSON.stringify(data))
        } catch (error) {
            dispatch({
                type: USER_REGISTER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }

export const getUserDetails = (username) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/users/${username}`, config)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PROFILE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put('/api/users/profile', user, config)

        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data,
        })
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })
        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listUsers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get('/api/users', config)

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await axios.delete(`/api/users/${id}`, config)

        dispatch({
            type: USER_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(`/api/users/${user._id}`, user, config)

        dispatch({
            type: USER_UPDATE_SUCCESS,
        })
        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const followUser = (userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_FOLLOW_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/users/following/${userId}`,
            {},
            config
        )

        dispatch({
            type: USER_FOLLOW_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_FOLLOW_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const unfollowUser = (userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UNFOLLOW_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await axios.delete(`/api/users/following/${userId}`, config)

        dispatch({
            type: USER_UNFOLLOW_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: USER_UNFOLLOW_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listFollowers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_FOLLOWERS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get('/api/users/followers', config)

        dispatch({
            type: USER_LIST_FOLLOWERS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FOLLOWERS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listFollowersById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_FOLLOWERS_BY_ID_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/users/${id}/followers`, config)

        dispatch({
            type: USER_LIST_FOLLOWERS_BY_ID_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FOLLOWERS_BY_ID_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listFollowing = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_FOLLOWING_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get('/api/users/following', config)

        dispatch({
            type: USER_LIST_FOLLOWING_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FOLLOWING_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listFollowingById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_FOLLOWING_BY_ID_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/api/users/${id}/following`, config)

        dispatch({
            type: USER_LIST_FOLLOWING_BY_ID_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FOLLOWING_BY_ID_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
